<template>
	<loading :expand="true" v-if="isLoading"></loading>
	<error-message v-if="error"></error-message>
	<div class="card_history" v-if="!error">
		<header class="history_header">
			<div class="search_card">
				<label for="card-code">Поиск карты</label>
				<input 
					type="number" 
					name="card-code" 
					id="card-code"
					placeholder="Введите номер карты"
					:readonly="readonly"
					v-model.number.trim="cardCode">
			</div>
		</header>
	</div>
	<chart 
		v-if="!error"
		:data="collectedData" 
		:titles="titles" 
		:requiredValues="requiredValues"
		:type="type"
		:pages="pagesAmountCount"
		:currentPage="currentPage"
		:initialPage="initialPage"
		:staticText="textOptions"
		@next="next()"
		@prev="prev()"
		@toStart="toStart()"
		@toEnd="toEnd()"
		@changePage="(page) => changePage(page)"></chart>
</template>

<script>
import ErrorMessage from '../../ErrorMessage.vue';
import Chart from './Chart.vue'
import Loading from '../../Loading.vue'


export default {
	name: 'CardHistory',
	components: {
		Chart,
		ErrorMessage,
		Loading
	},
	data() {
		return {
			collectedData: [],
			titles: [],
			requiredValues: [],
			textOptions: {
				content: 'Введите номер карты',
				visible: true
			},
			type: 2,
			cardCode: '',
			readonly: false,
			result: '',
			pagesAmountCount: 0,
			currentPage: 1,
			initialPage: 0,
			isLoading: false,
			error: false,
		}
	},
	methods: {
		async getCardHistory() {
			try{
				this.isLoading = true
				this.error = false
				this.textOptions = {
						content: 'Введите номер карты',
						visible: true
					}
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/card-history',
					data: {
						code: this.cardCode
					}
				}
				const response = await this.$store.dispatch('sendData', payload)
				this.collectedData = []
				this.titles = ['Сумма', 'Дата', 'Описани', 'Операция']
				this.requiredValues = ['amount', 'date', 'description', 'type']
				// this.pagesAmountCount = response.data.pagesCount
				this.collectedData = response.data
				this.textOptions.visible = false
				this.isLoading = false
			}catch(err) {
				this.isLoading = false
				if(this.$store.state.errorData.data.message === 'Нет карточки') {
					this.textOptions = {
						content: 'Карта не найдена',
						visible: true
					}
				}else{
					this.error = true
				}
			}
		},

		prev() {
			this.currentPage--
		},
		next() {
			this.currentPage++
		},
		toStart() {
			this.currentPage = 1
		},
		toEnd() {
			this.currentPage = this.pagesAmountCount
		},
	},
	watch: {	
		cardCode() {
			if(this.cardCode.length >= 8) {
				this.readonly = true
				this.cardCode = this.cardCode.slice(0, 8);
				this.getCardHistory()
				this.readonly = false
			}
		}
	}
}
</script>

<style scoped>
	.card_history{
		width: 100%;
	}

	.history_header{
		width: 100%;
		margin-bottom: 30px;
	}

	.search_card{
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.search_card label{
		margin-right: 10px;
	}

	.search_card input{
		padding: 15px 20px;
		border: none;
		background-color: #f1f1f1;
		border-radius: .5rem;
		font-family: "Open Sans";
		font-size: 14px;
		font-weight: 400;
		color: #80868B;
	}
</style>