<template>
	<h1>Под вашим аккаунтом вошли с другого устройства<router-link to="/login">Страница авторизации</router-link></h1>
</template>

<script>
export default {
	name: "UsedAccountPage",
}
</script>

<style scoped>
	h1{
		position: relative;
		z-index: 99;
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
</style>