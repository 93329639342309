<template>
	<div class="section">
		<loading v-if="isLoading"></loading>
		<div class="container">
			<div class="section_inner">
				<header class="header">
					<router-link to="/cashbox/card-id" class="header_btn">
						<svg width="20" height="40" viewBox="0 0 30 50" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M28.6674 1.30866C27.8163 0.470841 26.6607 0 25.4556 
							0C24.2505 0 23.0949 0.470841 22.2437 1.30866L1.33257 21.8456C-0.444191 
							23.5906 -0.444191 26.4094 1.33257 28.1543L22.2437 48.6913C24.0205 50.4362 
							26.8907 50.4362 28.6674 48.6913C30.4442 46.9463 30.4442 44.1275 28.6674 
							42.3825L10.9909 24.9776L28.6674 7.61739C30.4442 5.87243 30.3986 3.00889 
							28.6674 1.30866Z"/>
						</svg>
						<span>Назад</span>
					</router-link>
					<h1 class="header_title">Отчеты</h1>
					<div class="header_filters">
						<input 
							type="text" 
							class="search filter" 
							v-model="search" 
							placeholder="Введите ID карты"
							@keydown.prevent>
						<!-- ё -->
					</div>
				</header>
				<error-message v-if="error"></error-message>
				<div class="table" v-else>
					<div class="title_row">
						<h3 class="title_row_item">ID номер карты</h3>
						<h3 class="title_row_item">Кассир</h3>
						<h3 class="title_row_item">Наличными/безналичными</h3>
						<h3 class="title_row_item">
							<span>Статус платежа</span>
						</h3>
						<h3 class="title_row_item">Скидка</h3>
						<h3 class="title_row_item sort" @click="sortBySum()">
							<svg :class="{reverse: !sumReverse}" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5 8L10 9.53674e-07L-2.54292e-07 7.94465e-08L5 8Z" fill="#80B8DD"/>
							</svg>
							<span>Сумма</span>
						</h3>
						<h3 class="title_row_item to_right sort" @click="sortByDate()">
							<svg :class="{reverse: !dateReverse}" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5 8L10 9.53674e-07L-2.54292e-07 7.94465e-08L5 8Z" fill="#80B8DD"/>
							</svg>
							<span>Время</span>
						</h3>
					</div>
					<div 
						v-for="report in searchCard" 
						:key="report.created_at" 
						class="values_row">
							<h3 class="value_row_item">{{ report.id }}</h3>
							<h3 class="value_row_item">{{ report.cashier }}</h3>
							<h3 class="value_row_item">{{ report.paymentMethod }}</h3>
							<h3 class="value_row_item">Успешно</h3>
							<h3 class="value_row_item">{{ '-' }}</h3>
							<h3 class="value_row_item">{{ editSum(report.sum) }}</h3>
							<h3 class="value_row_item to_right">{{ report.date }}</h3>
					</div>
					<div class="pagination">
                        <span class="prev count" @click="toStart">1</span>
                        <button class="prev_btn btn" @click="prev">
                            <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.177677 7.79061C0.291166 7.92467 0.445244 8 0.605923 8C0.766602 8 0.920679 7.92467 1.03417 7.79061L3.82232 4.5047C4.05923 4.22551 4.05923 3.7745 3.82232 3.49531L1.03417 0.209397C0.797267 -0.0697987 0.414579 -0.0697988 0.177677 0.209397C-0.0592249 0.488591 -0.0592249 0.939599 0.177677 1.21879L2.53455 4.00358L0.177677 6.78122C-0.0592255 7.06041 -0.0531511 7.51858 0.177677 7.79061Z" fill="#707683"/>
                            </svg>
                        </button>
                        <input type="number" v-model="page" class="edit_page">
                        <button class="next_btn btn" @click="next">
                            <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.177677 7.79061C0.291166 7.92467 0.445244 8 0.605923 8C0.766602 8 0.920679 7.92467 1.03417 7.79061L3.82232 4.5047C4.05923 4.22551 4.05923 3.7745 3.82232 3.49531L1.03417 0.209397C0.797267 -0.0697987 0.414579 -0.0697988 0.177677 0.209397C-0.0592249 0.488591 -0.0592249 0.939599 0.177677 1.21879L2.53455 4.00358L0.177677 6.78122C-0.0592255 7.06041 -0.0531511 7.51858 0.177677 7.79061Z" fill="#707683"/>
                            </svg>
                        </button>
                        <span class="next count" @click="toEnd">{{ pagesAmountCount }}</span>
                    </div>
				</div>
				<div class="section_info" v-if="!error">
					<div class="info_row">
						<div class="info_title">Пополнение карт</div>
						<div class="info_row_inner">
							<div class="info_row_item">
								<h1 class="info_row_item_overhead">Наличными</h1>
								<h3 class="info_row_item_title">{{ statistic.countByCash }}</h3>
							</div>
							<div class="info_row_item">
								<h1 class="info_row_item_overhead">Картой</h1>
								<h3 class="info_row_item_title">{{ statistic.countByCard }}</h3>
							</div>
							<div class="info_row_item">
								<h1 class="info_row_item_overhead">Kaspi QR</h1>
								<h3 class="info_row_item_title">{{ statistic.countByKaspi }}</h3>
							</div>
							<div class="info_row_item">
								<h1 class="info_row_item_overhead">Итог</h1>
								<h3 class="info_row_item_title">{{ statistic.countResult }}</h3>
							</div>
						</div>
					</div>
					<div class="info_row">
						<div class="info_title">Пополнение сумм</div>
						<div class="info_row_inner">
							<div class="info_row_item">
								<h1 class="info_row_item_overhead">Наличными</h1>
								<h3 class="info_row_item_title">{{ statistic.sumByCash }}</h3>
							</div>
							<div class="info_row_item">
								<h1 class="info_row_item_overhead">Картой</h1>
								<h3 class="info_row_item_title">{{ statistic.sumByCard }}</h3>
							</div>
							<div class="info_row_item">
								<h1 class="info_row_item_overhead">Kaspi QR</h1>
								<h3 class="info_row_item_title">{{ statistic.sumByKaspi }}</h3>
							</div>
							<div class="info_row_item">
								<h1 class="info_row_item_overhead">Итог</h1>
								<h3 class="info_row_item_title">{{ statistic.sumResult }}</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loading from './Loading.vue'
import ErrorMessage from '@/components/admin/ErrorMessage.vue'

export default {
	name: 'Reports',
	components: {
		Loading,
		ErrorMessage
	},
	data() {
		return{
			paginatedReports: [],
			statistic: [],
			search: '',
			sessionId: '',
			value: '',
			currentInput: '',
			isLoading: false,
			page: 1,
			pagesAmountCount: 0,
			sumReverse: true,
			dateReverse: true,
			error: false,
		}
	},
	methods: {
		async getPaginatedReports() {
			try{
				const payload = {
					url: this.$store.state.apiUrl + '/v1/front/report-pagination-list',
					data: {
						page: this.page - 1,
						limit: 50
					}
				}
				const data = await this.$store.dispatch('sendData', payload);
				this.paginatedReports = data.data.data;
				this.pagesAmountCount = data.data.pagesCount
			}catch(err) {
				this.isLoading = false
				this.error = true
			}
		},
		async getStatistic() {
			try{
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/statistic',
					data: {}
				}
				const data = await this.$store.dispatch('sendData', payload);
				this.statistic = data.data
			}catch(err) {
				this.isLoading = false
				this.error = true
			}
		},
		async loadAllData(single="") {
			this.isLoading = true;
			this.error = false
			if(single) {
				await this.getPaginatedReports()
				this.isLoading = false;
			}else{
				await this.getPaginatedReports()
				await this.getStatistic()
				this.isLoading = false;
			}
		},
		async filterReports() {
			if(this.sessionId.length > 0) {
				this.isLoading = true;
				try{
					this.error = false
					const payload = {
						url: this.$store.state.apiUrl + 'v1/front/transaction-pagination-list',
						data: {
							page: this.page - 1,
							limit: 50
						}
					}
					const data = await this.$store.dispatch('sendData', payload);
					this.paginatedReports = data.data.data;
					this.pagesAmountCount = data.data.pagesCount
				}catch(err) {
					this.error = true
				}
			}
		},
		sortBySum() {
			if(!this.sumReverse) {
				this.paginatedReports.sort((a, b) => {
					return Number.parseInt(a.sum) - Number.parseInt(b.sum)
				})
			}else{
				this.paginatedReports.reverse();
			}
			this.sumReverse = !this.sumReverse
		},
		getMilliseconds(rep) {
			const regexp = /\d+/g
			const dateList = [...rep.date.match(regexp)].reverse()
			const milliseconds = new Date(dateList[0], dateList[1], dateList[2], dateList[4], dateList[3]).getTime()
			return milliseconds
		},
		sortByDate() {
			if(!this.dateReverse) {
				this.paginatedReports.sort((a, b) => {
					return this.getMilliseconds(a) - this.getMilliseconds(b)
				})
			}else{
				this.paginatedReports.reverse();
			}
			this.dateReverse = !this.dateReverse
		},
		editSum(amount) {
			return Number.parseInt(amount);
		},
		prev() {
            if(this.page > 1) {
                this.page--
            } 
        },
        next() {
            if(this.page < this.pagesAmountCount) {
                this.page++
            } 
        },
        toStart() {
            this.page = 1;
        },
        toEnd() {
            this.page = this.pagesAmountCount;
        },
		setCurrentInput(evt) {
			this.currentInput = evt.target
		},
		onFocus(life='mount') {
			const inputElem = document.getElementsByTagName('input')
			this.search = ''
			if(life === 'mount') {
				for(let i of inputElem) {
					i.addEventListener('focus', this.setCurrentInput)
				}
			}else{
				for(let i of inputElem) {
					i.removeEventListener('focus', this.setCurrentInput)
				}
			}
		},
		inputScreenKeyboard(evt) {
			let char = ''
			if(/\d+/.test(evt.key)) {
				char = evt.key
				this.value = char
				if(this.currentInput.className === 'search filter') {
					this.search += this.value
				}else if(this.currentInput.className === 'session filter'){
					this.sessionId += this.value
				}
			} else if(evt.key === 'Backspace') {
				if(this.currentInput.className === 'search filter') {
					this.search = this.search.substring(0, this.search.length - 1)
				}else if(this.currentInput.className === 'session filter'){
					this.sessionId = this.sessionId.substring(0, this.sessionId.length - 1)
				}
			}
		}
	},
	computed: {
		searchCard() {
            if(this.search.length > 0) {
                return this.paginatedReports.filter((item) => {
                    return item.id.replace(/\s/g, '').indexOf(this.search) > -1
                })
            }else {
                return this.paginatedReports;
            }
        }
	},
	watch: {
		page() {
			if(this.page !== '' && Number(this.page) > 0 && !(Number(this.page) > Number(this.pagesAmountCount))) {
				this.loadAllData('single');
			}
		},
	},
	mounted() {
		this.loadAllData();
		this.onFocus('mount')
		window.addEventListener('keydown', this.inputScreenKeyboard)
	},
	beforeUnmount() {
		window.removeEventListener('keydown', this.inputScreenKeyboard)
		this.onFocus('unmount')
	}
}
</script>

<style scoped>
	.section{
        position: relative;
		z-index: 99;
        width: 100%;
        height: 100vh;
    }

    .section_inner{
        position: absolute;
		top: 30px;
		left: 30px;
		right: 30px;
		bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

	.header{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;
	}

	.header_btn{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20%;
		padding: 15px;
		background: #DADCE0;
		border-radius: .5rem;
		transition: .1s linear;
		text-decoration: none;
	}

	.header_btn span{
		font-family: "Open Sans";
        font-size: 24px;
        font-weight: 700;
        color: #80868B;
	}

	.header_btn:hover{
		background: #0071BC;
	}

	.header_btn svg{
		fill: #80868B;
		margin-right: 10px;
	}

	.header_btn:hover span{
		color: #fff;
	}

	.header_btn:hover svg{
		fill: #fff;
	}

	.header_title{
		font-family: "Open Sans";
        font-size: 36px;
        font-weight: 700;
		color: #000;
	}

	.header_filters{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.filter{
		padding: 15px;
		background: #fff;
		border: none;
		border-radius: .5rem;
		font-family: "Open Sans";
		font-weight: 400;
		font-size: 19px;
		color: #000;
	}

	.search{
		margin-bottom: 20px;
	}

	.session_filter{
		position: relative;
	}

	.session{
		padding: 15px 160px 15px 15px;
	}

	.session_btn{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		padding: 0 15px;
		border-radius: 0 .5rem .5rem 0;
		border: none;
		background-color: #dadce0;
		font-family: "Open Sans";
		font-weight: 700;
		font-size: 14px;
		color: #80868b;
		transition: .1s ease-out;
	}

	.session_btn:hover{
		background-color: #0071BC;
		color: #fff;
	}

	.table{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 20px;
		background: #fff;
		overflow-y: auto;
		width: 100%;
		margin-bottom: 30px;
	}

	.title_row{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: solid 1px #e5e5e5;
		margin-bottom: 10px;
	}

	.title_row_item{
		display: flex;
		align-items: center;
		padding: 10px;
		flex: 0 0 calc(100%/7);
		max-width: calc(100%/7);
		font-family: "Open Sans";
        font-size: 14px;
        font-weight: 700;
		color: #80B8DD;
		user-select: none;
	}

	.title_row_item.sort{
		cursor: pointer;
	}

	.reverse{
		transform: rotate(180deg);
	}

	.to_right{
		display: flex;
		justify-content: flex-end;
	}

	.title_row_item svg{
		margin-right: 10px;
	}

	.values_row{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.value_row_item{
		font-family: "Open Sans";
		font-size: 13px;
		font-weight: 300;
		padding: 10px;
		flex: 0 0 calc(100%/7);
	}

	.section_info{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.info_row{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 80%;
	}

	.info_row:first-of-type{
		margin-bottom: 20px;
	}

	.info_title{
		font-family: "Open Sans";
		font-weight: 600;
		font-size: 19px;
		color: #000;
		margin-bottom: 15px;
	}

	.info_row_title{
		font-family: "Open Sans";
        font-size: 24px;
        font-weight: 600;
		color: #000;
	}

	.info_row_inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.info_row_item{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 15px 30px;
		background: #fff;
		flex: 0 0 25%;
		max-width: 23%;
		overflow: auto;
		text-overflow: ellipsis;
	}

	.info_row_item_overhead{
		font-family: "Open Sans";
		font-weight: 700;
		font-size: 17px;
		color: #9FA2B4;
		margin-bottom: 15px;
		width: 100%;
		text-align: center;
	}

	.info_row_item_title{
		font-family: "Open Sans";
		font-weight: 700;
		font-size: 28px;
		color: #000;
		width: 100%;
		text-align: center;
	}

	.pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
    }

    .count{
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #707683;
        padding: 5px 15px;
        cursor: pointer;
    }

    .current{
        border: solid 1px #707683;
        color: #0071BC;
    }

    .btn{
        background-color: transparent;
        border: none;
        padding: 5px 15px;
        cursor: pointer;
    }

    .prev_btn{
        transform: rotate(180deg);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

	.edit_page{
        width: 80px;
        padding: 5px 15px;
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #0071BC;
        text-align: center;
        padding: 5px 15px;
        border: solid 1px #707683;
        overflow: visible;
    }


</style>