<template>
	<loading v-if="isLoading"></loading>
	<form action="" class="form" @keydown.prevent>
		<div class="sum_field">
			<p v-if="emptySumField">Заполните это поле</p>
			<p v-if="invalidSumField">Введите корректную сумму</p>
			<input 
				:class="{valid: true, invalid: emptySumField || invalidSumField}" 
				readonly
				type="number" 
				@input="changeRepSum" 
				@keydown.prevent
				placeholder="Сумма пополнения" 
				autocomplete="on" 
				v-model="repSum">
		</div>
		<div class="form_btns">
			<p v-if="emptyMethodField">Выберите метод оплаты</p>
			<div class="form_btns_inner">
				<button @click.prevent="cashSelect" :class="{form_btn: true, selected: cash}">Картой</button>
				<button @click.prevent="cashlessSelect" :class="{form_btn: true, selected: cashless}">Налич<wbr>ными</button>
				<button @click.prevent="kaspiSelect" :class="{form_btn: true, selected: kaspi}">Kaspi <wbr>QR</button>
			</div>
		</div>
		<div class="section_btns">
			<router-link to="sum" class="section_btn" >Назад</router-link>
			<button @click.prevent="replenishment()" :class="{section_btn: true, active: repSum && (cash || cashless || kaspi)}">Пополнить</button>
		</div>
	</form>

	<print-content 
		v-if="printContent" 
		:data="collectedData"
		@close="printCheck" ></print-content>

	<teleport to="body">
		<div :class="{modal_back: true, active: $store.state.keyboardVisible}" v-if="false">
			<div class="modal">
				<div class="modal_inner">
					<div class="modal_img">
						<img src="@/assets/replenishment/cross.svg" alt="Cross">
					</div>
					<div class="modal_title">Ошибка при выполнении!</div>
					<div class="modal_btns">
						<button class="modal_btn" @click.prevent="modalRepShow">Отмена</button>
						<button class="modal_btn" @click.prevent="cardModalShow">Повторить</button>
					</div>
				</div>
			</div>
		</div>

		<div :class="{modal_back: true, active: $store.state.keyboardVisible}" v-if="modalSuccessRep">
			<div class="modal">
				<div class="modal_inner">
					<div class="modal_img">
						<img src="@/assets/replenishment/tick.svg" alt="Cross">
					</div>
					<div class="modal_title">Пополнено на сумму {{ repSum }}</div>
					<div class="modal_btns">
						<button class="modal_btn" @click.prevent="completeReplenishment">Выйти</button>
						<button class="modal_btn" @click.prevent="printCheck">Печать чека</button>
					</div>
				</div>
			</div>
		</div>

		<div :class="{modal_back: true, active: $store.state.keyboardVisible}" v-if="modalUnsuccRep">
			<div class="modal">
				<div class="modal_inner">
					<div class="modal_img">
						<img src="@/assets/replenishment/cross.svg" alt="Tick">
					</div>
					<div class="modal_title">Ошибка при пополнении</div>
					<div class="modal_btns">
						<router-link class="modal_btn" to="/cashbox/card-id">Выйти</router-link>
						<router-link class="modal_btn" to="/cashbox/replenishment/sum">Повторить</router-link>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
import PrintContent from '../PrintContent.vue'
import Loading from '../Loading.vue';  

export default {
	name: 'PayMethod',
	components: {
		Loading,
		PrintContent
	},
	data() {
		return{
			repSum: window.localStorage.getItem('withDiscount'),
			cash: false,
			cashless: false,
			kaspi: false,
			modalSuccessRep: false,
			modalUnsuccRep: false,
			payMethodModal: false,
			questionModal: false,
			emptySumField: false,
			emptyMethodField: false,
			invalidSumField: false,
			printContent: false,
			isLoading: false,	
			collectedData: {}
		}
	},
	methods: {
		async replenishment() {
			try{
				if((!this.repSum.startsWith(0) && this.repSum.length > 0 && this.repSum > 0) && (this.cashless || this.cash || this.kaspi)) {
					this.isLoading = true;
					const payload = {
						url: this.$store.state.apiUrl + 'v1/front/add-replenishment',
						data: {
							amount: window.localStorage.getItem('sum'),
							card_code: window.localStorage.cardId,
							payment_method: this.payMethod,
							discount_id: window.localStorage.discount_id === 'null' ? null : Number(window.localStorage.discount_id),
						}
					}	
					const data = await this.$store.dispatch('sendData', payload);
					if(data.data.status === 200) {
						this.modalSuccessRepShow();
						this.emptySumField = false;
						this.emptyMethodField = false;
						this.invalidSumFiled = false;
						this.collectedData = data.data
					}else{
						this.modalUnsuccRepShow();
					}
					this.isLoading = false;
				}
				if(!this.cashless && !this.cash && !this.kaspi) {
					this.emptyMethodField = true;
				}
			}catch(err) {
				this.modalUnsuccRepShow();
			}
		},
		inputScreenKeyboard(evt) {
			if(/\d+/.test(evt.key)) {
				this.sum += evt.key
			} else if(evt.key === 'Backspace') {
				this.sum = this.sum.substring(0, this.sum.length - 1)
			} else if(evt.key === 'Enter') {
				if((!this.repSum.startsWith(0) && this.repSum.length > 0 && this.repSum > 0) && (this.cashless || this.cash || this.kaspi)) {
					this.replenishment()
				}
			}
		},
		cashSelect() {
			this.cash = !this.cash;
			this.cashless = false;
			this.kaspi = false;
		},
		cashlessSelect() {
			this.cashless = !this.cashless;
			this.cash = false;
			this.kaspi = false;
		},
		kaspiSelect() {
			this.kaspi = !this.kaspi;
			this.cash = false;
			this.cashless = false;
		},
		modalSuccessRepShow() {
			this.modalSuccessRep = !this.modalSuccessRep;
			this.cashless = false;
			this.cash = false;
			this.kaspi = false;
		},
		modalUnsuccRepShow() {
			this.modalUnsuccRep = !this.modalUnsuccRep;
		},
		changeRepSum() {
			if(this.repSum.length === 0){
				this.emptySumField = true;
				this.invalidSumField = false;
			}else if(this.repSum.startsWith(0) || this.repSum < 0) {
				this.invalidSumField = true;
				this.emptySumField = false;
			}else if(!this.repSum.startsWith(0) && this.repSum.length > 0 && this.repSum > 0) {
				this.invalidSumField = false;
				this.emptySumField = false;
				window.localStorage.setItem('sum', this.repSum);
			}
		},
		completeReplenishment() {
			this.$router.push('/cashbox/card-id');
		},
		printCheck() {
			this.printContent = !this.printContent;
		}
	},
	computed: {
		payMethod() {
			if(this.cash) {
				return 1
			}else if(this.cashless) {
				return 2
			}else if(this.kaspi) {
				return 3
			}
			return null
		}
	},
	watch: {
		payMethod() {
			if(!this.cashless && !this.cash && !this.kaspi) {
				this.emptyMethodField = true;
			}else{
				this.emptyMethodField = false;
			}
		}
	},
	mounted() {
		window.addEventListener('keydown', this.inputScreenKeyboard)
	},
	beforeUnmount() {
		window.removeEventListener('keydown', this.inputScreenKeyboard)
	}
}
</script>

<style scoped>
	.form{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 70%;
		width: 100%;
	}

	.sum_field{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.sum_field p{
		font-family: "Open Sans";
		font-weight: 400;
		font-size: 18px;
		color: #ED1C24;
		margin-bottom: 10px;
		text-align: center;
	}

	.valid{
        width: 60%;
        padding: 20px;
        border-radius: .5rem;
        border: solid 3px #0071BC;
        font-family: "Open Sans";
        font-size: 36px;
        font-weight: 600;
        color: #000;
        margin-bottom: 40px;
    }

	.form_btns{
		width: 80%;
		display: flex;
		flex-direction: column;
	}

	button{
		cursor: pointer;
	}

	.form_btns_inner{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.form_btns p{
		font-family: "Open Sans";
		font-weight: 400;
		font-size: 18px;
		color: #ED1C24;
		margin-bottom: 10px;
		text-align: center;
	}

	.form_btn{
		flex: 0 0 30%;
		max-width: 30%;
		padding: 30px 15px;
		background: transparent;
		border: solid 2px #BFBFBF;
		border-radius: .5rem;
		font-family: "Open Sans";
		font-size: 19px;
		font-weight: 700;
		color: #BFBFBF;
		text-align: center;
	}

	.form_btn.selected{
		border: solid 2px #0071BC;
		color: #000;
	}

	.section_btns{
		display: flex;
		justify-content: space-between;
		width: 50%;
	}

	.section_btn{
		font-family: "Open Sans";
        font-size: 24px;
        font-weight: 700;
        color: #fff;
		padding: 15px 50px;
		border: none;
		background: #DADCE0;
		border-radius: .5rem;
		transition: .1s linear;
		text-decoration: none;
	}

	.section_btn.active{
		background: #0071BC;
	}

	.modal_back{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 999;
	}

	.modal_back.active{
		right: 25%;
	}

	.modal{
		width: 50%;
		padding: 30px;
		background: #fff;
		border-radius: .5rem;
		box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
	}

	.modal_inner{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.modal_img{
		margin-bottom: 30px;
	}

	.modal_title{
		font-family: "Open Sans";
		font-size: 36px;
		font-weight: 700;
		color: #000;
		text-align: center;
		margin-bottom: 40px;
	}

	.modal_subtitle{
		font-family: "Open Sans";
		font-size: 24px;
		font-weight: 400;
		color: #000;
		text-align: center;
		margin-bottom: 40px;
	}

	.modal_btns{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.modal_btn{
		font-family: "Open Sans";
		font-size: 24px;
		font-weight: 700;
		color: #fff;
		padding: 15px;
		width: 48%;
		background: #AAAFB5;
		border-radius: .5rem;
		text-align: center;
		user-select: none;
		cursor: pointer;
		transition: .1s linear;
		border: none;
		text-decoration: none;
	}
	
	.modal_btns.pay_method{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.modal_btns.pay_method .modal_btn{
		width: 30%;
		padding: 30px 15px;
		font-size: 16px;
	}

	.modal_btn:hover{
		background: #0071BC;
	}
</style>