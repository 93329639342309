<template>
	<loading v-if="isLoading"></loading>
    <section class="admin">
        <aside :class="{nav: true, resize: resized}">
            <div class="nav_wrp">
                <h1 class="nav_title">{{ $store.state.name }}</h1>
                <nav class="nav_inner">
                    <router-link 
                        :to="link.link" 
                        :class="{nav_link: true, selected: link.selected}"
                        v-for="link in links"
                        :key="link.id">
                        {{ link.label }}</router-link>
                </nav>
            </div>
            <router-link @click="logOut" to="/" replace class="exit">
                <img src="@/assets/admin/exit.svg" alt="Exit">
                <span>Выход</span>
            </router-link>
        </aside>
		<button class="resize_btn" @click.prevent="resize()">Меню</button>
        <div :class="{admin_inner: true, resize: resized}" @click="resize(true)">
            <router-view></router-view>
        </div>
    </section>
	<teleport to="body">
		<transition>
			<message-notification 
				v-if="messageVisible" 
				:data="message"
				@close="(idx) => closeMessage(idx)"></message-notification>
		</transition>
	</teleport>
</template>

<script>
import MessageNotification from './MessageNotification.vue';
import Loading from '@/components/cashier/Loading.vue'
import firebase from 'firebase';
import 'firebase/messaging';

export default {
    name: 'Admin',
	components: {
		MessageNotification,
		Loading
	},
	data() {
        return{
            links: [
                {link: '/admin/devices', label: 'Устройства', id: 1},
                {link: '/admin/users', label: 'Пользователи', id: 2},
                {link: '/admin/payments', label: 'Пополнение', id: 3},
                {link: '/admin/price-list', label: 'Прайс лист', id: 4},
                {link: '/admin/reports', label: 'Отчеты', id: 5},
                {link: '/admin/add-options', label: 'Доп. Опции', id: 6},
            ],
            prevSelected: null,
			resized: false,
			messageVisible: false,
			message: [],
			isLoading: false,
        }
    },
    methods: {
		async logOut() {
			try{
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/logout/logout',
					data: {}
				}
				await this.$store.dispatch('sendData', payload);
				this.isLoading = false;
				window.localStorage.clear();
			}catch(err) {
				throw new Error(err)
			}
		},
		resize(close=false) {
			if(close) {
				this.resized = false;
				return
			}
			this.resized = !this.resized
		},
		closeMessage(idx) {
			this.message.splice(idx, 1)
			if(this.message.length === 0) {
				this.messageVisible = false
			}
		},
		async sendToken(token) {
			try{
				this.$store.state.messagingTokenError = false;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/add-token',
					data: {
						token: token,
					}
				}
				await this.$store.dispatch('sendData', payload)
			}catch(err) {
				this.$store.state.messagingTokenError = true;
			}
		},
		messaging() {
			firebase.initializeApp({
				apiKey: "AIzaSyDLhP9_hxeVV-wrkqVCRRC3J9RxZOaBt-g",
				authDomain: "fantasy-world-b3eff.firebaseapp.com",
				projectId: "fantasy-world-b3eff",
				storageBucket: "fantasy-world-b3eff.appspot.com",
				messagingSenderId: "789533482907",
				appId: "1:789533482907:web:168f0ffe5a54f4a4010b3e",
				measurementId: "G-ERNBD8PQST"
			})

			const messaging = firebase.messaging();

			messaging.requestPermission()
				.then(() => {
					return messaging.getToken({vapidKey: "BBpU-Y8n-QOk9QhMGGrZMu9AKuMa2_F5REkb34dsfzdEzyu_2yUja3YmboRTLwh5gLwnFgXc31zYaJB0i1-m9Wg"});
				})
				.then((token) => {
					this.sendToken(token)
				})
				.catch((err) => {
					throw new Error(err)
				})

			messaging.onMessage((payload) => {
				payload.delete = false;
				this.message.push(payload)
				this.messageVisible = true;
			});
		},
    },
	mounted() {
		this.messaging();
	}
}
</script>

<style scoped>
    .admin{
        position: relative;
		z-index: 99;
        display: flex;
        justify-content: flex-end;
		align-items: flex-start;
        width: 100%;
        height: 100vh;
    }

    .nav{
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 20%;
        height: 100%;
        background-color: #363740;
		transition: .2s;
    }

    .nav_wrp{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .nav_title{
        font-family: "Open Sans";
        font-size: 24px;
        font-weight: 700;
        color: #fff;
        padding: 30px;
        margin-bottom: 30px;
    }

    .nav_inner{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    .nav_link{
        position: relative;
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 400;
        color: #A4A6B3;
        text-decoration: none;
        width: 100%;
        padding: 25px;
        transition: .2s linear;
        user-select: none;
        cursor: pointer;
    }

    .nav_link::before{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: #DDE2FF;
        transition: .2s linear;
        transform: scale(0);
    }

    .nav_link:hover{
        background-color: rgba(159, 162, 180, 0.1);
        color: #DDE2FF;
    }

    .nav_link:hover::before{
        transform: scale(1);
    }

    .nav_link.active{
        background-color: rgba(159, 162, 180, 0.1);
        color: #DDE2FF;
    }

    .nav_link.active::before{
        transform: scale(1);
    }

    .exit{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 25px;
        cursor: pointer;
        text-decoration: none;
    }

    .exit img{
        margin-right: 7px;
    }

    .exit span{
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 400;
        color: #A4A6B3;
    }

    .admin_inner{
        position: relative;
        z-index: 99;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 80%;
		transition: .2s linear;
    }

	.resize_btn{
		display: none;
		position: absolute;
		left: 0;
		z-index: 1000;
		padding: 10px 15px;
		opacity: .1;
		transition: .2s linear;
		border: none;
		background-color: #0071BC;
		font-family: "Open Sans";
		font-weight: 600;
		font-size: 14px;
		color: #fff;
	}

	.resize_btn:hover{
		opacity: 1;
	}

	.v-enter-from, .v-leave-to{
		opacity: 0;
		transform: translateY(-100%)
	}

	.v-enter-active, .v-leave-active{
		transition: all .2s ease-out;
	}

	.v-enter-to, .v-leave-from{
		opacity: 1;
		transform: translateY(0)
	}

	@media(max-width: 1000px) {
		.resize_btn{
			display: block;
		}

		.nav{
			transform: translateX(-100%);
			width: 40%;
			visibility: hidden;
		}

		.nav.resize{
			transform: translateX(0);
			visibility: visible;
		}

		.admin_inner{
			width: 100%;
		}
	}
</style>