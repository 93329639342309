<template>
    <div class="add_options">
        <div class="container">
            <div class="add_options_inner">
                <header class="header">
                    <h1 class="title">Доп. опции</h1>
                </header>
                <nav class="nav">
                    <router-link 
                        to="/admin/add-options/discounts" 
                        class="nav_link" >
                        Скидки</router-link>
                    <router-link 
                        to="/admin/add-options/send-message" 
                        class="nav_link" >
                        Отправить сообщение</router-link>
                        <router-link 
                        to="/admin/add-options/balance-migrate" 
                        class="nav_link" >
                        Перенос баланса</router-link>
                        <router-link 
                        to="/admin/add-options/cards" 
                        class="nav_link" >
                        Карты</router-link>
                </nav>
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AddOptions',
}
</script>

<style scoped>
    .add_options{
        position: relative;
        width: 100%;
        height: 100vh;
    }

    .add_options_inner{
        position: absolute;
        top: 20px;
        bottom: 20px;
        right: 20px;
        left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .header{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: 30px;
    }

    .title{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 36px;
        color: #0071BC;
        margin-right: 20px;
    }

    .nav{
        width: calc(100% + 40px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: -20px;
        margin-bottom: 30px;
        background-color: #fff;
    }

    .nav_link{
        position: relative;
        padding: 20px 35px;
        height: 100%;
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 600;
        color: #363740;
        text-decoration: none;
        transition: .2s linear;
        user-select: none;
        cursor: pointer;
    }

    .nav_link:not(:last-of-type) {
        border-right: solid 2px #BDC1C6;
    }

    .nav_link::after{
        position: absolute;
        content: '';
        bottom: 0;
        right: 0;
        left: 0;
        height: 3px;
        background-color: #0071BC;
        transition: .2s linear;
        transform: scale(0);
    }

    .nav_link:hover{
        background-color: rgba(62,64,73,.1);
    }
    
    .nav_link:hover::after{
        transform: scale(1);
    }

    .nav_link.active{
        background-color: rgba(62,64,73,.1);
    }

    .nav_link.active::after{
        transform: scale(1);
    }
</style>    