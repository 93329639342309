<template>
    <div class="users">
        <div class="container">
            <div class="users_inner">
                <header class="header">
                    <h1 class="title">Пользователи</h1>
                    <h3 class="subtitle">Кассиры/Операторы</h3>
                </header>
                <nav class="nav">
                    <router-link 
                        to="/admin/users/table" 
                        class="nav_link" >
                        Таблица</router-link>
                </nav>
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Users',
}
</script>

<style scoped>
    .users{
        position: relative;
        width: 100%;
        height: 100vh;
    }

    .users_inner{
        position: absolute;
        top: 20px;
        bottom: 20px;
        right: 20px;
        left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .header{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: 30px;
    }

    .title{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 36px;
        color: #0071BC;
        margin-right: 20px;
    }

    .subtitle{
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 24px;
        color: #0071BC;
        padding-bottom: 4px;
    }

    .nav{
        width: calc(100% + 40px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: -20px;
        margin-bottom: 30px;
        background-color: #fff;
    }

    .nav_link{
        position: relative;
        padding: 20px 35px;
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 600;
        color: #363740;
        text-decoration: none;
        transition: .2s linear;
        user-select: none;
        cursor: pointer;
    }

    .nav_link:not(:last-of-type) {
        border-right: solid 2px #BDC1C6;
    }

    .nav_link::after{
        position: absolute;
        content: '';
        bottom: 0;
        right: 0;
        left: 0;
        height: 3px;
        background-color: #0071BC;
        transition: .2s linear;
        transform: scale(0);
    }

    .nav_link:hover{
        background-color: rgba(62,64,73,.1);
    }
    
    .nav_link:hover::after{
        transform: scale(1);
    }

    .nav_link.active{
        background-color: rgba(62,64,73,.1);
    }

    .nav_link.active::after{
        transform: scale(1);
    }

</style>