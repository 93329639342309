<template>
    <div class="payments">
		<loading v-if="isLoading"></loading>
        <div class="container">
            <div class="payments_inner">
                <h1 class="title">Пополнение</h1>
                <div class="filter_wrp" v-if="!error">
                    <button class="filter" @keypress.prevent="" @click="filterModalWindow">
                        <span>Фильтр</span>
                        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.9963 1.5H1.49969V3.8775L7.05604 9.4425L7.49845 9.8775V16.5H10.4978V15H11.9975V16.5C11.9975 16.8978 11.8395 17.2794 11.5583 17.5607C11.277 17.842 10.8956 18 10.4978 18H7.49845C7.10071 18 6.71925 17.842 6.43801 17.5607C6.15676 17.2794 5.99876 16.8978 5.99876 16.5V10.5L0.442409 4.9425C0.302062 4.80296 0.190714 4.63701 0.114784 4.45423C0.0388545 4.27144 -0.000156336 4.07543 4.7085e-07 3.8775V1.5C4.7085e-07 1.10218 0.158003 0.720644 0.439249 0.43934C0.720496 0.158035 1.10195 0 1.49969 0H17.9963V1.5Z" fill="#0071BC"/>
                            <path d="M20.7782 5.4675L18.5287 3.2175C18.459 3.1472 18.376 3.09141 18.2846 3.05333C18.1933 3.01526 18.0953 2.99565 17.9963 2.99565C17.8973 2.99565 17.7993 3.01526 17.7079 3.05333C17.6165 3.09141 17.5336 3.1472 17.4639 3.2175L10.4978 10.1925V13.5H13.8046L20.7782 6.5325C20.8485 6.46278 20.9043 6.37983 20.9423 6.28843C20.9804 6.19704 21 6.09901 21 6C21 5.90099 20.9804 5.80296 20.9423 5.71157C20.9043 5.62017 20.8485 5.53722 20.7782 5.4675ZM13.1898 12H11.9975V10.8075L15.7467 7.0575L16.939 8.25L13.1898 12ZM17.9963 7.1925L16.804 6L17.9963 4.8075L19.1885 6L17.9963 7.1925Z" fill="#0071BC"/>
                        </svg>
                    </button>
                </div>
				<error-message v-if="error"></error-message>
                <div class="table" ref="table" v-else>
                    <div class="table_inner">
                        <div class="row_titles">
                            <h3 class="row_title_item id">
                                <span>ID номер карты</span>
                            </h3>
                            <h3 @click.prevent="sortBySum()" class="row_title_item sort">
								Сумма пополнения
								<img :class="{reverse: !sumReverse}" src="@/assets/reports/arrow-2.svg" alt="">
							</h3>
                            <h3 @click.prevent="sortByDate()" class="row_title_item sort">
								Дата пополнения
								<img :class="{reverse: !dateReverse}" src="@/assets/reports/arrow-2.svg" alt="">
							</h3>
                            <h3 class="row_title_item">Кассир</h3>
                            <h3 class="row_title_item">Способ оплаты</h3>
                            <h3 class="row_title_item">Статус</h3>
                        </div>
                        <div
                            class="row"
                            v-for="(pay, idx) in payments"
                            :key="idx + 1">
                            <h3 class="row_item id">
                                <span>{{ pay.id }}</span>
                            </h3>
                            <h3 class="row_item">{{ pay.sum }}</h3>
                            <h3 class="row_item">{{ pay.date }}</h3>
                            <h3 class="row_item">{{ pay.cashier }}</h3>
                            <h3 class="row_item">{{ pay.paymentMethod }}</h3>
                            <h3 class="row_item">Успешно</h3>
                        </div>
                    </div>
                    <div class="pagination">
                        <span class="prev count" @click="toStart">1</span>
                        <button class="prev_btn btn" @click="prev">
                            <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.177677 7.79061C0.291166 7.92467 0.445244 8 0.605923 8C0.766602 8 0.920679 7.92467 1.03417 7.79061L3.82232 4.5047C4.05923 4.22551 4.05923 3.7745 3.82232 3.49531L1.03417 0.209397C0.797267 -0.0697987 0.414579 -0.0697988 0.177677 0.209397C-0.0592249 0.488591 -0.0592249 0.939599 0.177677 1.21879L2.53455 4.00358L0.177677 6.78122C-0.0592255 7.06041 -0.0531511 7.51858 0.177677 7.79061Z" fill="#707683"/>
                            </svg>
                        </button>
                        <input type="number" v-model="page" class="edit_page">
                        <button class="next_btn btn" @click="next">
                            <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.177677 7.79061C0.291166 7.92467 0.445244 8 0.605923 8C0.766602 8 0.920679 7.92467 1.03417 7.79061L3.82232 4.5047C4.05923 4.22551 4.05923 3.7745 3.82232 3.49531L1.03417 0.209397C0.797267 -0.0697987 0.414579 -0.0697988 0.177677 0.209397C-0.0592249 0.488591 -0.0592249 0.939599 0.177677 1.21879L2.53455 4.00358L0.177677 6.78122C-0.0592255 7.06041 -0.0531511 7.51858 0.177677 7.79061Z" fill="#707683"/>
                            </svg>
                        </button>
                        <span class="next count" @click="toEnd">{{ pagesAmountCount }}</span>
                    </div>
                </div>

                <a class="submit_btn" :href="$store.state.downloadXlsLink" v-if="!error">Выгрузить в XLS</a>
            </div>
        </div>

		<modal-window 
			v-if="modalFilter"
			:data="collectedData"
			:title="'Фильтр'"
			:selectField="true"
			:mixField="'checkbox-checkbox-checkbox'"
			:checkboxFieldLabels="['Картой', 'Наличными', 'Kaspi QR']"
			:checkboxFieldOptions="{label: '', validate: 'checkbox-checkbox-checkbox'}"
			:dateField="true"
			:buttonsText="['Отменить', 'Применить', true]"
			@send="(data) => isLoadingData(data, true)"
			@close="filterModalWindow()"></modal-window>
    </div>
</template>

<script>
import Loading from '../Loading.vue';
import ModalWindow from '../ModalWindow.vue';
import ErrorMessage from '../ErrorMessage.vue';

export default {
    name: 'Payments',
    components: {
		Loading,
		ModalWindow,
		ErrorMessage
    },
    data() {
        return{
            payments: '',
			userList: [],
            page: 1,
			pagesAmountCount: 0,
            modalFilter: false,
			isLoading: false,
			collectedData: {
				first: {},
				second: {},
				component: 'payments'
			},
			filter: {
				userId: '',
				paymentMethod: '',
				userFrom: '',
				userTo: ''
			},
			isFiltering: false,
			sumReverse: true,
			dateReverse: false,
			error: false
        }
    },
    methods: {
		async getPaymentsData(data={}, filter=false) {
			try{
				this.sumResult = 0
				this.error = false;
				this.isFiltering = filter
				if(this.isFiltering) {
					this.page = 1
				}
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/replenishment-pagination-list',
					data: {
						page: this.page - 1,
						limit: 50,
						userId: data.userId,
						paymentMethod: data.paymentMethod,
						dateFrom: data.userFrom,
						dateTo: data.userTo
					}
				};
				this.isFiltering = false
				this.modalFilter = false
				await this.$store.dispatch('sendData', payload).then((res) => {
					this.payments = res.data.data;
					this.pagesAmountCount = res.data.pagesCount;
				})
			}catch(err) {
				this.modalFilter = false;
				this.isLoading = false;
				this.error = true;
				this.isFiltering = false
			}
		},
		async getUserList() {
			try{
				const payload = {
				url: this.$store.state.apiUrl + 'v1/front/user-list',
				}
				await this.$store.dispatch('sendData', payload).then((res) => {
					this.userList = res.data.filter((item) => {
						return item.profile === '2';
					});
					if(this.userList.length === 0) {
						this.userList.push({
							id: '',
							fullName: 'Список пуст'
						})
					}else{
						this.userList.push({
							id: '',
							fullName: 'Все возможные'
						})
					}
				})
			}catch(err) {
				this.userList.push({
					id: '',
					fullName: 'Список кассиров недоступен'
				})
			}
		},
		async downloadFile() {
			try{
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/url-excel-replenishment',
					data: {
                        token: 123
                    }
				}
				this.$store.dispatch('downloadFile', payload);
			}catch(err) {
				throw new Error(err)
			}
		},
		async isLoadingData(data, filter) {
			this.isLoading = true;
			if(data) {
				this.filter = data
			}
			await this.getPaymentsData(this.filter, filter)
			await this.getUserList()
			await this.downloadFile()
			this.isLoading = false;
		},
		sortBySum() {
			if(!this.sumReverse) {
				this.payments.sort((a, b) => {
					return Number.parseInt(a.sum) - Number.parseInt(b.sum)
				});
			}else{
				this.payments.reverse();
			}
			this.sumReverse = !this.sumReverse;
		},
		getMilliseconds(rep) {
			const regexp = /\d+/g
			const dateList = [...rep.date.match(regexp)].reverse()
			const milliseconds = new Date(dateList[0], dateList[1], dateList[2], dateList[4], dateList[3]).getTime()
			return milliseconds
		},
		sortByDate() {
			if(!this.dateReverse) {
				this.payments.sort((a, b) => {
					return this.getMilliseconds(a) - this.getMilliseconds(b)
				})
			}else{
				this.payments.reverse();
			}
			this.dateReverse = !this.dateReverse
		},
		collectData() {
			this.collectedData.second = this.userList;
		},
        filterModalWindow() {
            this.modalFilter = !this.modalFilter;
            this.collectData()
        },
        prev() {
            if(this.page > 1) {
                this.page--
            } 
        },
        next() {
            if(this.page < this.pagesAmountCount) {
                this.page++
            } 
        },
        toStart() {
            this.page = 1;
        },
        toEnd() {
            this.page = this.pagesAmountCount;
        },
    },
    mounted() {
        this.isLoadingData();
    },
	watch: {
		page() {
			if(this.page !== '' && Number(this.page) > 0 && !(Number(this.page) > Number(this.pagesAmountCount)) && !this.isFiltering) {
				this.isLoadingData();
			}
		}
	}
}
</script>

<style scoped>
    .wrp{
        position: relative;
        z-index: 12321321;
    }

    .payments{
        position: relative;
        width: 100%;
        height: 100vh;
    }

    .payments_inner{
        position: absolute;
        top: 20px;
        bottom: 20px;
        right: 20px;
        left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .title{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 36px;
        color: #0071BC;
        margin-bottom: 25px;
    }

    .filter_wrp{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 25px;
    }

    .filter{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 10px;
        cursor: pointer;
        user-select: none;
    }

    .filter span{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 14px;
        color: #0071BC;
        margin-right: 10px;
        user-select: none;
    }

    .title{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 36px;
        color: #0071BC;
        margin-bottom: 30px;
    }

    .table{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        padding: 10px 15px;
        overflow-y: auto;
        margin-bottom: 30px;
    }

    .table_inner{
        width: 100%;
        margin-bottom: 30px;
    }

    .row_titles{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
    }

    .row_title_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 700;
        color: rgba(0,113,188,0.5);
        padding: 7px;
        width: calc(100%/6);
        padding: 5px;
		user-select: none;
    }

	.row_title_item.sort{
		cursor: pointer;
	}

    .row_title_item.id{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

	.row_title_item img.reverse{
		transform: rotate(180deg);
	}

    .checkbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        border: solid 2px #D5D5D5;
        border-radius: 0.2rem;
        margin-right: 10px;
        cursor: pointer;
    }

    .checkbox svg{
        margin: 0;
    }

    .checkbox.selected{
        border: solid 2px #90C1E1;
        background: #0071BC;
    }

    .checkbox.all_selected{
        border: solid 2px #90C1E1;
        background: #0071BC;
    }

    .row_title_item.action{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .row:not(:last-of-type) {
        margin-bottom: 15px;
    }

    .row_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 400;
        color: #707683;
        padding: 5px;
        width: calc(100%/6);
    }

    .row_item.id{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 600;
        color: #323C47;
        padding: 7px;
    }

    .row_item.action{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    .row_item.id{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

	.result{
		align-self: flex-start;
		margin-bottom: 20px;
	}

    .pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
    }

    .count{
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #707683;
        padding: 5px 15px;
        cursor: pointer;
    }

    .current{
        border: solid 1px #707683;
        color: #0071BC;
    }

    .btn{
        background-color: transparent;
        border: none;
        padding: 5px 15px;
        cursor: pointer;
    }

    .prev_btn{
        transform: rotate(180deg);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .edit_page{
        width: 80px;
        padding: 5px 15px;
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #0071BC;
        text-align: center;
        padding: 5px 15px;
        border: solid 1px #707683;
        overflow: visible;
    }

    .modal_back{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal_window{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        background-color: #fff;
        min-width: 25%;
        border-radius: .5rem;
    }

    .modal_title{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 18px;
        color: #000;
        text-align: center;
        margin-bottom: 30px;
    }

    .modal_form_row.first{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 15px;
    }

    .modal_form_row.first input{
        padding: 10px 25px;
        border: none;
        background-color: #DADCE0;
        border-radius: .5rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
    }

    .label{
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
        margin-bottom: 10px;
        user-select: none;
    }

    .modal_form_wrp{
        display: flex;
        justify-content: flex-start;
        width: 48%;
    }

    .modal_form_wrp span{
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 12px;
        color: #707683;
        user-select: none;
    }

    .modal_form_row.second{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    .modal_form_row_inner.second{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .select{
        background: #DADCE0;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('~@/assets/add-options/arrow.svg');
        background-repeat: no-repeat;
        background-position-x: 97%;
        background-position-y: 50%;
        border: none;
        border-radius: .5rem;
        padding: 10px 30px 10px 25px;
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #000;
    }

    .modal_form_row.second input{
        width: 100%;
        padding: 10px 25px;
        border: none;
        background-color: #DADCE0;
        border-radius: .5rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
    }

    #pass{
        width: 100%;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 600;
        color: #000;
        padding: 10px 40px 10px 10px;
        border-radius: .5rem;
        border: none;
        background: #DADCE0;
    }

    .modal_form_row.third{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .modal_form_row_inner.third{
        display: flex;
        justify-content: space-between;
    }

    .calendar{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .calendar span{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 400;
        color: #000;
        margin-right: 10px;
    }

    .calendar input{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        padding: 10px 20px;
        background: #DADCE0;
        border: none;
        border-radius: .5rem;
        transition: .2s linear;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 400;
        color: #000;
        background-image: url('../../../assets/payments/calendar.svg');
        background-repeat: no-repeat;
        background-position: 90% 50%;
    } 

    .calendar button:hover{
        background-color: #c5c5c5;
    }

    .modal_form_row.fourth{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal_form_row.fourth button{
        width: 48%;
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 12px;
        color: #80868B;
        background-color: #DADCE0;
        padding: 15px 30px;
        border: none;
        border-radius: .5rem;
        transition: .2s linear;
    }

    .modal_form_row.fourth button:hover{
        color: #fff;
        background-color: #0071BC;
    }

    .submit_btn{
        width: 20%;
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 12px;
        color: #80868B;
		text-decoration: none;
		text-align: center;
        background-color: #DADCE0;
        padding: 10px 15px;
        border: none;
        border-radius: .5rem;
        transition: .2s linear;
    }

    .submit_btn:hover{
        color: #fff;
        background-color: #0071BC;
    }
</style>