<template>
	<h1>Страница не найдена.<router-link :to="link"> Перейти на главную страницу</router-link></h1>
</template>

<script>
export default {
	name: 'NotFoundPage',
	data() {
		return{
			link: '/admin/devices',
		}
	},
	mounted() {
		if(!window.localStorage.access_token) {
			this.link = '/login';
		}else{
			this.link = '/admin/devices';
		}
	}
}
</script>

<style scoped>
	h1{
		position: relative;
		z-index: 99;
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
</style>