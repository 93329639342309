<template>
	<form class="section_form" @submit.prevent="submitSum" @keydown.prevent>
		<label :class="{sum_title: true, empty: false}" for="">Баланс карты: {{ editSum(balance) }} тг</label>
		<label :class="{sum_title: true, empty: emptySumField}" for="">Сумма пополнения</label>
		<p v-if="invalidSumField">Введите корректную сумму</p>
		<p v-if="emptySumField">Заполните это поле</p>
		<input 
			:class="{valid: true, invalid: emptySumField || invalidSumField, active: !emptySumField}" 
			type="number" 
			placeholder="Сумма пополнения" 
			autocomplete="on" 
			@keydown.prevent
			v-model.trim.number="sum">
		<div class="sum_btns">
			<button :class="{sum_btn: true, active: discountSelected}" @click.prevent="selectDiscount">Скидка</button>
			<button :class="{sum_btn: true, active: bonusSelected}" @click.prevent="selectBonus">Бонус</button>
		</div>
		<label :class="{sum_title: true, empty: emptySumField}" for="">Итоговая сумма</label>
		<input 
			:class="{read_only: true, active: !emptySumField}"
			type="number" 
			placeholder="Итоговая сумма" 
			autocomplete="on" 
			v-model.trim.number="result"
			readonly>
		<div class="section_btns">
			<button class="form_btn" @click.prevent="cancel">Отмена</button>
			<button type="submit" :class="{form_btn: true, active: !emptySumField && !invalidSumField}">Продолжить</button>
		</div>
	</form>
	<teleport to="body">
		<div class="modal_bckg" v-if="modal && discountSelected">
			<loading v-if="isLoading"></loading>
			<div class="modal_window">
				<div class="column_wrp">
					<div class="column">
						<div class="title">Название</div>
						<div class="column_item" v-for="item in discountList.discount" :key="item.id">
							<label class="radio_container">
								<input type="radio" name="discount" :value="item.id" v-model="select">
								<span class="checkmark"></span>
								<label class="label">{{ item.name }}</label>
							</label>
						</div>
					</div>
					<div class="column">
						<div class="title">Количество</div>
						<div class="column_item" v-for="item in discountList.discount" :key="item.id">
							<label class="label">{{ item.quantity }}</label>
						</div>
					</div>
				</div>
				<div class="modal_btns">
					<button class="modal_btn" @click.prevent="modalShow">Выйти</button>
					<button class="modal_btn" @click.prevent="useDiscount">Применить</button>
				</div>
			</div>
		</div>

		<div class="modal_bckg" v-if="modal && bonusSelected">
			<loading v-if="isLoading"></loading>
			<div class="modal_window">
				<div class="column_wrp">
					<div class="column">
						<div class="title">Название</div>
						<div class="column_item" v-for="item in discountList.bonus" :key="item.id">
							<label class="radio_container">
								<input type="radio" name="discount" :value="item.id" v-model="select">
								<span class="checkmark"></span>
								<label class="label">{{ item.name }}</label>
							</label>
						</div>
					</div>
					<div class="column">
						<div class="title">Количество</div>
						<div class="column_item"  v-for="item in discountList.bonus" :key="item.id">
							<label class="label">{{ item.quantity }}</label>
						</div>
					</div>
				</div>
				<div class="modal_btns">
					<button class="modal_btn" @click.prevent="modalShow">Выйти</button>
					<button class="modal_btn" @click.prevent="useDiscount">Применить</button>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
import Loading from '../Loading.vue';

export default {
	name: 'Sum',
	components: {
		Loading
	},
	data() {
		return{
			sum: '',
			result: '',
			balance: 0,
			invalid: false,
			emptySumField: true,
			invalidSumField: false,
			discountSelected: false,
			bonusSelected: false,
			modal: false,
			discountList: [],
			select: 1,
			isLoading: false
		}
	},
	methods: {
		async getData() {
			this.isLoading = true;
			const payload = {
				url: this.$store.state.apiUrl + 'v1/front/discount-double-list'
			}
			await this.$store.dispatch('getData', payload).then((res) => {
				this.discountList = res.data;
			});
			this.isLoading = false;
		},
		submitSum() {
			if(this.sum > 0 && !this.sum.startsWith(0) && this.sum.length > 0) {
				this.$router.push('/cashbox/replenishment/pay-method');
			}
			if(!this.bonusSelected && !this.discountSelected) {
				window.localStorage.setItem('discount_id', null);
			}
		},
		selectDiscount() {
			this.modalShow();
			this.discountSelected = !this.discountSelected;
			this.bonusSelected = false;
			if(window.localStorage.getItem('discount_id')) {
				this.select = window.localStorage.getItem('discount_id');
			}
		},
		selectBonus() {
			this.modalShow();
			this.bonusSelected = !this.bonusSelected;
			this.discountSelected = false;
			if(window.localStorage.getItem('discount_id')) {
				this.select = window.localStorage.getItem('discount_id');
			}
		},
		modalShow() {
			this.modal = !this.modal
			this.discountSelected = false;
			this.bonusSelected = false;
			if(!this.modal) {
				this.result = this.resultWithDiscount()
			}
		},
		useDiscount() {
			if(this.discountSelected || this.bonusSelected) {
				window.localStorage.setItem('discount_id', this.select);
			}
			this.result = this.resultWithDiscount();
			this.modal = false;
		},
		resultWithDiscount() {
			if(this.discountSelected) {
				const amount = this.discountList.discount.filter(item => item.id === window.localStorage.getItem('discount_id'));
				return Math.floor(Number(this.sum) * (Number(amount[0].quantity) / 100) + Number(this.sum));
			}else if(this.bonusSelected) {
				const amount = this.discountList.bonus.filter(item => item.id === window.localStorage.getItem('discount_id'));
				return Math.floor(Number(this.sum) + Number(amount[0].quantity));
			}else{
				return Math.floor(this.sum)
			}
		},
		cancel() {
			this.$router.replace('/cashbox/card-id')
		},
		editSum(amount) {
			return Number.parseInt(amount);
		},
		inputScreenKeyboard(evt) {
			if(/\d+/.test(evt.key)) {
				this.sum += evt.key
			} else if(evt.key === 'Backspace') {
				this.sum = this.sum.substring(0, this.sum.length - 1)
			} else if(evt.key === 'Enter') {
				this.submitSum()
			}
		}
	},
	watch: {
		sum() {
			this.result = this.resultWithDiscount();
			if(Number(this.sum) <= 20000) {
				if(this.sum.length <= 0){
					this.emptySumField = true;
					this.invalidSumField = false;
				}else if(Number(this.sum) < 0) {
					this.invalidSumField = true;
					this.emptySumField = false;
				}else if(this.sum.length > 0 && Number(this.sum) > 0) {
					this.invalidSumField = false;
					this.emptySumField = false;
				}
			}else{
				this.sum = '20000'
			}
		},
		result() {
			this.result = this.resultWithDiscount();
			if(Number(this.result) > 0) {
				window.localStorage.setItem('sum', this.sum)
				window.localStorage.setItem('withDiscount', this.result)
			}else{
				window.localStorage.setItem('sum', '')
				window.localStorage.setItem('withDiscount', '')
			}
		},
	},
	mounted() {
		this.getData();
		window.localStorage.setItem('discount_id', null)
		// this.sum = window.localStorage.getItem('sum')
		this.balance = window.localStorage.getItem('balance')
		if(Number(this.sum) > 0) {
			this.invalidSumField = false;
			this.emptySumField = false;
		}
		window.addEventListener('keydown', this.inputScreenKeyboard)
	},
	beforeUnmount() {
		window.removeEventListener('keydown', this.inputScreenKeyboard)
	}
}
</script>

<style scoped>
	.section_form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
    }

	.section_form p{
		font-family: "Open Sans";
		font-weight: 400;
		font-size: 18px;
		color: #ED1C24;
		margin-bottom: 10px;
		width: 100%;
		text-align: center;
	}

	.section_btns{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 55%;
	}


	button{
		cursor: pointer;
	}

    .valid{
        width: 100%;
        padding: 20px;
        border-radius: .5rem;
        border: solid 3px #bfbfbf;
        font-family: "Open Sans";
        font-size: 36px;
        font-weight: 600;
        color: #828282;
        margin-bottom: 40px;
    }

	.read_only{
		width: 100%;
        padding: 20px;
        border-radius: .5rem;
        border: solid 3px #bfbfbf;
        font-family: "Open Sans";
        font-size: 36px;
        font-weight: 600;
        color: #828282;
        margin-bottom: 40px;
	}

	.read_only.active{
		border: solid 3px #0071BC;
		color: #000;
	}

	.valid.active{
		border: solid 3px #0071BC;
		color: #000;
	}

	.invalid{
		border: solid 3px #ED1C24;
	}

	.invalid:focus{
		border: solid 3px #ED1C24;
	}

	.sum_title{
		font-family: "Open Sans";
		font-weight: 700;
		font-size: 24px;
		color: #0071BC;
		margin-bottom: 10px;
		width: 100%;
		text-align: center;
	}
	
	.sum_title.empty{
		color: #828282;
	}
	
	.sum_btns{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 60%;
		margin-bottom: 20px;
	}

	.sum_btn{
		width: 45%;
		font-family: "Open Sans";
		font-weight: 700;
		font-size: 24px;
		color: #828282;
		padding: 30px;
		border: solid 2px #bfbfbf;
		border-radius: .5rem;
		background: transparent;
	}

	.sum_btn.active{
		color: #000;
		border: solid 2px #0071BC;
	}

	.form_btn{
        font-family: "Open Sans";
        font-size: 28px;
        font-weight: 700;
        color: #fff;
        border: none;
        background: #DADCE0;
        padding: 15px 25px;
        border-radius: .5rem;
        transition: .1s linear;
		text-decoration: none;
		text-align: center;
    }

	.form_btn:first-of-type{
		margin-right: 15px;
	}

    .form_btn.active{
        background: #0071BC;
        color: #fff;
    }

	.modal_bckg{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
	}

	.modal_window{
		position: absolute;
		top: 20px;
		bottom: 20px;
		overflow: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 30px;
		width: 40%;
		background: #fff;
		border-radius: .5rem;
		box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
	}

	.column_wrp{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
	}

	.title{
		font-family: "Open Sans";
		font-size: 24px;
		font-weight: 700;
		color: #0071BC;
		margin-bottom: 15px;
	}

	.radio_container{
		display: block;
		position: relative;
		padding-left: 35px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.radio_container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		border: solid 2px #d5d5d5;
		border-radius: .2rem;
	}

	.radio_container:hover input ~ .checkmark {
		background-color: #d5d5d5;
	}

	.radio_container input:checked ~ .checkmark {
		background-color: #0071BC;
	}

	.label{
		font-family: "Open Sans";
		font-size: 18px;
		font-weight: 400;
		color: #707683;
	}

	.column_item{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: 50px;
	}

	.modal_btns{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.modal_btn{
		font-family: "Open Sans";
		font-size: 19px;
		font-weight: 700;
		color: #fff;
		padding: 15px;
		width: 48%;
		background: #AAAFB5;
		border-radius: .5rem;
		text-align: center;
		user-select: none;
		cursor: pointer;
		transition: .1s linear;
		border: none;
	}

</style>