<template>
	<loading v-if="isLoading" :expand="true"></loading>
	<error-message v-if="$store.state.messagingTokenError"></error-message>
    <div class="send_message" v-else>
        <div class="container">
            <div class="send_message_inner">
                <h3 class="title required">
					Кому отправить
					<p v-if="!validReceiver">*</p>
				</h3>
                <form action="" class="form">
                    <div class="form_row_wrp">
						<div class="form_row" v-for="(list, listKey) in checkboxes" :key="listKey">
							<div 
								class="form_row_item" 
								v-for="(checkbox, idx) in list" 
								:key="checkbox.id">
								<div
									:class="{checkbox: true, checked: checkbox.selected}"
									@click="checkbox.active ? selectReceiver(listKey, idx) : false">
									<svg v-if="checkbox.selected" width="10" height="8" viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg">
										<path d="M8.90273 0.210276L3.40098 6.28028L1.10426 3.74634C0.982947 3.6125 0.818416 3.53731 0.646859 3.53731C0.475301 3.53731 0.31077 3.6125 0.189461 3.74634C0.0681511 3.88018 0 4.06171 0 4.25098C0 4.3447 0.0167316 4.43751 0.0492393 4.52409C0.081747 4.61068 0.129394 4.68935 0.189461 4.75562L2.94034 7.79063C3.19337 8.06979 3.6021 8.06979 3.85513 7.79063L9.81104 1.21956C9.8715 1.15259 9.91934 1.07306 9.95177 0.985573C9.9842 0.898083 10.0006 0.804367 9.99998 0.709845C9.99938 0.615322 9.9818 0.52187 9.94825 0.434892C9.91471 0.347915 9.86586 0.269138 9.80455 0.203118C9.5645 -0.0688872 9.15576 -0.0688874 8.90273 0.210276Z" fill="#fff"/>
									</svg>
								</div>
								<span :class="{active: checkbox.active}">{{ checkbox.title }}</span>
							</div>
						</div>
					</div>
                    <select v-model="receiver" class="select" @change="receiverValidation" v-if="!userError">
						<option 
							value="initial" disabled>Получатель</option>
                        <option
                            :value="user.id"
                            v-for="user in userList"
                            :key="user.id"
							:disabled="!checkboxes.main[2].selected">
                            {{ user.fullName }}
                        </option>
                    </select>
					<select v-model="receiver" class="select" @change="receiverValidation" v-else>
						<option 
							value="initial" disabled>Список пользователей недоступен</option>
                    </select>
                    <div class="theme first">
                        <label for="theme" class="required">
							Тема 
							<p v-if="!theme.length > 0">*</p>
						</label>
                        <input type="text" id="theme" placeholder="Тема" v-model="theme">
                    </div>

                    <div class="theme last">
                        <label for="msg" class="required">
							Сообщение
							<p v-if="!content.length > 0">*</p>
						</label>
                        <textarea 
							wrap="on" 
							id="msg" 
							cols="30" 
							rows="5" 
							width="100%" 
							placeholder="Сообщение" 
							v-model="content"></textarea>
                    </div>
                    <button 
						type="submit" 
						:class="{submit_btn: true, active: isValid}" 
						@click.prevent="sendMessage">Отправить</button>
                </form>
            </div>
        </div>
    </div>
	<modal-window 
		v-if="modalResult"
		:data="{
			first: {},
			second: {},
			component: {},
		}"
		:resultField="true"
		:resultFieldOptions="resultOptions"
		@close="showResultModal()"></modal-window>
</template>

<script>
/* eslint-disable no-unused-vars */
import Loading from '../../Loading.vue';
import ModalWindow from '../../ModalWindow.vue';
import ErrorMessage from '../../ErrorMessage.vue';

export default {
    name: 'SendMessage',
	components: {
		Loading,
		ModalWindow,
		ErrorMessage
	},
    data() {
        return{
            checkboxes: {
				main: [
					{title: 'Отправить всем', selected: false, active: true, id: 1},
					{title: 'Группа', selected: false, active: true, id: 2},
					{title: 'Личное сообщение', selected: false, active: true, id: 3},
				],
				semi: [	
					{title: 'Касса', selected: false, active: false, id: 4},
					{title: 'Операторы', selected: false, active: false, id: 5},
					{title: 'Бухгалтера', selected: false, active: false, id: 6},
				]
			},
			userList: [],
			receiver: 'initial',
			theme: '' ,
			content: '',
			validReceiver: false,
			isLoading: false,
			modalResult: false,
			resultOptions: {},
			userError: false,
        }
    },
	methods: {
		selectReceiver(listKey='', idx='') {
			if(listKey !== '' && idx !== '') {
				this.checkboxes.main.map(item => {
				if(item.id !== this.checkboxes[listKey][idx].id && listKey === 'main') {
					item.selected = false;
				}
				if(item.id === 3) {
					this.receiver = 'initial';
				}
				})
				this.checkboxes[listKey][idx].selected = !this.checkboxes[listKey][idx].selected;
				if(this.checkboxes.main[1].selected) {
					this.checkboxes.semi.map((item) => {
						item.active = true;
					})
				}else{
					this.checkboxes.semi.map((item) => {
						item.active = false;
						item.selected = false;
					})
				}
				this.receiverValidation();
			}
		},
		async getUserList() {
			if(!this.$store.state.messagingTokenError) {
				try{
					this.userError = false;
					this.isLoading = true;
					const payload = {
					url: this.$store.state.apiUrl + 'v1/front/user-list',
					}
					const data = await this.$store.dispatch('sendData', payload);
					this.userList = data.data;
					this.isLoading = false;
				}catch(err) {
					this.isLoading = false;
					this.userError = true;
				}
			}
		},
		async sendMessage() {
			try{
				if(this.isValid) {
					this.isLoading = true;
					const payload = {
						url: this.$store.state.apiUrl + '/v1/front/add-message',
						data: {
							subject: this.theme,
							action: '5',
							description: this.content,
							userId: this.receiver === 'initial' ? '' : this.receiver
						}
					}
					await this.$store.dispatch('sendData', payload)
					this.isLoading = false;
					this.resultOptions = {
						message: 'Сообщение было отправлено',
						result: true
					}
					this.modalResult = true;
					setTimeout(() => {
						this.modalResult = false
					}, 2000)
					this.clearFields();
				}
			}catch(err) {
				this.isLoading = false;
				this.resultOptions = {
					message: 'Что то пошло не так',
					result: false
				}
				this.modalResult = true;
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
			}
		},
		clearFields() {
			this.theme = '';
			this.content = '';
			this.checkboxes.main.map((item) => {
				item.selected = false;
			})
			this.receiver = 'initial'
		},
		showResultModal() {
			this.modalResult = !this.modalResult;
		},
		receiverValidation() {
			let mainAmount = 0
			let mainWithSemiAmount = 0
			this.checkboxes.main.map((item) => {
				item.selected ? mainAmount++ : mainAmount	
			})
			if(this.checkboxes.main[1].selected) {
				this.checkboxes.semi.map((item) => {
					item.selected ? mainWithSemiAmount++ : mainWithSemiAmount
				})
				this.validReceiver = mainAmount + mainWithSemiAmount >= 2 ? true : false
				return 
			}else if(this.checkboxes.main[2].selected) {
				this.validReceiver = this.receiver !== 'initial' ? true : false
				return 
			}
			this.validReceiver = mainAmount >= 1 ? true : false
		},
		
	},
	computed: {
		isValid() {
			return this.validReceiver && this.theme.length > 0 && this.content.length > 0
		}
	},
	mounted() {
		this.getUserList()
	}
}
</script>

<style scoped>
    .send_message{
        max-width: 60%;
        padding: 20px;
        background: #fff;
    }

    .checkbox{
        width: 15px;
        height: 15px;
        border: solid 2px #D5D5D5;
        border-radius: 0.2rem;
        margin-right: 5px;
        cursor: pointer;
    }

	.checkbox.checked{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #0071BC;
		border: solid 2px #90C1E1;
	}

    .title{
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
        margin-bottom: 20px;
    }

	.required{
		display: flex;
		justify-content: flex-start;
		align-content: center;
	}

	.required p{
		color: #ff0000;
		margin-left: 5px;
	}

    .form{
        display: flex;
        flex-direction: column;
    }

	.form_row_wrp{
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}

    .form_row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
		user-select: none;
    }

    .form_row_item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 0 0 calc(100%/3);
		max-width: calc(100%/3);
    }

    .form_row_item span{
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 12px;
        color: #80868B;
    }

	.form_row_item span.active{
		color: #000;
	}

    .select{
        width: 70%;
        background: #DADCE0;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('../../../../assets/add-options/arrow.svg');
        background-repeat: no-repeat;
        background-position-x: 97%;
        background-position-y: 50%;
        border: none;
        border-radius: .5rem;
        padding: 10px 15px;
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #000;
        margin-bottom: 20px;
    }

    .theme{
        width: 70%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .theme:first-of-type{
        margin-bottom: 20px;
    }

    .theme label{
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
        margin-bottom: 10px;
    }

    .theme input{
        padding: 10px 15px;
        border-radius: .5rem;
        border: none;
        background-color: #f1f1f1;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
    }

    .theme textarea{
        resize: none;
        padding: 10px 15px;
        border-radius: .5rem;
        border: none;
        background-color: #f1f1f1;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
    }

    .submit_btn{
        width: 30%;
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 12px;
        color: #80868B;
        background-color: #DADCE0;
        padding: 10px 15px;
        border: none;
        border-radius: .5rem;
        transition: .2s linear;
    }
    .submit_btn.active{
        color: #fff;
        background-color: #0071BC;
    }
</style>