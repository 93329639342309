<template>
	<div class="message_wrp">
		<div 
			:class="{message: true, delete: message.delete}" 
			ref="message" 
			@transitionend="message.delete ? closeMessage(idx) : false"
			v-for="(message, idx) in messages" 
			:key="idx + 1">
			<div class="title_wrp">
				<h1 class="title">{{ message.notification.title }}</h1>
			</div>
			<div class="text_wrp">
				<p class="text">{{ message.notification.body }}</p>
			</div>
			<button class="close_btn" @click.prevent="startAnimation(idx)">Закрыть</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MessageNotification',
	props: {
		data: {
			type: Object,
			required: true,
		}
	},
	data() {
		return{
			messages: this.data
		}
	},
	emits: ['close'],
	methods: {
		startAnimation(idx) {
			this.messages[idx].delete = true;
		},
		closeMessage(idx) {
			this.$emit('close', idx);
		}
	},
}
</script>

<style scoped>
	.message_wrp{
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3000;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.message{
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #fff;
		max-width: 350px;
		box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
	}

	.message.delete{
		transition: .2s ease-out;
		transform: translateX(100%);
		opacity: 0;
	}

	.title_wrp{
		padding: 10px 15px;
		background-color: #fff;
		border-bottom: solid 3px #0071BC;
	}

	.title{
		font-family: "Open Sans";
		font-weight: 700;
		font-size: 24px;
		color: #363740;
		width: 100%;
		text-align: center;
	}

	.text_wrp{
		padding: 10px 15px;
		background-color: #fff;
	}

	.text{
		font-family: "Open Sans";
		font-weight: 500;
		font-size: 16px;
		color: #363740;
	}

	.close_btn{
		font-family: "Open Sans";
		font-weight: 700;
		font-size: 16px;
		color: #fff;
		padding: 10px 15px;
		background-color: #0071BC;
		border: none;
		cursor: pointer;
	}
</style>