<template>
	<loading v-if="isLoading"></loading>
    <section class="login">
        <div class="left_img img_wrp">
            <img src="@/assets/login/img-1.svg" alt="Corner">
        </div>
        <div class="right_img img_wrp">
            <img src="@/assets/login/img-2.svg" alt="Corner">
        </div>
        <div class="container">
            <div class="login_inner">
                <h1 class="login_title">Войти в систему</h1>
                <form class="login_form" @submit.prevent="signIn">
					<p v-if="result === 'not_found'">Неверный логин или пароль</p>
					<p v-if="result === 'error'">Что то пошло не так</p>
                    <input 
						:class="
							{
								auth_field: true, 
								valid: !emptyLoginField, 
								invalid: result === 'not_found' || result === 'error'
							}" 
						@input="change" 
						type="text" 
						placeholder="Логин" 
						autocomplete="on" 
						v-model.trim="login">
                    <input 
						:class="
							{
								auth_field: true, 
								valid: !emptyPassField, 
								invalid: result === 'not_found' || result === 'error'
							}" 
						@input="change" 
						type="password" 
						placeholder="Пароль" 
						autocomplete="on" 
						v-model.trim="password">
                    <button 
						type="submit" 
						:class="{sign_in_btn: true, active: !emptyLoginField && !emptyPassField}" 
						@submit.prevent="signIn">Войти</button>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import Loading from './admin/Loading.vue';
import axios from 'axios'

export default {
    name: 'Login',
	components: {
		Loading
	},
    data() {
        return{
			login: '',
			password: '',
			result: '',
			emptyPassField: true,
			emptyLoginField: true,
			isLoading: false,
        }
    },
	methods: {
		async signIn() {
			try{
				if(!this.emptyPassField && !this.emptyLoginField) {
					this.isLoading = true;
					const payload = {
						url: this.$store.state.apiUrl + '/v1/front-auth/sign-in/',
						data: {
							login: btoa(this.login),
							password: btoa(this.password),
						}
					}
					const response = await axios.post(payload.url, payload.data);
					const token = response.data.user.access_token;
					const name = response.data.user.fullname
					const role = response.data.user.role
					window.localStorage.setItem('access_token', token);
					window.localStorage.setItem('role', role)
					window.localStorage.setItem('name', name);
					this.invalid = false;
					this.isLoading = false;
					if(window.localStorage.role === '1') {
						this.$router.replace('/admin');
					}else if(window.localStorage.role === '2') {
						this.$router.replace('/cashbox');
					}
				}
			}catch(err) {
				if(err.response.data.message === 'Incorrect username or password' || err.response.data.message === 'User not found') {
					this.result = 'not_found'
				} else {
					this.result = 'error'
				}
				this.isLoading = false;	
			}
		},
		change() {
			this.invalid = false;
			if(this.login.length === 0) {
				this.emptyLoginField = true;
			}else{
				this.emptyLoginField = false;
			}
			if(this.password.length === 0) {
				this.emptyPassField = true;
			}else{
				this.emptyPassField = false;
			}
		}
	},
	mounted() {
		window.localStorage.clear()
	}
}
</script>

<style scoped>

    .login{
        position: relative;
        width: 100%;
        height: 100vh;
    }

    .img_wrp{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 30px;
        height: 25%;
        width: 25%;
    }

    .img_wrp img{
        max-width: 100%;
        max-height: 100%;
    }

    .left_img{
        left: 5px;
        align-items: flex-start;
    }

    .right_img{
        right: 5px;
        align-items: flex-end;
    }

    .login_inner{
        position: relative;
        z-index: 99;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
    }

    .login_title{
        font-family: "Open Sans";
        font-size: 36px;
        font-weight: 700;
        color: #0071BC;
        margin-bottom: 25px;
    }

    .login_form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40%;
    }

	.login_form p{
		font-family: "Open Sans";
		font-size: 19px;
		font-weight: 400;
		color: #FF0000;
		margin-bottom: 10px;
	}

    .auth_field {
        width: 100%;
        padding: 20px;
        border-radius: .5rem;
        border: solid 3px #bfbfbf;
        font-family: "Open Sans";
        font-size: 24px;
        font-weight: 600;
        color: #828282;
		margin-bottom: 40px;
    }
	
	.auth_field:focus{
		border: solid 3px #0071BC;
		color: #000;
	}

	.valid{
		border: solid 3px #0071BC;
		color: #000;
	}

	.invalid{
		border: solid 3px #FF0000;
		color: #000;
	}
	
    .auth_field:first-of-type{
        margin-bottom: 20px;
    }

    .sign_in_btn{
        width: 50%;
        font-family: "Open Sans";
        font-size: 24px;
        font-weight: 700;
        color: #80868B;
        border: none;
        background: #DADCE0;
        padding: 15px;
        border-radius: .5rem;
        transition: .2s linear;
		cursor: pointer;
    }

	.sign_in_btn.active{
        background: #0071BC;
        color: #fff;
    }

    .backg{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .backg img{
        max-width: 100%;
        max-height: 100%;
    }

    .corner{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        width: 25%;
        height: 35%;
    }

    .corner img{
        max-width: 100%;
        max-height: 100%;
    }

    .left_corner{
        left: 0;
    }

    .right_corner{
        right: 0;
    }
</style>