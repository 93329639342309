<template>
	<loading :expand="true" v-if="isLoading"></loading>
	<error-message v-if="error"></error-message>
	<modal-window 
		v-if="modalFilter && type === 2"
		:data="filterData"
		:title="'Фильтр'"
		:dateField="true"
		:buttonsText="['Отменить', 'Применить', true]"
		@send="(data) => getSessionList(data)"
		@close="filterModalWindow()"></modal-window>
	<modal-window 
		v-if="modalFilter && type === 3"
		:data="filterData"
		:title="'Фильтр'"
		:numberField="true"
			:numberFieldType="'ID карты'"
			:numberFieldOptions="{
				require: false
			}"
		:mixField="'checkbox-checkbox'"
		:checkboxFieldLabels="['Скидка', 'Бонус']"
		:checkboxFieldOptions="{label: 'Операция', validate: ''}"
		:buttonsText="['Отменить', 'Применить', true]"
		@send="(data) => getPayments(data)"
		@close="filterModalWindow()"></modal-window>
	<div class="path" v-if="!error">
		<div 
			v-for="p in path" :key="p.type">
			<span v-if="p.type > 1">»</span>
			<span class="path_link" @click.prevent="linkFollow(p.type)">{{ p.label }}</span>
		</div>
	</div>
	<div class="filter_wrp" v-if="type > 1 && !error">
		<button class="filter" @keypress.prevent="" @click.prevent="filterModalWindow()">
			<span>Фильтр</span>
			<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M17.9963 1.5H1.49969V3.8775L7.05604 9.4425L7.49845 9.8775V16.5H10.4978V15H11.9975V16.5C11.9975 16.8978 11.8395 17.2794 11.5583 17.5607C11.277 17.842 10.8956 18 10.4978 18H7.49845C7.10071 18 6.71925 17.842 6.43801 17.5607C6.15676 17.2794 5.99876 16.8978 5.99876 16.5V10.5L0.442409 4.9425C0.302062 4.80296 0.190714 4.63701 0.114784 4.45423C0.0388545 4.27144 -0.000156336 4.07543 4.7085e-07 3.8775V1.5C4.7085e-07 1.10218 0.158003 0.720644 0.439249 0.43934C0.720496 0.158035 1.10195 0 1.49969 0H17.9963V1.5Z" fill="#0071BC"/>
				<path d="M20.7782 5.4675L18.5287 3.2175C18.459 3.1472 18.376 3.09141 18.2846 3.05333C18.1933 3.01526 18.0953 2.99565 17.9963 2.99565C17.8973 2.99565 17.7993 3.01526 17.7079 3.05333C17.6165 3.09141 17.5336 3.1472 17.4639 3.2175L10.4978 10.1925V13.5H13.8046L20.7782 6.5325C20.8485 6.46278 20.9043 6.37983 20.9423 6.28843C20.9804 6.19704 21 6.09901 21 6C21 5.90099 20.9804 5.80296 20.9423 5.71157C20.9043 5.62017 20.8485 5.53722 20.7782 5.4675ZM13.1898 12H11.9975V10.8075L15.7467 7.0575L16.939 8.25L13.1898 12ZM17.9963 7.1925L16.804 6L17.9963 4.8075L19.1885 6L17.9963 7.1925Z" fill="#0071BC"/>
			</svg>
		</button>
	</div>
	<chart 
		v-if="!error"
		:data="collectedData" 
		:titles="titles" 
		:requiredValues="requiredValues"
		:type="type"
		:pages="pagesAmountCount"
		:currentPage="currentPage"
		:initialPage="initialPage"
		@next="next()"
		@prev="prev()"
		@toStart="toStart()"
		@toEnd="toEnd()"
		@changePage="(page) => changePage(page)"
		@action="(type, id, session) => changeType(type, id, session)"></chart>
</template>

<script>
/* eslint-disable no-unused-vars */
import ModalWindow from '@/components/admin/ModalWindow.vue';
import Loading from '../../Loading.vue';
import ErrorMessage from '../../ErrorMessage.vue';
import Chart from './Chart.vue';

export default {
	name: 'Cashiers',
	components: {
		ModalWindow,
		Chart,
		Loading,
		ErrorMessage,
	},
	data () {
		return {
			type: 1,
			collectedData: {},
			titles: ['Кассир', 'Статус', 'Действие'], 
			requiredValues: [],
			path: [
				{label: 'Кассиры', type: 1}
			],
			filterData: {
				first: {},
				second: {},
				component: 'cashiers'
			},
			cashierId: 0,
			sessionId: 0,
			pagesAmountCount: 0,
			currentPage: 1,
			initialPage: 0,
			modalFilter: false,
			isLoading: false,	
			error: false
		}
	},
	methods: {
		changeType (type, id, session) {
			this.type = type
			if(id) {
				this.cashierId = id
			} else if(session) {
				this.sessionId = session
			}
		},
		linkFollow (to) {
			this.type = to
		},
		filterModalWindow () {
			this.modalFilter = !this.modalFilter
		},
		async getUserList () {
			try{
				this.isLoading = true
				this.error = false
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/user-list',
				}
				this.collectedData = []
				this.titles = ['Кассир', 'Статус', 'Дата создания', 'Действие']
				this.path = [{label: 'Кассиры', type: 1}]
				const response = await this.$store.dispatch('getData', payload)
				const users = response.data.filter((item) => {
					return item.profile === '2'
				})
				this.collectedData = users
				this.requiredValues = ['fullName', 'active', 'created_at', 'action']
				this.isLoading = false
			}catch(err) {
				this.isLoading = false
				this.error = true
			}
		},
		async getSessionList (filter={}) {
			try{
				this.isLoading = true
				this.error = false
				if(filter.dateFrom) {
					this.currentPage = 1
				}
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/session-list',
					data: {
						cashierId: this.cashierId,
						page: this.currentPage - 1,
						dateFrom: filter.dateFrom,
						dateTo: filter.dateTo,
						limit: 50
					}
				}
				this.collectedData = []
				this.titles = ['Кассир', 'Начало', 'Конец', 'Действие']
				this.requiredValues = ['fullname', 'start', 'end', 'action']
				this.path = [
					{label: 'Кассиры', type: 1},
					{label: 'Сессии', type: 2}
				]
				const response = await this.$store.dispatch('sendData', payload)
				this.pagesAmountCount = response.data.pagesCount
				this.collectedData = response.data.data
				this.isLoading = false
				this.modalFilter = false
			}catch(err) {
				this.isLoading = false
				this.error = true
			}
		},
		async getPayments(filter={}) {
			try{
				this.isLoading = true
				this.error = false
				if(filter.dateFrom) {
					this.currentPage = 1
				}
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/operation-list',
					data: {
						sessionId: this.sessionId,
						page: this.currentPage - 1,
						limit: 50,
						code: filter.card_code,
						paymentMethod: filter.paymentMethod
					}
				}
				this.collectedData = []
				this.titles = ['Сумма', 'Дата', 'Описание', 'Операция']
				this.requiredValues = ['amount', 'date', 'description', 'type']
				this.path = [
					{label: 'Кассиры', type: 1},
					{label: 'Сессии', type: 2},
					{label: 'Платежи', type: 3},
				]
				const response = await this.$store.dispatch('sendData', payload)
				this.pagesAmountCount = response.data.pagesCount
				this.collectedData = response.data.data
				this.isLoading = false
				this.modalFilter = false
			}catch(err) {
				this.isLoading = false
				this.error = true
			}
		},
		prev() {
			this.currentPage--
		},
		next() {
			this.currentPage++
		},
		toStart() {
			this.currentPage = 1
		},
		toEnd() {
			this.currentPage = this.pagesAmountCount
		},
	},
	watch: {
		type () {
			switch(this.type) {
				case 1:
					this.getUserList()
					break
				case 2:
					this.currentPage = 1
					this.getSessionList()
					break
				case 3:
					this.currentPage = 1
					this.getPayments()
					break
			}
		},
		currentPage() {
			switch(this.type) {
				case 1:
					this.getUserList()
					break
				case 2:
					this.getSessionList()
					break
				case 3:
					this.getPayments()
					break
			}
		}
	},
	mounted () {
		this.getUserList()
	}
}
</script>

<style scoped>
	.path{
		display: flex;
		align-items: center;
		margin-bottom: 30px;
	}

	.path div{
		display: flex;
		align-items: center;
	}

	.path span{
		font-size: 13px;
		margin-right: 5px;
	}

	.path_link{
		cursor: pointer;
	}

	.filter_wrp{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 25px;
    }

    .filter{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 10px;
        cursor: pointer;
        user-select: none;
    }

    .filter span{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 14px;
        color: #0071BC;
        margin-right: 10px;
        user-select: none;
    }
</style>