<template>
    <div class="reports">
        <div class="container">
            <div class="reports_inner">
                <header class="header">
                    <h1 class="title">Отчеты</h1>
                </header>
                <nav class="nav">
                    <router-link 
                        to="/admin/reports/replenishment" 
                        class="nav_link" >
                        Пополнение</router-link>
                    <router-link 
                        to="/admin/reports/write-off" 
                        class="nav_link" >
                        Списание</router-link>
					<router-link 
                        to="/admin/reports/cashiers" 
                        class="nav_link" >
                        Кассиры</router-link>
					<router-link 
                        to="/admin/reports/card-history" 
                        class="nav_link" >
                        История карты</router-link>
					<router-link 
                        to="/admin/reports/attractions" 
                        class="nav_link" >
                        Аттракционы</router-link>
                </nav>
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Reports',
}
</script>

<style scoped>
     .reports{
        position: relative;
        width: 100%;
        height: 100vh;
    }

    .reports_inner{
        position: absolute;
        top: 20px;
        bottom: 20px;
        right: 20px;
        left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .header{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: 30px;
    }

    .title{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 36px;
        color: #0071BC;
        margin-right: 20px;
    }

    .nav{
        width: calc(100% + 40px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: -20px;
        margin-bottom: 30px;
        background-color: #fff;
    }

    .nav_link{
        position: relative;
        padding: 20px 35px;
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 600;
        color: #363740;
        text-decoration: none;
        transition: .2s linear;
        user-select: none;
        cursor: pointer;
		white-space: nowrap;
    }

    .nav_link:not(:last-of-type) {
        border-right: solid 2px #BDC1C6;
    }

    .nav_link::after{
        position: absolute;
        content: '';
        bottom: 0;
        right: 0;
        left: 0;
        height: 3px;
        background-color: #0071BC;
        transition: .2s linear;
        transform: scale(0);
    }

    .nav_link:hover{
        background-color: rgba(62,64,73,.1);
    }
    
    .nav_link:hover::after{
        transform: scale(1);
    }

    .nav_link.active{
        background-color: rgba(62,64,73,.1);
    }

    .nav_link.active::after{
        transform: scale(1);
    }

    .submit_btn{
        width: 20%;
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 12px;
        color: #80868B;
        background-color: #DADCE0;
        padding: 10px 15px;
        border: none;
        border-radius: .5rem;
        transition: .2s linear;
    }

    .submit_btn:hover{
        color: #fff;
        background-color: #0071BC;
    }

</style>