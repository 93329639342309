<template>
     <div class="price_list">
		<loading v-if="isLoading"></loading>
        <div class="container">
            <div class="price_list_inner">
                <header class="header">
                    <h1 class="title">Прайс лист</h1>
                    <div class="btns_wrp" v-if="!error">
                        <button class="import header_btn" @keypress.prevent="false" @click.prevent="importModalWindow()">
							<span>Импорт</span>
                            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 10.9286H7.22L8.94 9.26358L8 8.35715L4.66667 11.5714L8 14.7857L8.94 13.8793L7.22 12.2143H16V10.9286Z" fill="#0071BC"/>
                                <path d="M13.3333 7.71429V5.14287C13.3338 5.05826 13.317 4.97439 13.2838 4.89607C13.2507 4.81774 13.2018 4.74651 13.14 4.68644L8.47333 0.186439C8.41104 0.126858 8.33716 0.0797207 8.25594 0.0477292C8.17471 0.0157377 8.08774 -0.0004784 8 1.07449e-05H1.33333C0.979711 1.07449e-05 0.640573 0.135469 0.390524 0.376587C0.140476 0.617706 0 0.944732 0 1.28572V16.7143C0 17.0553 0.140476 17.3823 0.390524 17.6234C0.640573 17.8645 0.979711 18 1.33333 18H12C12.3536 18 12.6928 17.8645 12.9428 17.6234C13.1929 17.3823 13.3333 17.0553 13.3333 16.7143V15.4286H12V16.7143H1.33333V1.28572H6.66667V5.14287C6.66667 5.48386 6.80714 5.81088 7.05719 6.052C7.30724 6.29312 7.64638 6.42858 8 6.42858H12V7.71429H13.3333ZM8 5.14287V1.5493L11.7267 5.14287H8Z" fill="#0071BC"/>
                            </svg>
                        </button>
                        <a :href="$store.state.downloadXlsLink" class="export header_btn" @keypress.prevent="false">
                            <span>Экспорт</span>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.07143 12.2143H15.5379L13.8793 13.8793L14.7857 14.7857L18 11.5714L14.7857 8.35715L13.8793 9.26358L15.5379 10.9286H7.07143V12.2143Z" fill="#0071BC"/>
                                <path d="M12.8571 7.71429V5.14287C12.8576 5.05826 12.8414 4.97439 12.8094 4.89607C12.7774 4.81774 12.7303 4.74651 12.6707 4.68644L8.17071 0.186439C8.11065 0.126858 8.03941 0.0797207 7.96108 0.0477292C7.88276 0.0157377 7.79889 -0.0004784 7.71429 1.07449e-05H1.28571C0.944722 1.07449e-05 0.617695 0.135469 0.376577 0.376587C0.135459 0.617706 0 0.944732 0 1.28572V16.7143C0 17.0553 0.135459 17.3823 0.376577 17.6234C0.617695 17.8645 0.944722 18 1.28571 18H11.5714C11.9124 18 12.2394 17.8645 12.4806 17.6234C12.7217 17.3823 12.8571 17.0553 12.8571 16.7143V15.4286H11.5714V16.7143H1.28571V1.28572H6.42857V5.14287C6.42857 5.48386 6.56403 5.81088 6.80515 6.052C7.04627 6.29312 7.37329 6.42858 7.71429 6.42858H11.5714V7.71429H12.8571ZM7.71429 5.14287V1.5493L11.3079 5.14287H7.71429Z" fill="#0071BC"/>
                            </svg>
                        </a>
                        <button class="add header_btn" @keypress.prevent="false" @click="addModalWindow">
                            <span>Добавить</span>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.86 9.72H8.28V13.14C8.28 13.239 8.361 13.32 8.46 13.32H9.54C9.639 13.32 9.72 13.239 9.72 13.14V9.72H13.14C13.239 9.72 13.32 9.639 13.32 9.54V8.46C13.32 8.361 13.239 8.28 13.14 8.28H9.72V4.86C9.72 4.761 9.639 4.68 9.54 4.68H8.46C8.361 4.68 8.28 4.761 8.28 4.86V8.28H4.86C4.761 8.28 4.68 8.361 4.68 8.46V9.54C4.68 9.639 4.761 9.72 4.86 9.72Z" fill="#0071BC"/>
                                <path d="M17.28 0H0.72C0.32175 0 0 0.32175 0 0.72V17.28C0 17.6783 0.32175 18 0.72 18H17.28C17.6783 18 18 17.6783 18 17.28V0.72C18 0.32175 17.6783 0 17.28 0ZM16.38 16.38H1.62V1.62H16.38V16.38Z" fill="#0071BC"/>
                            </svg>
                        </button>
                    </div>
                </header>
				<error-message v-if="error"></error-message>
                <div class="table" ref="table" v-else>
                    <div class="row_titles">
                        <h3 class="row_title_item id">
                            <span>Название аттракциона</span>
                        </h3>
                        <h3 class="row_title_item center">Стоимость услуги</h3>
                        <h3 class="row_title_item edit">Действия</h3>
                    </div>
                    <div 
                        class="row"
                        v-for="(p, idx) in priceList"
                        :key="p.id">
                        <h3 class="row_item id">

                            <span>{{ p.name }}</span>
                        </h3>
                        <h3 class="row_item center">{{ editPrice(p.sum) }}</h3>
                        <h3 class="row_item edit" @click="editModalWindow(idx, p.id)">Изменить</h3>
                    </div>
                </div>
            </div>
        </div>

		<modal-window 
			v-if="modalImport"
			:data="collectedData"
			:title="'Импорт'"
			:importField="true"
			@send="(data) => handleFileUpload(data)"
			@close="importModalWindow()"></modal-window>
		<modal-window 
			v-if="modalAdd"
			:data="collectedData"
			:title="'Добавить аттракцион'"
			:nameField="true"
			:nameFieldType="'Название'"
			:numberField="true"
			:numberFieldType="'Стоймость'"
			:numberFieldOptions="{readonly: false, validate: 'number'}"
			:buttonsText="['Отмена', 'Добавить', true]"
			@send="(data) => add(data)"
			@close="addModalWindow()"></modal-window>
		<modal-window 
			v-if="modalEdit"
			:data="collectedData"
			:title="'Изменить аттракцион'"
			:nameField="true"
			:nameFieldType="'Название'"
			:numberField="true"
			:numberFieldType="'Стоймость'"
			:numberFieldOptions="{readonly: false, validate: 'number'}"
			:buttonsText="['Удалить', 'Изменить', true]"
			@send="(data) => edit(data)"
			@delete="deleteAttraction"
			@close="editModalWindow()"></modal-window>
		<modal-window 
			v-if="modalResult"
			:data="{
				first: {},
				second: {},
				component: {},
			}"
			:resultField="true"
			:resultFieldOptions="resultOptions"
			@close="showResultModal()"></modal-window>

    </div>
</template>

<script>
import Loading from '../Loading.vue';
import ModalWindow from '../ModalWindow.vue';
import ErrorMessage from '../ErrorMessage.vue';
import axios from 'axios';

export default {
    name: 'PriceList',
	components: {
		Loading,
		ModalWindow,
		ErrorMessage
	},
    data() {
        return{
            priceList: [
            ],
            modalAdd: false,
            modalEdit: false,
			modalResult: false,
            attName: '',
            cost: 0,
            allSelected: false,
            selectedCount: 0,
			emptyName: true,
			emptyCost: true,
			editItemId: 0,
			isLoading: false,
			collectedData: {
				first: {},
				second: {},
				component: 'priceList'
			},
			resultOptions: {},
			file: '',
			modalImport: false,
			error: false,
        }
    },
    methods: {
		async getData() {
			try{
				this.error = false;
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/attraction-list'
				}
				await this.$store.dispatch('getData', payload).then((res) => {
					this.priceList = res.data
				});
				this.isLoading = false;
			}catch(err) {
				this.isLoading = false;
				this.error = true;
			}
		},
		async add(data) {
			try{
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/add-attraction',
					data: data
				}
				await this.$store.dispatch('sendData', payload);
				this.isLoading = false;
				this.modalAdd = false;
				this.getData();
			}catch(err) {
				this.modalAdd = false;
				this.isLoading = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Произошла ошибка во время добавления аттракциона',
					result: false
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
			}
		},
		async edit(data) {
			try{
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/update-attraction',
					data: data
				}
				await this.$store.dispatch('sendData', payload);
				this.isLoading = false;
				this.modalEdit = false;
				this.getData();
			}catch(err) {
				this.modalEdit = false;
				this.isLoading = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Произошла ошибка во время изменения аттракциона',
					result: false
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
			}
		},
		async deleteAttraction() {
			try{
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/delete-attraction',
					data: {
						id: this.editItemId
					}
				}
				await this.$store.dispatch('sendData', payload);
				this.isLoading = false;
				this.modalEdit = false;
				this.getData();
			}catch(err) {
				this.modalEdit = false;
				this.isLoading = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Произошла ошибка во время удаления аттракциона',
					result: false
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
			}
		},
		async downloadFile() {
			try{
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/url-excel-attraction',
					data: {}
				}
				this.$store.dispatch('downloadFile', payload);
			}catch(err) {
				throw new Error(err);
			}
		},
		async isLoadingData() {
			this.isLoading = true;
			await this.getData()
			.catch((err) => {
				throw new Error(err)
			});
			await this.downloadFile()
			.catch((err) => {
				throw new Error(err)
			})
			this.isLoading = false;
		},
		async handleFileUpload(data) {
			try{
				const fd = new FormData();
				fd.append('file', data, data.name)
				await axios.post(this.$store.state.apiUrl + 'v1/front/import-excel-attraction', fd, {
					headers: {
						authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
						'Content-Type': 'multipart/form-data'
					}
				});
				this.modalImport = false;
				this.isLoadingData();
			}catch(err) {
				this.modalImport = false;
				this.isLoading = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Произошла ошибка во время импортирования файла',
					result: false
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
			}
		},
		collectData(data) {
			this.collectedData.first = data;
		},
        addModalWindow() {
            this.modalAdd = !this.modalAdd;
			const data = {
				attName: '',
				cost: '',
			}
			this.collectData(data)
        },
        editModalWindow(idx=0, id=0, edit='false') {
            this.modalEdit = !this.modalEdit;
			this.editItemId = id;
            if(edit) {
				const data = {
					editItemId: id,
					attName: this.priceList[idx].name,
					cost: this.editPrice(this.priceList[idx].sum),
				}
				this.collectData(data);
            }
        },
		importModalWindow() {
			this.modalImport = !this.modalImport;
		},
		editPrice(price) {
			return Number.parseInt(price);
		},
		showResultModal() {
			this.modalResult = !this.modalResult;
		}
    },
	mounted() {
		this.isLoadingData();
	},
}
</script>

<style scoped>
    .price_list{
        position: relative;
        width: 100%;
        height: 100vh;
    }

    .price_list_inner{
        position: absolute;
        top: 20px;
        bottom: 20px;
        right: 20px;
        left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

	a{
		text-decoration: none;
	}

    .title{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 36px;
        color: #0071BC;
        margin-bottom: 30px;
    }

    .table{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #fff;
        overflow-y: auto;
    }

    .row_titles{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 10px;
    }

    .row_title_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 700;
        color: rgba(0,113,188,0.5);
        padding: 7px;
        width: calc(100%/4);
        padding: 5px;
    }

    .row_title_item.id{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .row_title_item.id svg{
        margin-right: 10px;
        cursor: pointer;
    }

    .row_title_item.edit{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #EEF8FF;
        padding: 10px 15px;
    }

    .row_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 400;
        color: #707683;
        padding: 5px;
        width: calc(100%/4);
    }

    .row_item.id{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 600;
        color: #323C47;
        padding: 7px;
    }

    .row_item.edit{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    .row_item.id{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
	

	.center{
		display: flex;
		justify-content: center;
	}

    .checkbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        border: solid 2px #D5D5D5;
        border-radius: 0.2rem;
        margin-right: 10px;
        cursor: pointer;
    }

    .checkbox.selected{
        border: solid 2px #90C1E1;
        background: #0071BC;
    }

    .checkbox.all_selected{
        border: solid 2px #90C1E1;
        background: #0071BC;
    }

    .checkbox.all_selected svg{
        margin: 0;
    }

    .btns_wrp{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 30px;
    }

    .header_btn{
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        padding: 10px;
        background-color: transparent;
        border: none;
    }

    .header_btn:not(:last-of-type) {
        margin-right: 10px;
    }
    
    .header_btn span{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 700;
        color: #0071BC;
        margin-right: 10px;
    }

    .modal_back{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal_window{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        background-color: #fff;
        min-width: 25%;
        border-radius: .5rem;
    }

    .modal_title{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 18px;
        color: #000;
        text-align: center;
        margin-bottom: 30px;
    }

    .modal_form_row.first{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 15px;
    }
	
	.modal_form_row p {
		font-family: "Open Sans";
        font-weight: 400;
        font-size: 12px;
        color: #FF0000;
        margin-bottom: 5px;
        user-select: none;
	}

    .modal_form_row.first input{
        padding: 10px 25px;
        border: none;
        background-color: #DADCE0;
        border-radius: .5rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
    }

    .label{
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
        margin-bottom: 10px;
        user-select: none;
    }

    .modal_form_wrp{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 48%;
    }

    .icon{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px 15px;
    }

    .modal_form_row.second{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 30px;
    }

    .select{
        background: #DADCE0;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('~@/assets/add-options/arrow.svg');
        background-repeat: no-repeat;
        background-position-x: 93%;
        background-position-y: 50%;
        border: none;
        border-radius: .5rem;
        padding: 10px 40px 10px 25px;
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #000;
    }

    .modal_form_row.second input{
        width: 100%;
        padding: 10px 25px;
        border: none;
        background-color: #DADCE0;
        border-radius: .5rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
    }

    #cost{
        width: 100%;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 600;
        color: #000;
        padding: 10px 40px 10px 25px;
        border-radius: .5rem;
        border: none;
        background: #DADCE0;
    }

    .modal_form_row.fourth{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal_btn{
        width: 48%;
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 12px;
        color: #80868B;
        background-color: #DADCE0;
        padding: 15px 0;
        border: none;
        border-radius: .5rem;
        transition: .2s linear;
		cursor: pointer;
    }

    .modal_btn.active{
        color: #fff;
        background-color: #0071BC;
    }
</style>