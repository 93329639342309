<template>
	<teleport to="body">
		<div :class="{print_bckg: true, active: $store.state.keyboardVisible}" id="print_bckg">
			<div class="print_window">
				<div id="print_content" ref="print_content">
					<div class="print_title">
						<h1>Қиял әлемі саябагы</h1>
						<h1>Улица {{ $store.state.address }}</h1>
					</div>
					<div class="print_body">
						<h1>Номер операции {{ data.id }}</h1>
						<h1>{{ data.date }}</h1>
						<h1>Кассир: {{ data.cashier }}</h1>
					</div>
					<h1 class="title">Продажа</h1>
					<div class="print_body">
						<h1>Карта {{ data.code }} &#9; Пакет стандартный</h1>
						<span>
							<h2>{{data.sum }} х 1.0</h2>
							<h2>{{data.sum }}</h2>
						</span>
					</div>
					<div class="print_body">
						<h1>Использованные Скидки</h1>
						<span>
							<h2>{{data.discountAmount }} х 1.0</h2>
							<h2>{{data.discountAmount }}</h2>
						</span>
					</div>
					<hr>
					<label>
						<h2>Всего</h2>
						<h2>{{ Number(data.discountAmount) +  Number(data.sum)}}</h2>
					</label>
					<label>
						<h2>{{ data.paymentMethod }}</h2>
						<h2>{{data.sum }}</h2>
					</label>
					<label>
						<h2>Сдача</h2>
						<h2>0</h2>
					</label>
				</div>
				<div class="btn_wrp">
					<button @click="close">Отмена</button>
					<button @click.prevent="printContent">Печать</button>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
export default {
	name: 'PrintComponent',
	emits: ['close'],
	methods: {
		printContent() {
			window.print();
		},
		close() {
			this.$emit('close');
		}
	},
	props: {
		data: {
			required: true,
			type: Object,
		}
	}
}
</script>

<style scoped>
	.print_bckg{
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1100;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.print_bckg.active{
		left: -12.5%;
	}

	.print_window{
		padding: 30px;
		background: #fff;
		border-radius: .5rem;
		width: 35%;
		box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
	}

	#print_content{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;		
	}

	h1{
		font-weight: 300;
		color: #000;
		width: 100%;
		font-size: 14px;
	}
	div{
		width: 100%;
	}

	.print_title{
		margin-bottom: 20px;
	}

	.print_title h1{
		text-align: center;
	}

	.print_body{
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}

	.title{
		margin-bottom: 20px;
	}

	span{
		display: flex;
		justify-content: space-between;
		width: 80%;
		margin-left: auto;
	}

	label{
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	label:first-of-type{
		margin-bottom: 20px;
	}

	label:last-of-type{
		margin-bottom: 20px;
	}

	label h2:last-of-type{
		text-align: end;
	}

	h2{
		font-weight: 300;
		color: #000;
		width: 100%;
		font-size: 14px;
	}

	span h2:last-of-type{
		text-align: end;
	}

	hr{
		width: 100%;
		margin-bottom: 20px;
	}

	.btn_wrp{
		width: 100%;
		display: flex;
		justify-content: center;
	}

	button{
		padding: 15px 20px;
		border: none;
		border-radius: .5rem;
		font-size: 17px;
		background: #AAAFB5;
		color: #fff;
	}

	button:first-of-type{
		margin-right: 20px;
	}

	@media print{
		.print_bckg.active{
			left: 0;
		}

		.print_window{
			width: 100%;
			height: 100vh;
		}

		button{
			display: none;
		}
	}

</style>