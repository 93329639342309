<template>
	<loading :expand="true" v-if="isLoading"></loading>
    <div class="btns_wrp" v-if="!error">
        <button @keypress.prevent class="add header_btn" @click="addModalWindow">
            <span>Добавить</span>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.86 9.72H8.28V13.14C8.28 13.239 8.361 13.32 8.46 13.32H9.54C9.639 13.32 9.72 13.239 9.72 13.14V9.72H13.14C13.239 9.72 13.32 9.639 13.32 9.54V8.46C13.32 8.361 13.239 8.28 13.14 8.28H9.72V4.86C9.72 4.761 9.639 4.68 9.54 4.68H8.46C8.361 4.68 8.28 4.761 8.28 4.86V8.28H4.86C4.761 8.28 4.68 8.361 4.68 8.46V9.54C4.68 9.639 4.761 9.72 4.86 9.72Z" fill="#0071BC"/>
                <path d="M17.28 0H0.72C0.32175 0 0 0.32175 0 0.72V17.28C0 17.6783 0.32175 18 0.72 18H17.28C17.6783 18 18 17.6783 18 17.28V0.72C18 0.32175 17.6783 0 17.28 0ZM16.38 16.38H1.62V1.62H16.38V16.38Z" fill="#0071BC"/>
            </svg>
        </button>
    </div>
	<error-message v-if="error"></error-message>
    <div class="table" v-else>
        <div class="row_titles">
            <h3 class="row_title_item id">
                <span>ID Пользователя</span>
            </h3>
            <h3 class="row_title_item">ФИО</h3>
            <h3 class="row_title_item">Логин</h3>
            <h3 class="row_title_item">Профиль</h3>
            <h3 class="row_title_item active">Активный</h3>
            <h3 class="row_title_item action">Действия</h3>
        </div>
        <div 
            class="row"
            v-for="(user, idx) in users"
            :key="user.id">
            <h3 class="row_item id">
                <span>{{ user.id }}</span>
            </h3>
            <h3 class="row_item">{{ user.fullName }}</h3>
            <h3 class="row_item">{{ user.login }}</h3>
            <h3 class="row_item">{{ user.profile === '1' ? 'Админ' : 'Кассир' }}</h3>
            <h3 class="row_item active">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="user.active">
                    <path d="M11 0C4.93281 0 0 4.93281 0 11C0 17.0672 4.93281 22 11 22C17.0672 22 22 17.0672 22 11C22 4.93281 17.0672 0 11 0ZM16.5773 8.6668L10.6176 14.6266C10.4371 14.807 10.2051 14.8973 9.96875 14.8973C9.73242 14.8973 9.50039 14.807 9.31992 14.6266L6.34219 11.6488C5.98555 11.2922 5.98555 10.7121 6.34219 10.3512C6.69883 9.99453 7.27891 9.99453 7.63984 10.3512L9.97305 12.6844L15.284 7.37344C15.6406 7.0168 16.2207 7.0168 16.5816 7.37344C16.934 7.73008 16.934 8.31016 16.5773 8.6668Z" fill="#00A651"/>
                </svg>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!user.active">
                    <path d="M11 0C4.93281 0 0 4.93711 0 11C0 17.0629 4.93281 22 11 22C17.0672 22 22 17.0672 22 11C22 4.93281 17.0672 0 11 0ZM15.052 13.7543C15.4086 14.1109 15.4086 14.691 15.052 15.052C14.8715 15.2324 14.6395 15.3227 14.4031 15.3227C14.1668 15.3227 13.9348 15.2324 13.7543 15.052L11 12.2977L8.2457 15.052C8.06523 15.2324 7.8332 15.3227 7.59688 15.3227C7.36055 15.3227 7.12852 15.2324 6.94805 15.052C6.59141 14.6953 6.59141 14.1152 6.94805 13.7543L9.70234 11L6.94805 8.2457C6.59141 7.88906 6.59141 7.30898 6.94805 6.94805C7.30469 6.59141 7.88477 6.59141 8.2457 6.94805L11 9.70234L13.7543 6.94805C14.1109 6.59141 14.691 6.59141 15.052 6.94805C15.4086 7.30469 15.4086 7.88477 15.052 8.2457L12.2977 11L15.052 13.7543Z" fill="#ED1C24"/>
                </svg>
            </h3>
            <h3 class="row_item action" @click="editModalWindow(idx, user.id, true)">{{ user.action }}</h3>
        </div>
    </div>
	<modal-window
		v-if="modalAdd"
		:data="collectedData"
		title="Добавить пользователя"
		:loginField="true"
		:nameField="true"
		:passwordField="true"
		:mixField="'select-checkbox'"
		:checkboxFieldLabels="['Активный']"
		:buttonsText="['Отмена', 'Добавить', true]"
		@close="addModalWindow"
		@send="(data) => addUser(data)"></modal-window>
	<modal-window
		v-if="modalEdit"
		:data="collectedData"
		title="Изменить пользователя"
		:loginField="true"
		:nameField="true"
		:passwordField="true"
		:mixField="'select-checkbox'"
		:checkboxFieldLabels="['Активный']"
		:buttonsText="['Удалить', 'Изменить', true]"
		@close="editModalWindow"
		@send="(data) => editUser(data)"
		@delete="deleteUser"></modal-window>
	<modal-window 
		v-if="modalResult"
		:data="{
			first: {},
			second: {},
			component: {},
		}"
		:resultField="true"
		:resultFieldOptions="resultOptions"
		@close="showResultModal()"></modal-window>
</template>

<script>
import Loading from '../../Loading.vue';
import ModalWindow from '../../ModalWindow.vue';
import ErrorMessage from '../../ErrorMessage.vue'

export default {
    name: 'Table',
	components: {
		Loading,
		ModalWindow,
		ErrorMessage
	},
    data() {
        return{
            users: [],
            modalEdit: false,
            modalAdd: false,
			modalResult: false,
			isLoading: false,
			collectedData: {
				first: {},
				second: {},
				component: 'users'
			},
			resultOptions: {},
			error: false,
        }
    },
    methods: {
		async addUser(data) {
			try{
				const payload = {
					url: this.$store.state.apiUrl + '/v1/front/add-user',
					data: data
				}
				await this.$store.dispatch('sendData', payload);
				this.getUserData();
				this.modalAdd = false;
			}catch(err) {
				this.modalAdd = false;
				this.isLoading = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Произошла ошибка во время добавления пользователя',
					result: false
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
			}

		},
		async editUser(data) {
			try{
				const payload = {
					url: this.$store.state.apiUrl + '/v1/front/update-user',
					data: data
				}
				await this.$store.dispatch('sendData', payload);
				this.getUserData();
				this.modalEdit = false;
			}catch(err) {
				this.modalEdit = false;
				this.isLoading = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Произошла ошибка во время изменения пользователя',
					result: false
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
			}

		},
		async getUserData() {
			this.isLoading = true;
			try{
				this.error = false;
				const payload = {
					url: this.$store.state.apiUrl + '/v1/front/user-list',
				}
				await this.$store.dispatch('getData', payload).then((res) => {
					this.users = res.data;
				})
				this.isLoading = false;
			}catch(err) {
				this.isLoading = false;
				this.error = true;
			}
        },
		async deleteUser() {
			try{
				const payload = {
					url: this.$store.state.apiUrl + '/v1/front/delete-user',
					data: {
						id: this.editItemId
					}
				}
				await this.$store.dispatch('sendData', payload);
				this.modalEdit = false;
				this.getUserData();
			}catch(err) {
				this.modalEdit = false;
				this.isLoading = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Произошла ошибка во время удаления пользователя',
					result: false
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
			}
		}, 
		collectData(data) {
			this.collectedData.first = data;
			this.collectedData.second = {};
		},
        addModalWindow() {
            this.modalAdd = !this.modalAdd;
			const data = {
				name: '',
				profile: 1,
				password: '',
				is_active: true,
				login: '',
			}
			this.collectData(data);
        },
        editModalWindow(idx=0, id=1, edit=false) {
            this.modalEdit = !this.modalEdit;
			if(edit) {
				this.editItemId = id;
				const data = {
					name: this.users[idx].fullName,
					profile: this.users[idx].profile,
					password: '',
					is_active: this.users[idx].active,
					login: this.users[idx].login,
					editItemId: id,
					edit: true
				}
				this.collectData(data);
			}
        },
		showResultModal() {
			this.modalResult = !this.modalResult;
		}
    },
    mounted() {
        this.getUserData();
    }
}

</script>

<style scoped>
    .btns_wrp{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 30px;
    }

    .header_btn{
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        padding: 10px;
        background-color: transparent;
        border: none;
    }

    .header_btn:not(:last-of-type) {
        margin-right: 10px;
    }
    
    .header_btn span{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 700;
        color: #0071BC;
        margin-right: 10px;
    }

    .table{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #fff;
        padding: 10px 15px;
        overflow-y: auto;
    }

    .row_titles{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
    }

    .row_title_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 700;
        color: rgba(0,113,188,0.5);
        padding: 7px;
        width: calc(100%/6);
        padding: 5px;
    } 

    .row_title_item.active{
        display: flex;
        justify-content: center;
    }

    .row_title_item.id{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .row_title_item.id svg{
        margin-right: 10px;
        cursor: pointer;
    }

    .row_title_item.action{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .row:not(:last-of-type) {
        margin-bottom: 15px;
    }

    .row_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 400;
        color: #707683;
        padding: 5px;
        width: calc(100%/6);
    }

    .row_item.active{
        display: flex;
        justify-content: center;
    }

    .row_item.id{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 600;
        color: #323C47;
        padding: 7px;
    }

    .row_item.action{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    .row_item.id{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
</style>