<template>
	<div class="keyboard" >
		<div class="keyboard_inner">
			<div class="keyboard_btns">
				<button class="keyboard_btn close_btn" @click.prevent="closeKeyboard()">
					<span>Закрыть</span>
					<svg width="14" height="8" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg">
						<path d="M0.366425 0.355353C0.131835 0.582332 -3.10767e-07 0.890488 -2.9672e-07 1.21185C-2.82673e-07 1.5332 0.131835 1.84136 0.366425 2.06834L6.11677 7.64465C6.60536 8.11845 7.39462 8.11845 7.88321 7.64465L13.6336 2.06834C14.1221 1.59453 14.1221 0.829157 13.6336 0.355353C13.145 -0.118452 12.3557 -0.118452 11.8671 0.355353L6.99373 5.0691L2.13287 0.355353C1.64428 -0.118451 0.842488 -0.106302 0.366425 0.355353Z"/>
					</svg>
				</button>
			</div>
			<!-- <div class="keyboard_numbers_row" >
				<div
					class="key_item"
					v-for="n in numbers"
					:key="n.number"
					@click.prevent="pressKey(shiftPressed ? n.withShift : n.number)">
					<span :class="{key_symbol: true, with_shift: true, transparent: shiftPressed}">{{ n.withShift }}</span>
					<span :class="{key_symbol: true, number: true, transparent: !shiftPressed}">{{ n.number }}</span>
				</div>
			</div> -->
			<div class="keyboard_keys">
				<div 
					class="key_item"
					v-for="n in numbers"
					:key="n.number"
					@click.prevent="pressKey(n.id ? n.id : n.number)">
					<span :class="['clear_symbol']" v-if="n.id === 'Backspace'">
						<svg width="20" viewBox="0 0 28 20" xmlns="http://www.w3.org/2000/svg">
							<path d="M12.1363 4.27586L16.6966 8.61905L21.257 4.27586L22.6363 5.72414L18.1466 10L22.6363 14.2759L21.257 15.7241L16.6966 11.381L12.1363 15.7241L10.757 14.2759L15.2466 10L10.757 5.72414L12.1363 4.27586Z"/>
							<path clip-rule="evenodd" d="M8.04445 0C7.67414 0 7.32457 0.170966 7.09722 0.463271L0.252777 9.26327C-0.08426 9.6966 -0.0842582 10.3034 0.252777 10.7367L7.09722 19.5367C7.32457 19.829 7.67413 20 8.04444 20H26.7466C27.4094 20 27.9466 19.4627 27.9466 18.8V1.2C27.9466 0.537253 27.4094 0 26.7466 0H8.04445ZM2.21349 10L8.43571 2H25.9466V18H8.43571L2.21349 10Z"/>
						</svg>
					</span>
					<span class="key_symbol" v-else>{{ edit(n) }}</span>
				</div>
			</div>
			<div class="keyboard_btns">
				<router-link class="keyboard_btn close_btn" to="/cashbox/card-id" replace>
					<span>На главную</span>
				</router-link>
			</div>
			<!-- <div class="keyboard_characters">
				<div
					:class="{
						key_item: true,
						character_item: true,
						shift_item: c.id === 'Shift',
						pressed_item: shiftPressed && idx === 20,
						space_item: c.id === 'Space',
						enter_item: c.id === 'Enter'
					}"
					v-for="c, idx in characters"
					:key="c.character"
					@click.prevent="c.id === 'Shift' ? toggleShift() : pressKey(editCharacter(c))">
					<span
						v-if="c.id === 'Backspace'"
						:class="{
							key_symbol: true,
							character: true,
							clear: c.id === 'Backspace'
						}">
						<svg width="20" viewBox="0 0 28 20" xmlns="http://www.w3.org/2000/svg">
							<path d="M12.1363 4.27586L16.6966 8.61905L21.257 4.27586L22.6363 5.72414L18.1466 10L22.6363 14.2759L21.257 15.7241L16.6966 11.381L12.1363 15.7241L10.757 14.2759L15.2466 10L10.757 5.72414L12.1363 4.27586Z"/>
							<path clip-rule="evenodd" d="M8.04445 0C7.67414 0 7.32457 0.170966 7.09722 0.463271L0.252777 9.26327C-0.08426 9.6966 -0.0842582 10.3034 0.252777 10.7367L7.09722 19.5367C7.32457 19.829 7.67413 20 8.04444 20H26.7466C27.4094 20 27.9466 19.4627 27.9466 18.8V1.2C27.9466 0.537253 27.4094 0 26.7466 0H8.04445ZM2.21349 10L8.43571 2H25.9466V18H8.43571L2.21349 10Z"/>
						</svg>
					</span>
					<span
						v-else
						:class="c.classes">
							{{ editCharacter(c) }}
					</span>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
export default {
	name: 'ScreenKeyboard',
	emits: ['close', 'press'],
	data() {
		return {
			// characters: [
			// 	{character: 'q', classes: ['key_symbol', 'character']}, {character: 'w', classes: ['key_symbol', 'character']},
			// 	{character: 'e', classes: ['key_symbol', 'character']}, {character: 'r', classes: ['key_symbol', 'character']},
			// 	{character: 't', classes: ['key_symbol', 'character']}, {character: 'y', classes: ['key_symbol', 'character']},
			// 	{character: 'u', classes: ['key_symbol', 'character']}, {character: 'i', classes: ['key_symbol', 'character']},
			// 	{character: 'o', classes: ['key_symbol', 'character']}, {character: 'p', classes: ['key_symbol', 'character']},
			// 	{character: 'a', classes: ['key_symbol', 'character']}, {character: 's', classes: ['key_symbol', 'character']},
			// 	{character: 'd', classes: ['key_symbol', 'character']}, {character: 'f', classes: ['key_symbol', 'character']},
			// 	{character: 'g', classes: ['key_symbol', 'character']}, {character: 'h', classes: ['key_symbol', 'character']},
			// 	{character: 'j', classes: ['key_symbol', 'character']}, {character: 'k', classes: ['key_symbol', 'character']},
			// 	{character: 'l', classes: ['key_symbol', 'character']}, {character: 'z', classes: ['key_symbol', 'character']},
			// 	{character: '', classes: {key_symbol: true, character: true, shift: true}, id: 'Shift'},
			// 	{character: 'x', classes: ['key_symbol', 'character']}, {character: 'c', classes: ['key_symbol', 'character']},
			// 	{character: 'v', classes: ['key_symbol', 'character']}, {character: 'b', classes: ['key_symbol', 'character']},
			// 	{character: 'n', classes: ['key_symbol', 'character']}, {character: 'm', classes: ['key_symbol', 'character']},
			// 	{character: '.', classes: ['key_symbol', 'character']}, {character: '', classes: ['key_symbol', 'character'], id: 'Backspace'},
			// 	{character: ',', classes: ['key_symbol', 'character']},
			// 	{character: '-', classes: ['key_symbol', 'character']},	{character: '_', classes: ['key_symbol', 'character']},
			// 	{character: '', classes: ['key_symbol', 'character', 'space'], id: 'Space'},
			// 	{character: '<', classes: ['key_symbol', 'character']},{character: '>', classes: ['key_symbol', 'character']},
			// 	{character: '', classes: ['key_symbol', 'character', 'enter'], id: 'Enter'},
			// ],
			numbers: [
				{number: 1},
				{number: 2},
				{number: 3},
				{number: 4,},
				{number: 5},
				{number: 6},
				{number: 7},
				{number: 8},
				{number: 9},
				{number: '', id: 'Backspace'},
				{number: 0},
				{number: '', id: 'Enter'},
			],
			symbols: [],
			// shiftPressed: false,
		}
	},
	methods: {
		// toggleShift () {
		// 	this.shiftPressed = !this.shiftPressed
		// },
		edit (char) {
			if(char.id) {
				return char.id
			}
			return char.number
		},
		pressKey (key) {
			window.dispatchEvent(new KeyboardEvent('keydown', {
				'key': key
			}));
		},
		closeKeyboard () {
			this.$emit('close')
		}
	}
}
</script>

<style scoped>
	@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

	.keyboard{
		position: relative;
		z-index: 99;
		width: 100%;
		height: 100%;
		background-color: #1D2228;
		padding: 30px 10px;
	}

	.keyboard_inner{
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.keyboard_btns{
		position: relative;
		right: 0;
		left: 0;
	}

	.keyboard_btn{
		padding: 10px 20px;
		background-color: #1D2228;
		border: none;
		width: 100%;

		display: flex;
		justify-content: center;
		align-items: center;

		font-family: 'Roboto', sans-serif;
		font-weight: 500;
		font-size: 14px;
		color: #fff;

		cursor: pointer;
		user-select: none;
	}
	
	.keyboard_btn:hover{
		background-color: #5a6775;
	}

	.close_btn span{
		margin-right: 10px;
	}

	.close_btn svg{
		fill: #fff;
		transform: rotate(-90deg);
	}

	.keyboard_keys{
		display: flex;
		justify-content: center;
		align-content: center;
		flex-wrap: wrap;
	}


	.key_item{
		position: relative;
		padding: 20px 5px;
		margin: 4px;
		background-color: #343C45;
		border-radius: .5rem;

		flex: 0 0 calc(100%/3 - 8px);
		max-width: calc(100%/3 - 8px);

		cursor: pointer;
		user-select: none;
	}

	.key_item:hover{
		background-color: #5a6775;
	}

	.key_item:active{
		background-color: #fff;
		z-index: 2;
	}

	.key_item:active .key_symbol{
		color: #343C45;
	}

	.key_item:active .clear svg{
		fill: #343C45;
	}

	.key_symbol{
		display: inline-block;

		font-family: 'Roboto', sans-serif;
		font-weight: 500;
		font-size: 18px;
		color: #fff;

		width: 100%;
		text-align: center;
	}

	.clear_symbol{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.clear_symbol svg{
		height: 21.6px;
		fill: #fff;
	}


	/* Numbers Row */

	/* .number{
		text-align: left;
		opacity: 0.3;
	}

	.keyboard_numbers_row{
		width: 100%;
		display: flex;
		align-items: flex-start;
	}

	.with_shift{
		text-align: right;
		opacity: 0.3;
	}

	.transparent{
		opacity: 1;
	} */


	/* Characters */

	/* .keyboard_characters{
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-wrap: wrap;
	}

	.character_item{
		padding: 15px 5px;
	}

	.character{
		width: 100%;
		text-align: center;
	}

	.shift_item{
		flex: 0 0 calc(100%/5 - 8px);
		max-width: calc(100%/5 - 8px);
	}

	.enter_item{
		flex: 0 0 calc(100%/5 - 8px);
		max-width: calc(100%/5 - 8px);
	}

	.pressed_item{
		background-color: #fff;
	}

	.pressed_item .shift{
		color: #343C45;
	}

	.pressed_item:hover{
		background-color: #fff;
	}

	.space_item{
		flex: 0 0 calc(100% * 0.3 - 8px);
		max-width: calc(100% * 0.3 - 8px);
	}

	.clear svg{
		fill: #fff;
	} */
</style>