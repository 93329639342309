<template>
	<div class="cashbox">
		<div :class="{cashbox_inner: true, active: $store.state.keyboardVisible}">
			<router-view></router-view>
		</div>
		<transition name="keyboard">
			<div class="cashbox_keyboard" v-if="$store.state.keyboardVisible">
				<screen-keyboard @close="closeKeyboard()"></screen-keyboard>
			</div>
		</transition>
	</div>
</template>

<script>
import ScreenKeyboard from './ScreenKeyboard.vue'

export default {
	name: 'Cashbox',
	components: {
		ScreenKeyboard
	},
	data () {
		return {
			isBlur: false,
			value: '',
			input: '',
		}
	},
	methods: {
		onFocus() {
			const inputElem = document.getElementsByTagName('input')
			for(let i of inputElem) {
				i.addEventListener('focus', (evt) => {
					this.keyboardVisible = true
					this.input = evt.target
				})
			}
		},
		closeKeyboard() {
			this.$store.commit('closeKeyboard')
		},
		// pressKey(key) {
		// 	// this.input.focus()
		// 	window.dispatchEvent(new KeyboardEvent('keydown', {
		// 		'key': key,
		// 	}));
		// }
	},
	mounted() {
		this.onFocus()
	}
}
</script>

<style scoped>
	.cashbox{
		display: flex;
		align-items: center;
	}

	.cashbox_inner{
		width: 100%;
		height: 100vh;
		transition: .2s ease-in-out;
	}

	.cashbox_inner.active{
		width: 75%;
		transition: .2s ease-in-out;
	}

	.cashbox_keyboard{
		position: relative;
		z-index: 99;
		width: 25%;
		height: 100vh;
	}

	.keyboard-enter-from, .keyboard-leave-to{
		opacity: 0;
		transform: translateX(100px);
	}

	.keyboard-enter-active{
		transition: .2s ease-in;
	}

	.keyboard-leave-active{
		transition: .2s ease-out;
	}

	.keyboard-enter-to, .keyboard-leave-from{
		opacity: 1;
		transform: translateX(0);
	}
</style>