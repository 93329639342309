/* eslint-disable no-unused-vars */
import { createApp, Vue } from 'vue';
import router from './router.js';
import store from './store.js';
import App from './App.vue';
import '@/assets/main.css';

const app = createApp(App);	

app.use(router);
app.use(store);
app.mount('#app');
