<template>
	<div :class="{print_bckg: true, active: $store.state.keyboardVisible}" id="print_bckg">
		<div class="print_window">
			<div class="print_content">
				<div class="center" v-if="checkType === 'Z'">
					<h1>Қиял әлемі саябагы</h1>
					<h1>Улица {{ $store.state.address }}</h1>
				</div>
				<div class="left" v-if="checkType === 'Z'">
					<h2>Номер операции {{ checkData.sessionId }}</h2>
					<h2>{{ checkData.time }}</h2>
					<h2>Кассир: {{ checkData.cashier }}</h2>
				</div>
				<h1 class="margin">{{ checkType }} отчет</h1>
				<h1 class="margin" v-if="checkType === 'X'">{{ checkData.time }}</h1>
				<h2 class="margin">Закрытий смен: {{ checkData.sessionCount }}</h2>
				<hr class="margin">
				<h2 class="margin">Продаж</h2>
				<div class="right">
					<h4>
						<span>Наличные</span>
						<span>{{ checkData.sumByCash }}</span>
					</h4>
					<h4>
						<span>Безналичная оплата</span>
						<span>{{ checkData.sumByCard }}</span>
					</h4>
					<h4>
						<span>Kaspi</span>
						<span>{{ checkData.sumByKaspi }}</span>
					</h4>
				</div>
				<hr>
				<h2 class="margin">Возвратов</h2>
				<div class="right">
					<h4>
						<span>Наличные</span>
						<span>0</span>
					</h4>
				</div>
				<hr class="margin">
				<h2 class="margin">Внесений</h2>
				<div class="right">
					<h4>
						<span>Наличные</span>
						<span>0</span>
					</h4>
				</div>
				<hr class="margin">
				<h2 class="margin">Изъятий</h2>
				<div class="right">
					<h4>
						<span>Наличные</span>
						<span>0</span>
					</h4>
				</div>
				<hr class="margin">
				<div class="right margin">
					<h4>
						<span>Выручка</span>
						<span>{{ checkData.sumResult }}</span>
					</h4>
					<h4>
						<span>Наличных в кассе</span>
						<span>{{ checkData.sumByCash }}</span>
					</h4>
				</div>
				<div class="check_btns">
					<button @click.prevent="close()">Отмена</button>
					<button @click.prevent="printContent()">Распечатать</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ReportCheck',
	emits: ['close'],
	props: {
		checkData: {
			type: Object,
			required: true
		},
		checkType: {
			type: String,
			required: true
		}
	},
	methods: {
		printContent() {
			window.print();
		},
		close() {
			if (this.checkType === 'X') {
				this.$emit('close');
			} else{
				window.location.href = '/'
			}
		},
		afterPrintHandler () {
			if (this.checkType === 'Z') {
				window.location.href = '/'
			}
		}
	},
	mounted () {
		window.addEventListener('afterprint', this.afterPrintHandler)
	},
	beforeUnmount () {
		window.removeEventListener('afterprint', this.afterPrintHandler)
	}
}
</script>

<style scoped>
	.print_bckg{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1100;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.print_bckg.active{
		right: 25%;
	}

	.print_window{
		padding: 20px;
		background: #fff;
		border-radius: .5rem;
		width: 35%;
		box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
		overflow: auto;
	}

	.print_content{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;		
	}

	.center{
		width: 100%;
		margin-bottom: 15px;
	}

	.left{
		width: 100%;
		margin-bottom: 15px;
	}

	.right{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 100%;
		margin-bottom: 15px;
	}

	h1{
		width: 100%;
		text-align: center;
		font-size: 18px;
	}

	h2{
		width: 100%;
		font-size: left;
		font-size: 18px;
		font-weight: 400;
	}

	h4{
		width: 80%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 18px;
	}

	span{
		font-size: 18px;
		font-weight: 400;
	}

	.margin{
		margin-bottom: 15px;
	}

	hr{
		width: 100%;
	}

	.check_btns{
		width: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	button{
		padding: 10px 15px;
	}

	@media print{
		.print_bckg.active{
			right: 0;
		}

		.print_window{
			width: 100%;
			height: 100vh;
		}

		button{
			display: none;
		}
	}
</style>