import axios from 'axios';
import router from './router';
import {createStore} from 'vuex';

const store = createStore({
	state() {
		return{
			downloadXlsLink: '',
			isLoading: false,
			apiUrl: process.env.VUE_APP_API_URL,
			name: process.env.VUE_APP_PARK_NAME,
			address: process.env.VUE_APP_PARK_ADDRESS,
			messagingToken: '',
			messagingTokenError: false,
			errorData: '',
			keyboardVisible: true,
			inputField: '',
		}
	},
	mutations: {
		closeKeyboard(state) {
			state.keyboardVisible = false
		},
		onFocus(state) {
			const inputElem = document.getElementsByTagName('input')
			for(let i of inputElem) {
				i.addEventListener('focus', (evt) => {
					state.keyboardVisible = true
					state.inputField = evt.target
				}, false)
			}
		},
	},
	actions: {
		async getData(context, payload) {
			try{
				const response = await axios.get(payload.url, {
					headers: {
						authorization: `Bearer ${window.localStorage.getItem('access_token')}`
					}
				});
				return response;
			}catch(err) {
				if(err.response) {
					if(err.response.data.status === 401) {
						router.replace('/used-account')
					}else if(err.response.data.message === 'SESSION_NOT_FOUND') {
						context.state.errorData = err.response
						router.replace('/used-account')
					}else{
						context.state.errorData = err.response
					}
					throw Error(err)
				}
				context.state.errorData = {status: err.message}
				throw Error(err)
			}
		},
		async sendData(context, payload) {
			try{
				const response = await axios.post(payload.url, payload.data, {
					headers: {
						authorization: `Bearer ${window.localStorage.getItem('access_token')}`
					}
				});
				return response;
			}catch(err) {
				if(err.response) {
					if(err.response.data.status === 401) {
						router.replace('/used-account')
					}else if(err.response.data.message === 'SESSION_NOT_FOUND') {
						context.state.errorData = err.response
						router.replace('/used-account')
					}else{
						context.state.errorData = err.response
					}
					throw Error(err)
				}
				context.state.errorData = {status: err.message}
				throw Error(err)
			}
		},
		async signIn(context ,payload) {
			try{
				const response = await axios.post(payload.url, payload.data);
				const token = response.data.user.access_token;
				const name = response.data.user.fullname
				const role = response.data.user.role
				window.localStorage.setItem('access_token', token);
				window.localStorage.setItem('role', role)
				window.localStorage.setItem('name', name);
				return response;
			}catch(err) {
				throw new Error(err)
			}
		},
		async downloadFile(context, payload) {
			context.state.downloadXlsLink = '';
			try{
				const response = await context.dispatch('sendData', payload);
				context.state.downloadXlsLink = response.data.url;
			}catch(err) {
				throw new Error(err)
			}
		},
	},
	// getters: {
	// 	test(state) {
	// 		return state.inputValue
	// 	}
	// }
})	

export default store;