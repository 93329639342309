<template>
	<loading :expand="true" v-if="isLoading"></loading>
    <header class="header">
        <div class="add_wrp" v-if="!error">
            <button class="add" @keypress.prevent.enter @click.prevent="addModalWindow">
                <span>Добавить</span>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.86 9.72H8.28V13.14C8.28 13.239 8.361 13.32 8.46 13.32H9.54C9.639 13.32 9.72 13.239 9.72 13.14V9.72H13.14C13.239 9.72 13.32 9.639 13.32 9.54V8.46C13.32 8.361 13.239 8.28 13.14 8.28H9.72V4.86C9.72 4.761 9.639 4.68 9.54 4.68H8.46C8.361 4.68 8.28 4.761 8.28 4.86V8.28H4.86C4.761 8.28 4.68 8.361 4.68 8.46V9.54C4.68 9.639 4.761 9.72 4.86 9.72Z" fill="#0071BC"/>
                    <path d="M17.28 0H0.72C0.32175 0 0 0.32175 0 0.72V17.28C0 17.6783 0.32175 18 0.72 18H17.28C17.6783 18 18 17.6783 18 17.28V0.72C18 0.32175 17.6783 0 17.28 0ZM16.38 16.38H1.62V1.62H16.38V16.38Z" fill="#0071BC"/>
                </svg>
            </button>
        </div>
    </header>
	<error-message v-if="error"></error-message>
    <div class="table" v-else>
        <div class="row_titles">
            <h3 class="row_title_item id">
                <div class="checkbox"></div>
                <span>Название</span>
            </h3>
            <h3 class="row_title_item">Тип</h3>
            <h3 class="row_title_item">Количество</h3>
            <h3 class="row_title_item edit">Действия</h3>
        </div>
        <div 
            class="row"
            v-for="(discount, idx) in discounts"
            :key="discount.id">
            <h3 class="row_item id">
                <div class="checkbox"></div>
                <span>{{ discount.name }}</span>
            </h3>
            <h3 class="row_item">{{ discount.type === "1" ? 'Скидка' : 'Бонус' }}</h3>
            <h3 class="row_item">{{ discount.quantity }}</h3>
            <h3 class="row_item edit" @click.prevent="editModalWindow(idx, discount.id, true)">Изменить</h3>
        </div>
    </div>

	<modal-window
		v-if="modalAdd"
		:data="collectedData"
		:title="'Добавить скидку'"
		:nameField="true"
		:nameFieldType="'Название'"
		:numberField="true"
		:numberFieldType="'Количество'"
		:mixField="'checkbox-checkbox'"
		:checkboxFieldLabels="['Скидка', 'Бонус']"
		:checkboxFieldOptions="{label: 'Операция', validate: 'checkbox-checkbox'}"
		:buttonsText="['Отмена', 'Добавить', true]"
		@close="addModalWindow()"
		@send="(data) => addDiscount(data)"></modal-window>
	<modal-window
		v-if="modalEdit"
		:data="collectedData"
		:title="'Изменить скидку'"
		:nameField="true"
		:nameFieldType="'Название'"
		:numberField="true"
		:numberFieldType="'Количество'"
		:mixField="'checkbox-checkbox'"
		:checkboxFieldLabels="['Скидка', 'Бонус']"
		:buttonsText="['Удалить', 'Изменить', true]"
		@close="editModalWindow()"
		@send="(data) => editDiscount(data)"
		@delete="deleteDiscount()"></modal-window>
	<modal-window 
		v-if="modalResult"
		:data="{
			first: {},
			second: {},
			component: {},
		}"
		:resultField="true"
		:resultFieldOptions="resultOptions"
		@close="showResultModal()"></modal-window>
</template>


<script>
import Loading from '../../Loading.vue';
import ModalWindow from '../../ModalWindow.vue';
import ErrorMessage from '../../ErrorMessage.vue';

export default {
    name: 'Discounts',
	components: {
		Loading,
		ModalWindow,
		ErrorMessage
	},
    data() {
		return{
            discounts: [],
            modalAdd: false,
            modalEdit: false,
			modalResult: false,
			editItemId: 0,
			isLoading: false,
			collectedData: {
				first: {},
				secodn: {},
				component: 'discounts'
			},
			resultOptions: {},
			error: false,
        }
    },
    methods: {
		async getData() {
			try{
				this.error = false;
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/discount-list'
				}
				await this.$store.dispatch('getData', payload).then((res) => {
					this.discounts = res.data;
				});
				this.isLoading = false;
			}catch(err) {
				this.error = true;
				this.isLoading = false;
			}
		},
		async addDiscount(data) {
			try{
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/add-discount',
					data: data
				}
				await this.$store.dispatch('sendData', payload);
				this.isLoading = false;
				this.modalAdd = false;
				this.getData();
			}catch(err) {
				this.modalAdd = false;
				this.isLoading = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Произошла ошибка во время добавления скидки',
					result: false
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
			}
		},
		async editDiscount(data) {
			try{
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/update-discount',
					data: data
				}
				await this.$store.dispatch('sendData', payload);
				this.isLoading = false;
				this.modalEdit = false;
				this.getData();
			}catch(err) {
				this.modalEdit = false;
				this.isLoading = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Произошла ошибка во время изменения скидки',
					result: false
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
			}
		},
		async deleteDiscount() {
			try{
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/delete-discount',
					data: {
						id: this.editItemId,
					}
				}
				await this.$store.dispatch('sendData', payload);
				this.modalEdit = false;
				this.getData();
			}catch(err) {
				this.modalEdit = false;
				this.isLoading = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Произошла ошибка во время удаления скидки',
					result: false
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
			}
		},
		async collectData(data) {
			this.collectedData.first = data;
			this.collectedData.second = {};
		},
        editModalWindow(idx = 0, id = 0, edit = false) {
            this.modalEdit = !this.modalEdit;
            if(edit) {
				this.editItemId = id;
				const data = {
					name: this.discounts[idx].name,
					quantity: this.discounts[idx].quantity,
					type: this.discounts[idx].type,
					editItemId: id
				}
				this.collectData(data, {})
			}
        },
        addModalWindow() {
            this.modalAdd = !this.modalAdd;
            const data = {
				name: '',
				quantity: '',
				type: '',
				editItemId: 1
			}
			this.collectData(data, {})
        },
        selectDiscount() {
            this.discountSelected = !this.discountSelected;
            this.bonusSelected = false;
        },
        selectBonus() {
            this.bonusSelected = !this.bonusSelected;
            this.discountSelected = false;
        },
		showResultModal() {
			this.modalResult = !this.modalResult;
		}
    },
	mounted() {
		this.getData();
	},
}
</script>

<style scoped>
    .table{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #fff;
        padding: 10px 15px;
        overflow-y: auto;
    }

    .row_titles{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
    }

    .row_title_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 700;
        color: rgba(0,113,188,0.5);
        padding: 7px;
        width: calc(100%/5);
        padding: 5px;
    }

    .row_title_item.id{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .row_title_item.id svg{
        margin-right: 10px;
        cursor: pointer;
    }

    .row_title_item.edit{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .row:not(:last-of-type) {
        margin-bottom: 15px;
    }

    .row_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 400;
        color: #707683;
        padding: 5px;
        width: calc(100%/5);
    }

    .row_item.id{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 600;
        color: #323C47;
        padding: 7px;
    }

    .row_item.edit{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    .row_item.id{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .add_wrp{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 30px;
    }

    .add{
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        padding: 10px;
        background-color: transparent;
        border: none;
    }
    
    .add span{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 700;
        color: #0071BC;
        margin-right: 10px;
    }
</style>