<template>
	<loading v-if="isLoading"></loading>
	<section class="section">
		<div class="container">
			<div class="section_inner">
				<header class="header">
					<div class="header_title">
						<h1 class="device">Касса</h1>
						<h1 class="name">{{ name }}</h1>
					</div>
					<div class="subtitle">{{ $store.state.name }}</div>
					<button class="header_btn" @click.prevent="modalExitShow()">Выйти</button>
				</header>
				<form class="section_form" @submit.prevent="checkCard">
					<p v-if="cardCheckResult === 'blocked'">Карта заблокирована</p>
					<p v-if="cardCheckResult === 'not_found'">Карта не найдена</p>
					<p class="balance" v-if="cardCheckResult === 'balance'">Баланс карты: {{ editSum(balance) }} тг</p>
                    <input 
					type="number" 
					:class="{not_found: cardCheckResult === 'not_found' || cardCheckResult === 'blocked', active: cardId && !(cardCheckResult === 'not_found' || cardCheckResult === 'blocked')}" 
					placeholder="ID номер" 
					autocomplete="on" 
					@keydown.prevent
					v-model="cardId">
					<div class="form_btns">
						<button :class="{active: cardId}" type="submit" @click.prevent="cardId.length ? checkCard() : false" id="form_btn">Ввести в ручную</button>
						<button :class="{check_balance_btn: true, active: cardId}" @click.prevent="cardId.length ? checkBalance() : false">Проверить баланс</button>
					</div>
                </form>
				<div class="section_btn_wrp">
					<router-link to="/cashbox/reports" class="section_btn">Отчеты</router-link>
				</div>
			</div>
		</div>
	</section>
	
	<teleport to="body">

		<div :class="{modal_back: true, active: $store.state.keyboardVisible}" v-if="modalExit">
			<div class="modal sign_out">
				<div class="modal_inner">
					<div class="modal_img">
						<img src="@/assets/card-id/modal-exit.svg" alt="">
					</div>
					<div class="modal_title">Вы точно хотите завершить сеанс?</div>
					<div class="modal_btns sign_out">
						<div class="modal_btn" @click.prevent="showPrintCheck('X')">X отчет</div>
						<div class="modal_btn" @click.prevent="showPrintCheck('Z')">Z отчет</div>
					</div>
					<div class="modal_btns sign_out">
						<div class="modal_btn close_modal" @click.prevent="modalExitShow()">Отмена</div>
						<div class="modal_btn close_modal" @click.prevent="signOut()">Выйти</div>
					</div>
				</div>
			</div>
		</div>
		
		<report-check 
			:checkData="checkData"
			:checkType="checkType"
			@close="showPrintCheck()" 
			v-if="printCheck"></report-check>

		<div :class="{modal_back: true, active: $store.state.keyboardVisible}" v-if="activeCardModal">
			<div class="modal">
				<div class="modal_inner">
					<div class="modal_title">Карта не активировна</div>
					<div class="modal_subtitle">Стоимость карты 100тг</div>
					<div class="modal_btns">
						<button class="modal_btn" @click.prevent="showActiveCardModal">Отмена</button>
						<button class="modal_btn" @click.prevent="payMethodModalShow">Активировать</button>
					</div>
				</div>
			</div>
		</div>

		<div :class="{modal_back: true, active: $store.state.keyboardVisible}" v-if="activatedCardModal">
			<div class="modal">
				<div class="modal_inner">
					<div class="modal_img">
						<img src="@/assets/replenishment/tick.svg" alt="Tick">
					</div>
					<div class="modal_title activated">Карта активирована</div>
				</div>
			</div>
		</div>

		<div :class="{modal_back: true, active: $store.state.keyboardVisible}" v-if="nonActivatedCardModal">
			<div class="modal">
				<div class="modal_inner">
					<div class="modal_img">
						<img src="@/assets/replenishment/cross.svg" alt="Tick">
					</div>
					<div class="modal_title activated">Ошибка при активации</div>
				</div>
			</div>
		</div>
		
		<div :class="{modal_back: true, active: $store.state.keyboardVisible}" v-if="payMethodModal">
			<div class="modal">
				<div class="modal_inner">
					<div class="modal_header">
						<div class="modal_header_inner">
							<div class="modal_btn_back" @click.prevent="showActiveCardModal">
								<font-awesome-icon style="margin-right: 7px" icon="arrow-circle-left" />
								<span>Назад</span>
							</div>
							<div class="modal_title" id="pay_method_title">Оплата</div>
						</div>
					</div>
					<div class="modal_btns pay_method">
						<button :class="{form_btn: true, selected: cash}" @click.prevent="cashSelect">Налич<wbr>ными</button>
						<button :class="{form_btn: true, selected: cashless}" @click.prevent="cashlessSelect">Безна<wbr>личными</button>
						<button :class="{form_btn: true, selected: kaspi}" @click.prevent="kaspiSelect">Kaspi <wbr>QR</button>
					</div>
					<button :class="{modal_btn: true, active: cash || cashless || kaspi}" @click.prevent="activateCard">Оплатить</button>
				</div>
			</div>
		</div>

		<div :class="{modal_back: true, active: $store.state.keyboardVisible}" v-if="modalRep">
			<div class="modal">
				<div class="modal_inner">
					<div class="modal_img">
						<img src="@/assets/replenishment/tick.svg" alt="Tick">
					</div>
					<div class="modal_title">Пополнено на сумму {{ repSum }}</div>
					<div class="modal_btns">
						<button class="modal_btn" @click.prevent="modalRepShow">Выйти</button>
						<button class="modal_btn">Печать чека</button>
					</div>
				</div>
			</div>
		</div>

		<div :class="{modal_back: true, active: $store.state.keyboardVisible}" v-if="questionModal">
			<div class="modal">
				<div class="modal_inner">
					<div class="modal_title">Желаете ли пополнить баланс?</div>
					<div class="modal_btns">
						<button class="modal_btn" @click.prevent="questionModalShow">Нет</button>
						<router-link to="/cashbox/replenishment/sum" class="modal_btn">Пополнить</router-link>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
import Loading from './Loading.vue';
import axios from 'axios';
import ReportCheck from './ReportCheck.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faArrowCircleLeft);

export default {
	name: 'CardId',
	components: {
		ReportCheck,
		FontAwesomeIcon,
		Loading
	},
    data() {
        return{
            cardId: '',
			modalExit: false,
			modalRep: false,
			printCheck: false,
			cardCheckResult: '',
			activeCardModal: false,
			activatedCardModal: false,
			nonActivatedCardModal: false,
			payMethodModal: false,
			questionModal: false,
			cash: false,
			cashless: false,
			kaspi: false,
			isLoading: false,
			name: window.localStorage.getItem('name') ,
			balance: '',
			checkData: {},
			checkType: '',
        }
    },
	methods: {
		modalExitShow() {
			this.modalExit = !this.modalExit;
		},
		async showPrintCheck (type) {
			if(!this.printCheck) {
				await this.getCheckData(type.toLowerCase())
			}
			this.printCheck = !this.printCheck
			if(type) {
				this.checkType = type
			}
		},
		async getCheckData (type) {
			try{
				this.isLoading = true
				const payload = {
					url: this.$store.state.apiUrl + `v1/front/${type}-report`
				}
				const response = await this.$store.dispatch('getData', payload)
				this.checkData = response.data
				this.isLoading = false
			}catch (err) {
				throw new Error(err)
			}
		},
		async checkCard() {
			try{
				this.cardCheckResult = ''
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/check-card',
					data: {
						card_code: this.cardId
					}
				}
				const response = await axios.post(payload.url, payload.data, {
					headers: {
						authorization: `Bearer ${window.localStorage.getItem('access_token')}`
					}
				});
				if(response.data.is_active) {
					window.localStorage.setItem('cardId', this.cardId)
					window.localStorage.setItem('balance', response.data.balance)
					this.$router.push('/cashbox/replenishment')
				}else{
					this.cardCheckResult = 'blocked'
				}
				this.isLoading = false;
			}catch(err){
				this.isLoading = false;
				if(err.response) {
					switch(err.response.data.message) {
						case "CARD_IS_NEW":
							window.localStorage.setItem('cardId', this.cardId);
							this.showActiveCardModal();
							break;
						case "CARD_NOT_FOUND":
							this.cardCheckResult = 'not_found'
							break;
						default:
					}
				}else{
					this.cardCheckResult = 'not_found'
				}
			}

		},
		async replenishment() {
			try{
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/activate-card',
					data: {
						card_code: this.cardId,
						payment_method: this.payMethod,
					}
				}
				const data = await this.$store.dispatch('sendData', payload);
				if(data.status === 200) {
					this.activatedCardModalShow(data, null);
				}
				this.isLoading = false;
			}catch(err) {
				this.activatedCardModalShow(null, err);
				this.isLoading = false;
			}

		},
		async checkBalance() {
			try{
				this.cardCheckResult = ''
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/check-card',
					data: {
						card_code: this.cardId
					}
				}
				const response = await axios.post(payload.url, payload.data, {
					headers: {
						authorization: `Bearer ${window.localStorage.getItem('access_token')}`
					}
				});
				if(response.data.is_active) {
					this.balance = response.data.balance
					this.cardCheckResult = 'balance'
				}else{
					this.cardCheckResult = 'blocked'
				}
				this.isLoading = false;
			}catch(err){
				this.isLoading = false;
				switch(err.response.data.message) {
					case "CARD_IS_NEW":
						window.localStorage.setItem('cardId', this.cardId);
						this.showActiveCardModal();
						break;
					case "CARD_NOT_FOUND":
						this.cardCheckResult = 'not_found'
						break;
				}
			}
		},
		async signOut () {
			try{
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + '/v1/logout/logout',
				}
				await this.$store.dispatch('sendData', payload)
				this.isLoading = false;
				window.location.href = '/'
			}catch (err) {
				throw new Error(err)
			}
		},
		showActiveCardModal() {
			this.activeCardModal = !this.activeCardModal;
			this.payMethodModal = false;
		},
		payMethodModalShow() {
			this.payMethodModal = !this.payMethodModal;
			this.activeCardModal = false;
		},
		cashSelect() {
			this.cash = !this.cash;
			this.cashless = false;
			this.kaspi = false;
		},
		cashlessSelect() {
			this.cashless = !this.cashless;
			this.cash = false;
			this.kaspi = false;
		},
		kaspiSelect() {
			this.kaspi = !this.kaspi;
			this.cash = false;
			this.cashless = false;
		},
		activateCard() {
			this.replenishment();
		},
		activatedCardModalShow(data, err) {
			this.payMethodModal = false;
			this.cash = false;
			this.cashless = false;
			this.kaspi = false;
			if(data) {
				this.activatedCardModal = true;
			}else if(err) {
				this.nonActivatedCardModal = true
			}
			setTimeout(() => {
				if(data) {
					this.activatedCardModal = false;
					this.questionModalShow()
				}else if(err) {
					this.nonActivatedCardModal = false;
				}

			}, 1000);

		},
		modalRepShow() {
			this.payMethodModal = false;
			this.modalRep = !this.modalRep;
			this.cash = false;
			this.cashless = false;
			this.kaspi = false;
			this.cardId = '';
			window.localStorage.removeItem('cardId')
		},
		questionModalShow() {
			this.questionModal = !this.questionModal;
			if(!this.questionModal) {
				window.localStorage.removeItem('cardId')
			}
		},
		editSum(amount) {
			return Number.parseInt(amount);
		},
		clearStorage() {
			const token = window.localStorage.getItem('access_token');
			const name = window.localStorage.getItem('name')
			const role = window.localStorage.getItem('role');
			window.localStorage.clear();
			window.localStorage.setItem('access_token', token);
			window.localStorage.setItem('name', name);
			window.localStorage.setItem('role', role);
		},
		inputScreenKeyboard(evt) {
			if(/^(\d+)$/.test(evt.key)) {
				this.cardId += evt.key
			} else if(evt.key === 'Backspace') {
				this.cardId = this.cardId.substring(0, this.cardId.length - 1)
			} else if(evt.key === 'Enter') {
				this.checkCard()
			}
		}
	},
	computed: {
		payMethod() {
			if(this.cash) {
				return '1'
			}else if(this.cashless) {
				return '2'
			}else if(this.kaspi) {
				return '3'
			}
			return null
		},
	},
	watch: {
		cardId() {
			this.cardCheckResult = '';
			if(this.cardId.length >= 8) {
				this.cardId = this.cardId.slice(0, 8);
			}
		}	
	},
	mounted() {
		this.clearStorage()
		window.addEventListener('keydown', this.inputScreenKeyboard)
	},
	beforeUnmount() {
		window.removeEventListener('keydown', this.inputScreenKeyboard)
	}
}
</script>

<style scoped>
	.section{
        position: relative;
		z-index: 99;
        width: 100%;
        height: 100vh;
    }

    .section_inner{
        position: relative;
        z-index: 99;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100vh;
		padding: 30px 0;
    }

	.header{
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
	}

	.device{
		font-family: "Open Sans";
        font-size: 36px;
        font-weight: 700;
        color: #0071BC;
	}

	.name{
		font-family: "Open Sans";
        font-size: 36px;
        font-weight: 700;
        color: #000;
	}

	.subtitle{
		font-family: "Open Sans";
        font-size: 36px;
        font-weight: 700;
		color: #666;
	}

	.header_btn{
		font-family: "Open Sans";
        font-size: 24px;
        font-weight: 700;
        color: #fff;
		text-decoration: underline;
		cursor: pointer;
		padding: 15px 50px;
		border: none;
		background: #DADCE0;
		border-radius: .5rem;
		transition: .1s linear;
	}

	.header_btn:hover{
		background: #0071BC;
	}

    .section_form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
    }

	.section_form p{
		font-family: "Open Sans";
		font-weight: 400;
		font-size: 18px;
		color: #ED1C24;
		margin-bottom: 10px;
		width: 100%;
	}

	.section_form p.balance{
		color: #0071BC;
	}

    .section_form input{
        width: 100%;
        padding: 20px;
        border-radius: .5rem;
        border: solid 3px #bfbfbf;
        font-family: "Open Sans";
        font-size: 36px;
        font-weight: 600;
        color: #828282;
        margin-bottom: 40px;
    }

	.section_form input.active{
		border: solid 3px #0071BC;
		color: #000;
	}

	input.not_found{
		border: solid 3px #ED1C24;
		color: #000;
	}

	.form_btns{
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

    #form_btn{
		width: 60%;
        font-family: "Open Sans";
        font-size: 28px;
        font-weight: 700;
        color: #fff;
        border: none;
        background: #DADCE0;
        padding: 15px 30px;
        border-radius: .5rem;
        transition: .1s linear;
		text-decoration: none;
		text-align: center;
		cursor: pointer;
		margin-bottom: 20px;
    }

	.check_balance_btn{
		width: 60%;
		font-family: "Open Sans";
        font-size: 28px;
        font-weight: 700;
        color: #DADCE0;
        border: solid 3px #DADCE0;
        background: transparent;
        padding: 15px 30px;
        border-radius: .5rem;
        transition: .1s linear;
		text-decoration: none;
		text-align: center;
		cursor: pointer;
	}

	.check_balance_btn.active{
		border: solid 3px #0071BC;
		color: #0071BC;
	}

    #form_btn.active{
        background: #0071BC;
        color: #fff;
    }

	.section_btn_wrp{
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.section_btn{
		font-family: "Open Sans";
        font-size: 24px;
        font-weight: 700;
        color: #fff;
		padding: 15px 50px;
		border: none;
		background: #DADCE0;
		border-radius: .5rem;
		transition: .1s linear;
		text-decoration: none;
	}

	.section_btn:hover{
		background: #0071BC;
	}

	.modal_back{
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 999;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.modal_back.active{
		right: 25%;
	}

	.modal{
		width: 50%;
		padding: 30px;
		background: #fff;
		border-radius: .5rem;
		box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
	}

	.modal_inner{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.modal_img{
		margin-bottom: 30px;
	}

	.modal_header{
		width: 100%;
		display: flex;
		justify-content: flex-start;
		margin-bottom: 25px;
	}

	.modal_header_inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 62%;
	}

	.modal_btn_back{
		cursor: pointer;
		user-select: none;
	}

	.modal_btn_back span{
		font-family: "Open Sans";
		font-weight: 400;
		font-size: 19px;
		color: #000;
	}

	.modal_title{
		font-family: "Open Sans";
		font-size: 36px;
		font-weight: 700;
		color: #000;
		text-align: center;
		margin-bottom: 40px;
	}

	.modal_title.activated{
		margin: 0;
	}

	#pay_method_title{
		margin-bottom: 0;
	}

	.modal_subtitle{
		font-family: "Open Sans";
		font-size: 24px;
		font-weight: 400;
		color: #000;
		text-align: center;
		margin-bottom: 40px;
	}

	.modal_btns{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.modal_btn{
		font-family: "Open Sans";
		font-size: 24px;
		font-weight: 700;
		color: #fff;
		padding: 15px;
		width: 48%;
		background: #AAAFB5;
		border-radius: .5rem;
		text-align: center;
		user-select: none;
		cursor: pointer;
		transition: .1s linear;
		border: none;
	}
	
	.modal_btns.pay_method{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 25px;
	}

	.modal_btns.sign_out:not(:last-of-type){
		margin-bottom: 25px;
	}

	.modal_btns.pay_method .modal_btn{
		width: 30%;
		padding: 30px 15px;
		font-size: 16px;
	}

	.modal_btn.active{
		background: #0071BC;
	}

	.modal_btn:hover{
		background: #0071BC;
	}

	.form_btn{
		flex: 0 0 30%;
		max-width: 30%;
		padding: 30px 15px;
		background: transparent;
		border: solid 2px #BFBFBF;
		border-radius: .5rem;
		font-family: "Open Sans";
		font-size: 19px;
		font-weight: 700;
		color: #BFBFBF;
		text-align: center;
	}

	.form_btn.selected{
		border: solid 2px #0071BC;
		color: #000;
	}

</style>