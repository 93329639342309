<template>
	<div class="table" ref="table">
        <div :class="{table_inner: true, static_text: staticText.visible}">
			<h3 class="static_text" v-if="staticText.visible">
				{{ staticText.content }}
			</h3>
            <div class="row_titles">
				<div 
					:class="{
						row_title_item: true, 
						right: title.right, 
						left: title.left
					}" 
					v-for="title in titles" 
					:key="title"
					:style="{
						flex: `0 0 100%/${titles.length}`,
						maxWidth: `100%/${titles.length}`
					}">
					<span>{{ title }}</span>
				</div>
            </div>
			<div class="row" 
				v-for="row in data" 
				:key="row.id">
				<div 
					:class="{row_item: true}" 
					v-for="(value, idx) of requiredValues" 
					:key="idx"
					:style="{
						flex: `0 0 100%/${requiredValues.length}`,
						maxWidth: `100%/${requiredValues.length}`,
						cursor: value === 'action' ? 'pointer' : false,
						userSelect: value === 'action' ? 'none' : false
					}"
					@click.prevent="value === 'action' ? action(setType(type), type === 1 ? row.id : false, type === 2 ? row.id : false) : false">
					<span>{{ edit(value, row) }}</span>
				</div>
			</div>
        </div>
        <div class="pagination" v-if="type > 1 && !staticText.visible">
            <span class="prev count" @click="toStart">{{ pagesAmountCount > 0 ? 1 : 0 }}</span>
            <button class="prev_btn btn" @click="prev">
                <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.177677 7.79061C0.291166 7.92467 0.445244 8 0.605923 8C0.766602 8 0.920679 7.92467 1.03417 7.79061L3.82232 4.5047C4.05923 4.22551 4.05923 3.7745 3.82232 3.49531L1.03417 0.209397C0.797267 -0.0697987 0.414579 -0.0697988 0.177677 0.209397C-0.0592249 0.488591 -0.0592249 0.939599 0.177677 1.21879L2.53455 4.00358L0.177677 6.78122C-0.0592255 7.06041 -0.0531511 7.51858 0.177677 7.79061Z" fill="#707683"/>
                </svg>
            </button>
            <input type="number" v-model="page" class="edit_page" readonly>
            <button class="next_btn btn" @click="next">
                <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.177677 7.79061C0.291166 7.92467 0.445244 8 0.605923 8C0.766602 8 0.920679 7.92467 1.03417 7.79061L3.82232 4.5047C4.05923 4.22551 4.05923 3.7745 3.82232 3.49531L1.03417 0.209397C0.797267 -0.0697987 0.414579 -0.0697988 0.177677 0.209397C-0.0592249 0.488591 -0.0592249 0.939599 0.177677 1.21879L2.53455 4.00358L0.177677 6.78122C-0.0592255 7.06041 -0.0531511 7.51858 0.177677 7.79061Z" fill="#707683"/>
                </svg>
            </button>
            <span class="next count" @click="toEnd">{{ pagesAmountCount }}</span>
        </div>
    </div>
</template>

<script>
export default {
	name: 'Chart',
	emits: ['action', 'prev', 'next', 'toStart', 'toEnd', 'changePage'],
	props: {
		data: {
			type: Object,
			required: true
		},
		titles: {
			type: Array,
			required: true
		},
		requiredValues: {
			type: Array,
			required: true	
		},
		type: {
			type: Number,
			required: true
		},
		pages: {
			type: Number,
			required: false,
			default() {
				return 1
			}
		},
		currentPage: {
			type: Number,
			required: false,
			default() {
				return 0
			}
		},
		initialPage: {
			type: Number,
			required: false,
			default() {
				return 0
			}
		},
		staticText: {
			type: Object,
			required: false,
			default() {
				return {
					content: '',
					visible: false
				}
			}
		}
	},
	data () {
		return {
			pagesAmountCount: 0,
			page: 1,
		}
	},
	methods: {
		action (type, id, session) {
			this.$emit('action', type, id, session)
		},
		setType() {
			switch(this.type) {
				case 1:
					return 2
				case 2:
					return 3
				default:
					return 3
			}
		},
		edit(value, item) {
			if(value === 'action') {
				return 'Подробнее'
			}
			if (value === 'type') {
				switch(item[value]) {
					case 0:
						return 'Пополнение'
					case 1:
						return 'Списание'
				}
			}
			if (value === 'active') {
				if(item[value]) {
					return 'Активный'
				}
				return 'Неактивный'
			}
			return item[value]
		},
		prev() {
			if(this.currentPage > 1) {
				this.$emit('prev')
			} 
		},
		next() {
			if(this.currentPage < this.pagesAmountCount) {
				this.$emit('next')
			}
		},
		toStart() {
			this.$emit('toStart')
		},
		toEnd() {
			this.$emit('toEnd')
		},
		setPage() {
			this.page = this.currentPage
			this.pagesAmountCount = this.pages
			if(this.pagesAmountCount <= 0) {
				this.page = 0
			}
		}
	},
	computed: {
		editData () {
			const editedData = []
			for(let i of this.data) {
				const {id, ...newData} = i
				Object.defineProperty(newData, "id", {
					value: id,
					enumerable: false
				});
				editedData.push(newData)
			}
			return editedData
		},
	},
	watch: {
		page() {
			if(this.page > 0 && this.page <= this.pagesAmountCount) {
				this.$emit('changePage', this.page)
			}
		}
	},
	mounted() {
		this.page = this.currentPage
	},
	beforeUpdate() {
		this.setPage()
	}
}
</script>

<style scoped>
	.table{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #fff;
        padding: 10px 15px;
        overflow-y: auto;
    }

    .table_inner{
        width: 100%;
        margin-bottom: 30px;
    }

	.table_inner.static_text{
		margin: 0;
	}

	.static_text{
		text-align: center;
	}

    .row_titles{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
    }

    .row_title_item{
        width: calc(100%/4);
        padding: 5px;
		user-select: none;
		text-align: center;
    }

	.row_title_item span{
		font-family: "Open Sans";
        font-size: 12px;
        font-weight: 700;
        color: rgba(0,113,188,0.5);
	}

	.row_title_item.sort{
		cursor: pointer;
	}

	.row_title_item.sort img.reverse{
		transform: rotate(180deg);
	}

    .row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .row:not(:last-of-type) {
        margin-bottom: 15px;
    }

    .row_item{
        padding: 5px;
        width: calc(100%/4);
		text-align: center;
    }

	.row_item span{
		font-family: "Open Sans";
        font-size: 12px;
        font-weight: 400;
        color: #707683;
	}

    .pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
    }

    .count{
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #707683;
        padding: 5px 15px;
        cursor: pointer;
    }

    .current{
        border: solid 1px #707683;
        color: #0071BC;
    }

    .btn{
        background-color: transparent;
        border: none;
        padding: 5px 15px;
        cursor: pointer;
    }

    .prev_btn{
        transform: rotate(180deg);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }

    .edit_page{
        width: 80px;
        padding: 5px 15px;
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #0071BC;
        text-align: center;
        padding: 5px 15px;
        border: solid 1px #707683;
		cursor: default;
        overflow: visible;
    }
</style>	