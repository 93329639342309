<template>
	<div class="error">
		<img src="@/assets/admin/error.svg" alt="Error">
		<div class="text">
			<p>Что то пошло не так</p>
			<p>Ошибка с кодом: {{ $store.state.errorData.status }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ErrorMessage',
}
</script>

<style scoped>
	.error{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	img{
		width: 250px;
		margin-bottom: 30px;
	}

	.text{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	p{
		font-family: "Open Sans";
		font-weight: 700;
		font-size: 25px;
		text-align: center;
	}
</style>