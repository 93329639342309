<template>
    <router-view ></router-view>
	<div class="backg">
		<img src="@/assets/login/login-backg.svg" alt="Backgroung">
	</div>
	<div class="left_corner corner">
		<img src="@/assets/login/corner-1.svg" alt="Corner">
	</div>
	<div class="right_corner corner">
		<img src="@/assets/login/corner-2.svg" alt="Corner">
	</div>
</template>

<script>
import '@/assets/main.css';

export default {
    name: 'App',
}
</script>

<style scoped>
.backg{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        user-select: none;
    }

    .backg img{
        max-width: 100%;
        max-height: 100%;
    }

    .corner{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        z-index: 1;
        width: 25%;
        height: 35%;
        user-select: none;
    }

    .corner img{
        max-width: 100%;
        max-height: 100%;
    }

    .left_corner{
        left: 0;
    }

    .right_corner{
        right: 0;
    }
</style>
