<template>
	<loading :expand="true" v-if="isLoading"></loading>
	<error-message v-if="error"></error-message>
    <div class="balance_migrate" v-else>
        <div class="container">
            <div class="balance_migrate_inner">
                <form action="" class="form">
                    <div class="form_row card_fields">
                        <div class="card_field_item">
                            <label for="from">
								Откуда
								<p v-if="cardFrom.empty">*</p>
								</label>
                            <input 
								type="number" 
								id="from"
								placeholder="ID номер" 
								v-model="cardFrom.content"
								:readonly="cardFrom.readonly"
								@input="validateCardCode(cardFrom, 'cardFrom')">
							<div class="card_info" v-if="cardFrom.show">
								<span v-if="cardFrom.result === 'active'">
									<img src="@/assets/devices/tick.svg" alt="">
									Карта активна
								</span>
								<span v-if="cardFrom.result === 'not_active'">
									<img src="@/assets/devices/cross.svg" alt="">
									Карта не активна
								</span>
								<span v-if="cardFrom.result === 'blocked'">
									<img src="@/assets/devices/cross.svg" alt="">
									Карта заблокирована
								</span>
								<span v-if="cardFrom.result === 'active'">Баланс: {{ cardFrom.data.balance }}</span>
							</div>
							<p v-if="cardFrom.result === 'not_found'">Карта не найдена</p>
                        </div>
                        <div class="card_field_item">
                            <label for="to">
								Куда
								<p v-if="cardTo.empty">*</p>
							</label>
                            <input 
								type="number" 
								id="to" 
								placeholder="ID номер" 
								v-model="cardTo.content"
								:readonly="cardTo.readonly"
								@input="validateCardCode(cardTo, 'cardTo')">
							<div class="card_info" v-if="cardTo.show">
								<span v-if="cardTo.result === 'active'">
									<img src="@/assets/devices/tick.svg" alt="">
									Карта активна
								</span>
								<span v-if="cardTo.result === 'not_active'">
									<img src="@/assets/devices/cross.svg" alt="">
									Карта не активна
								</span>
								<span v-if="cardTo.result === 'blocked'">
									<img src="@/assets/devices/cross.svg" alt="">
									Карта заблокирована
								</span>
								<span v-if="cardTo.result === 'active'">Баланс: {{ cardTo.data.balance }}</span>
							</div>
							<p v-if="cardTo.result === 'not_found'">Карта не найдена</p>
                        </div>
                    </div>
                    <div class="title">Причина переноса</div>
                    <div class="form_row first">
						<label class="radio_container">
							<input type="radio" :value="1" name="reason" v-model="reason">
							<span class="checkmark">
								<img src="@/assets/add-options/tick.svg" alt="">
							</span>
							Дефект карты
						</label>
						<!-- <label class="radio_container">
							<input type="radio" :value="2" name="reason" v-model="reason">
							<span class="checkmark">
								<img src="@/assets/add-options/tick.svg" alt="">
							</span>
							Text
						</label>
						<label class="radio_container">
							<input type="radio" :value="3" name="reason" v-model="reason">
							<span class="checkmark">
								<img src="@/assets/add-options/tick.svg" alt="">
							</span>
							Text
						</label> -->
                    </div>
                    <!-- <div class="form_row last">
						<label class="radio_container">
							<input type="radio" :value="4" name="reason" v-model="reason">
							<span class="checkmark">
								<img src="@/assets/add-options/tick.svg" alt="">
							</span>
							Text
						</label>
						<label class="radio_container">
							<input type="radio" :value="5" name="reason" v-model="reason">
							<span class="checkmark">
								<img src="@/assets/add-options/tick.svg" alt="">
							</span>
							Text
						</label>
						<label class="radio_container">
							<input type="radio" :value="6" name="reason" v-model="reason">
							<span class="checkmark">
								<img src="@/assets/add-options/tick.svg" alt="">
							</span>
							Text
						</label>
                    </div> -->
                    <button :class="{submit_btn: true, active: checkValidity}" @click.prevent="sendBalance">Отправить</button>
                </form>
            </div>
        </div>
    </div>
	<modal-window 
		v-if="modalResult"
		:data="{
			first: {},
			second: {},
			component: {},
		}"
		:resultField="true"
		:resultFieldOptions="resultOptions"
		@close="showResultModal()"></modal-window>
</template>

<script>
/* eslint-disable no-unused-vars */
import Loading from '../../Loading.vue';
import ModalWindow from '../../ModalWindow.vue'
import axios from 'axios';
import ErrorMessage from '../../ErrorMessage.vue';

export default {
	name: 'BalanceMigrate',
	components: {
		Loading,
		ModalWindow,
		ErrorMessage
	},
	data() {
		return{
			isLoading: false,
			cardFrom: {
				content: '',
				empty: true,
				data: null,
				result: '',
				show: false,
				readonly: false
			},
			cardTo: {
				content: '',
				found: true,
				empty: true,
				data: null,
				active: false,
				blocked: false,
				readonly: false,
			},
			reason: 1,
			resultOptions: {},
			modalResult: false,
			error: false,
		}
	},
	methods: {
		async sendBalance() {
			if(this.checkValidity) {
				try{
					this.isLoading = true;
					const payload = {
						url: this.$store.state.apiUrl + "v1/front/transfer-balance",
						data: {
							cardFromId: this.cardFrom.content,
							cardToId: this.cardTo.content,
							reason: this.reason,
						}
					}
					const response = await axios.post(payload.url, payload.data, {
						headers: {
							authorization: `Bearer ${window.localStorage.getItem('access_token')}`
						}
					});
					this.isLoading = false;
					this.resultOptions = {
						message: 'Перевод прошел успешно',
						result: true
					}
					this.modalResult = true
					setTimeout(() => {
						this.modalResult = false
					}, 2000);
					this.checkCard(this.cardFrom)
					this.checkCard(this.cardTo)
				}catch(err) {
					this.isLoading = false;
					this.resultOptions = {
						message: 'Что то пошло не так',
						result: false
					}
					this.modalResult = true
					setTimeout(() => {
						this.modalResult = false
					}, 2000);
				}
			}
		},
		async checkCard(card) {
			try{
				this.error = false;
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/check-card',
					data: {
						card_code: card.content
					}
				}
				const response = await axios.post(payload.url, payload.data, {
					headers: {
						authorization: `Bearer ${window.localStorage.getItem('access_token')}`
					}
				});
				card.data = response.data;
				card.show = true;
				if(card.data.is_active === 1) {
					card.result = 'active'
				}else{
					card.result = 'blocked'
				}
				this.isLoading = false;
				card.readonly = false;
			}catch(err) {
				this.isLoading = false;
				if(err.response) {
					card.show = true;
					card.readonly = false;
					if(err.response) {
						if(err.response.data.message === 'CARD_NOT_FOUND') {
							card.show = false;
							card.result = 'not_found'
						}else if(err.response.data.message === 'CARD_IS_NEW') {
							card.result = 'not_active'
						}
						return
					}
				}
				this.error = true
			}
		},
		validateCardCode(card) {
			card.empty = true;
			card.show = false;
			card.result = ''
			if(card.content.length > 0) {
				card.empty = false;
			}
			if(card.content.length >= 8) {
				card.content = card.content.slice(0, 8);
				card.readonly = true;
				this.checkCard(card);
			}
		},
		showResultModal() {
			this.modalResult = !this.modalResult;
		}
	},
	computed: {
		checkValidity() {
			const cardFromValidity = this.cardFrom.result === 'active' && this.cardFrom.show && this.cardFrom.content.length >= 8;
			const cardToValidity = this.cardTo.result === 'active' && this.cardTo.show && this.cardTo.content.length >= 8;
			const validity = cardFromValidity && cardToValidity && this.cardFrom.content !== this.cardTo.content; 
			return validity;
		}
	},
}
</script>

<style scoped>
    .balance_migrate{
        max-width: 60%;
        padding: 20px;
        background: #fff;
    }

    .card_fields{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .card_field_item input{
        padding: 10px 15px;
        border-radius: .5rem;
        border: none;
        background-color: #f1f1f1;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
    }

    .card_field_item:first-of-type{
        margin-right: 10px;
    }

    .card_field_item label{
        display: flex;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
        margin-right: 10px;
        margin-bottom: 10px;
    }

	.card_info{
		display: flex;
		flex-direction: column;
	}

	.card_info span{
		font-family: "Open Sans";
        font-weight: 400;
        font-size: 12px;
        color: #000;
	}

	.card_info span img{
		display: inline-block;
		vertical-align: middle;
	}

	p{
		font-family: "Open Sans";
        font-weight: 400;
        font-size: 12px;
        color: #FF0000;
        margin-left: 5px;
        user-select: none;
	}

	.radio_container {
		display: block;
		position: relative;
		padding-left: 25px;
		margin-bottom: 12px;
		cursor: pointer;
		font-family: "Open Sans";
        font-weight: 400;
        font-size: 12px;
		color: #000;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default radio button */
	.radio_container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom radio button */
	.checkmark {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		height: 16px;
		width: 16px;
		background-color: #fff;
		border: solid 2px #D5D5D5;
		border-radius: .2rem;
	}

	/* When the radio button is checked, add a blue background */
	.radio_container input:checked ~ .checkmark {
		background-color: #0071BC;
		border: solid 2px #90C1E1;
	}

    .title{
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
        margin-bottom: 10px;
    }

    .form{
        display: flex;
        flex-direction: column;
    }

    .form_row.first{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .form_row.last{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .form_row_item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: calc(100%/3);
    }

    .form_row_item span{
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 12px;
        color: #000;
    }

    .submit_btn{
        width: 30%;
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 12px;
        color: #80868B;
        background-color: #DADCE0;
        padding: 10px 15px;
        border: none;
        border-radius: .5rem;
        transition: .2s linear;
    }

	.submit_btn.active{
		color: #fff;
        background-color: #0071BC;
	}

    @media(max-width: 1200px) {
        .card_fields{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
        }

        .card_field_item:first-of-type{
            margin-bottom: 20px;
        }
    }
</style>