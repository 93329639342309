<template>
	<loading :expand="true" v-if="isLoading"></loading>
    <header class="header" v-if="!error">
        <div class="search_wrp">
            <input type="number" placeholder="ID номер" v-model="search" :readonly="searchReadOnly">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.9399 18.5624L13.4474 12.0699C14.4549 10.7675 14.9999 9.17496 14.9999 7.49997C14.9999 5.49498 14.2174 3.61498 12.8024 2.19749C11.3874 0.779996 9.50246 0 7.49997 0C5.49748 0 3.61248 0.782496 2.19749 2.19749C0.779996 3.61248 0 5.49498 0 7.49997C0 9.50246 0.782496 11.3874 2.19749 12.8024C3.61248 14.2199 5.49498 14.9999 7.49997 14.9999C9.17496 14.9999 10.765 14.4549 12.0674 13.4499L18.5599 19.9399C18.579 19.959 18.6016 19.9741 18.6264 19.9844C18.6513 19.9947 18.678 20 18.7049 20C18.7318 20 18.7585 19.9947 18.7834 19.9844C18.8083 19.9741 18.8309 19.959 18.8499 19.9399L19.9399 18.8524C19.959 18.8334 19.9741 18.8108 19.9844 18.7859C19.9947 18.761 20 18.7343 20 18.7074C20 18.6805 19.9947 18.6538 19.9844 18.6289C19.9741 18.6041 19.959 18.5815 19.9399 18.5624V18.5624ZM11.46 11.46C10.4 12.5174 8.99496 13.0999 7.49997 13.0999C6.00497 13.0999 4.59998 12.5174 3.53998 11.46C2.48249 10.4 1.89999 8.99496 1.89999 7.49997C1.89999 6.00497 2.48249 4.59748 3.53998 3.53998C4.59998 2.48249 6.00497 1.89999 7.49997 1.89999C8.99496 1.89999 10.4025 2.47999 11.46 3.53998C12.5174 4.59998 13.0999 6.00497 13.0999 7.49997C13.0999 8.99496 12.5174 10.4025 11.46 11.46Z" fill="#80868B"/>
            </svg>
        </div>
        <button class="add" @click="addModalWindow" @keypress.prevent.enter>
            <span>Добавить</span>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.86 9.72H8.28V13.14C8.28 13.239 8.361 13.32 8.46 13.32H9.54C9.639 13.32 9.72 13.239 9.72 13.14V9.72H13.14C13.239 9.72 13.32 9.639 13.32 9.54V8.46C13.32 8.361 13.239 8.28 13.14 8.28H9.72V4.86C9.72 4.761 9.639 4.68 9.54 4.68H8.46C8.361 4.68 8.28 4.761 8.28 4.86V8.28H4.86C4.761 8.28 4.68 8.361 4.68 8.46V9.54C4.68 9.639 4.761 9.72 4.86 9.72Z" fill="#0071BC"/>
                <path d="M17.28 0H0.72C0.32175 0 0 0.32175 0 0.72V17.28C0 17.6783 0.32175 18 0.72 18H17.28C17.6783 18 18 17.6783 18 17.28V0.72C18 0.32175 17.6783 0 17.28 0ZM16.38 16.38H1.62V1.62H16.38V16.38Z" fill="#0071BC"/>
            </svg>
        </button>
    </header>
	<error-message v-if="error"></error-message>
    <div class="table" v-else>
        <div class="row_titles">
            <h3 class="row_title_item id">
                <span>ID карты</span>
            </h3>
            <h3 class="row_title_item">Баланс</h3>
            <h3 class="row_title_item">Статус</h3>
            <h3 class="row_title_item">Дата активации</h3>
            <h3 class="row_title_item action">Действия</h3>
        </div>
        <div
            class="row"
            v-for="(card, idx) in cards"
            :key="card.cardId">
                <h3 class="row_item id">
                <span>{{ card.code }}</span>
                </h3>
            <h3 class="row_item">{{ card.balance }}</h3>
            <h3 class="row_item active">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="card.status === '1'">
                    <path d="M11 0C4.93281 0 0 4.93281 0 11C0 17.0672 4.93281 22 11 22C17.0672 22 22 17.0672 22 11C22 4.93281 17.0672 0 11 0ZM16.5773 8.6668L10.6176 14.6266C10.4371 14.807 10.2051 14.8973 9.96875 14.8973C9.73242 14.8973 9.50039 14.807 9.31992 14.6266L6.34219 11.6488C5.98555 11.2922 5.98555 10.7121 6.34219 10.3512C6.69883 9.99453 7.27891 9.99453 7.63984 10.3512L9.97305 12.6844L15.284 7.37344C15.6406 7.0168 16.2207 7.0168 16.5816 7.37344C16.934 7.73008 16.934 8.31016 16.5773 8.6668Z" fill="#00A651"/>
                </svg>
				<span v-if="card.status === '1'">Активирован</span>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="card.status === '0'">
                    <path d="M11 0C4.93281 0 0 4.93711 0 11C0 17.0629 4.93281 22 11 22C17.0672 22 22 17.0672 22 11C22 4.93281 17.0672 0 11 0ZM15.052 13.7543C15.4086 14.1109 15.4086 14.691 15.052 15.052C14.8715 15.2324 14.6395 15.3227 14.4031 15.3227C14.1668 15.3227 13.9348 15.2324 13.7543 15.052L11 12.2977L8.2457 15.052C8.06523 15.2324 7.8332 15.3227 7.59688 15.3227C7.36055 15.3227 7.12852 15.2324 6.94805 15.052C6.59141 14.6953 6.59141 14.1152 6.94805 13.7543L9.70234 11L6.94805 8.2457C6.59141 7.88906 6.59141 7.30898 6.94805 6.94805C7.30469 6.59141 7.88477 6.59141 8.2457 6.94805L11 9.70234L13.7543 6.94805C14.1109 6.59141 14.691 6.59141 15.052 6.94805C15.4086 7.30469 15.4086 7.88477 15.052 8.2457L12.2977 11L15.052 13.7543Z" fill="#ED1C24"/>
                </svg>
				<span v-if="card.status === '0'">Заблокирован</span>
            </h3>
            <h3 class="row_item">{{ card.created_at }}</h3>
            <h3 class="row_item action" @click="editModalWindow(idx, card.id, true)">Заблокировать</h3>
        </div>
		<div class="pagination">
            <span class="prev count" @click="toStart">1</span>
            <button class="prev_btn btn" @click="prev">
                <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.177677 7.79061C0.291166 7.92467 0.445244 8 0.605923 8C0.766602 8 0.920679 7.92467 1.03417 7.79061L3.82232 4.5047C4.05923 4.22551 4.05923 3.7745 3.82232 3.49531L1.03417 0.209397C0.797267 -0.0697987 0.414579 -0.0697988 0.177677 0.209397C-0.0592249 0.488591 -0.0592249 0.939599 0.177677 1.21879L2.53455 4.00358L0.177677 6.78122C-0.0592255 7.06041 -0.0531511 7.51858 0.177677 7.79061Z" fill="#707683"/>
                </svg>
            </button>
            <input type="number" v-model="page" class="edit_page">
            <button class="next_btn btn" @click="next">
                <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.177677 7.79061C0.291166 7.92467 0.445244 8 0.605923 8C0.766602 8 0.920679 7.92467 1.03417 7.79061L3.82232 4.5047C4.05923 4.22551 4.05923 3.7745 3.82232 3.49531L1.03417 0.209397C0.797267 -0.0697987 0.414579 -0.0697988 0.177677 0.209397C-0.0592249 0.488591 -0.0592249 0.939599 0.177677 1.21879L2.53455 4.00358L0.177677 6.78122C-0.0592255 7.06041 -0.0531511 7.51858 0.177677 7.79061Z" fill="#707683"/>
                </svg>
            </button>
            <span class="next count" @click="toEnd">{{ pagesAmountCount }}</span>
        </div>
    </div>

	<modal-window 
		v-if="modalAdd"
		:data="collectedData"
		:title="'Активировать карту'"
		:numberField="true"
		:numberFieldType="'ID карты'"
		:numberFieldOptions="{readonly: false, validate: 'cardCode'}"
		:mixField="'checkbox-checkbox-checkbox'"
		:checkboxFieldLabels="['Наличными', 'Безналичными', 'Kaspi QR']"
		:buttonsText="['Отмена', 'Активировать', true]"
		@close="addModalWindow()"
		@send="(data) => addCard(data)"></modal-window>
	<modal-window 
		v-if="modalEdit"
		:data="collectedData"
		:title="'Изменить статус'"
		:numberField="true"
		:numberFieldType="'ID карты'"
		:numberFieldOptions="{readonly: true, validate: 'cardCode'}"
		:buttonsText="['Отмена', 'Заблокировать', true]"
		@send="(data) => blockCard(data)"
		@close="editModalWindow()"></modal-window>
	<modal-window 
		v-if="modalResult"
		:data="{
			first: {},
			second: {},
			component: {},
		}"
		:resultField="true"
		:resultFieldOptions="resultOptions"
		@close="showResultModal()"></modal-window>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Loading from '../../Loading.vue';
import ModalWindow from '../../ModalWindow.vue';
import ErrorMessage from '../../ErrorMessage.vue';

export default {
    name: 'Cards',
	components: {
		Loading,
		ModalWindow,
		ErrorMessage
	},
    data() {
		return{
            cards: [],
			invalidCode: false,
            modalAdd: false,
            modalEdit: false,
            searchReadOnly: false,
			page: 1,
            pagesAmountCount: 0,
            search: '',
            isBlocked: false,
            filteredList: [],
			isLoading: false,
			collectedData: {
				first: {},
				second: {},
				component: 'cards'
			},
			modalResult: false,
			resultOptions: {},
			error: false
        }
    },
    methods: {
		async getData(card='') {
			try{
                this.searchReadOnly = true
				this.error = false;
				this.isLoading = true;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/card-list',
                    data: {
                        page: this.page - 1,
                        limit: 50,
                        card: card
                    }
				}
				await this.$store.dispatch('sendData', payload).then((res) => {
					this.cards = res.data.data;
                    this.pagesAmountCount = res.data.pagesCount
				});
				this.isLoading = false;
                this.searchReadOnly = false
			}catch(err) {
				this.isLoading = false;
				this.error = true;
			}
		},
		async addCard(data) {
			try{
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/activate-card',
					data: data
				}
				// await this.$store.dispatch('sendData', payload);
				const response = await axios.post(payload.url, payload.data, {
					headers: {
						authorization: `Bearer ${window.localStorage.getItem('access_token')}`
					}
				})
				this.invalidCode = false;
				this.modalAdd = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Карта была добавлена',
					result: true
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)
				await this.getData();
			}catch(err) {
				this.isLoading = false;
				if(err.response) {
					switch(err.response.data.message) {
						case 'NOT_FOUND':
							this.modalAdd = false;
							this.modalResult = true
							this.resultOptions = {
								message: 'Карты с таким кодом не существует',
								result: false
							}
							setTimeout(() => {
								this.modalResult = false
							}, 2000)
							break;
						default: 
							this.modalAdd = false;
							this.modalResult = true
							this.resultOptions = {
								message: 'Ошибка во время активации',
								result: false
							}
							setTimeout(() => {
								this.modalResult = false
							}, 2000)
							break;
					}
					return 
				}
				this.modalAdd = false;
				this.modalResult = true
				this.resultOptions = {
					message: 'Ошибка во время активации. Пожалуйста проверьте подключение к интернету',
					result: false
				}
				setTimeout(() => {
					this.modalResult = false
				}, 2000)	
			}

		},
		async blockCard(data) {
			try{
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/update-card',
					data: data
				}
				await this.$store.dispatch('sendData', payload);
				this.modalEdit = false;
				await this.getData();
			}catch(err) {
				throw new Error(err);
			}
		},
		collectData(data) {
			this.collectedData.first = data;
		},
        addModalWindow() {
            this.modalAdd = !this.modalAdd;
            const data = {
					code: '',
				}
			this.collectData(data);
        },
        editModalWindow(idx=0, id=0, edit=false) {
            this.modalEdit = !this.modalEdit;
            if(edit) {
				const data = {
					code: this.searchCard[idx].code,
					editItemId: id
				}
				this.collectData(data);
			}
        },
        selectActiveStatus() {
            this.isBlocked = !this.isBlocked;
        },
		showResultModal() {
			this.modalResult = !this.modalResult;
		},
		prev() {
            if(this.page > 1) {
                this.page--
            } 
        },
        next() {
            if(this.page < this.pagesAmountCount) {
                this.page++
            } 
        },
        toStart() {
            this.page = 1;
        },
        toEnd() {
            this.page = this.pagesAmountCount;
        },
    },
	watch: {
		page() {
			if(this.page !== '' && Number(this.page) > 0 && !(Number(this.page) > Number(this.pagesAmountCount)) && !this.isFiltering) {
				this.getData();
			}
		},
        search() {
            if(this.search.length > 7) {
                this.search = this.search.slice(0, 8)
                this.getData(this.search)
            }
        }
	},
	mounted() {
		this.getData();
	}
}
</script>

<style scoped>

    .header{
        width:100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;
    }

    .search_wrp{
        position: relative;
        margin-right: 10px;
    }

    .search_wrp input{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 400;
        color: #80868B;
        padding: 10px 40px 10px 15px;
        border-radius: 0.5rem;
        border: none;
        background-color: #f1f1f1;
    }

    .search_wrp svg{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .add_wrp{
        display: flex;
        justify-content: flex-end;
        width:100%;
        margin-bottom: 30px;
    }

    .add{
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        padding: 10px;
        background-color: transparent;
        border: none;
    }
    
    .add span{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 700;
        color: #0071BC;
        margin-right: 10px;
    }

    .table{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #fff;
        padding: 10px 15px;
        overflow-y: auto;
    }

    .row_titles{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
    }

    .row_title_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 700;
        color: rgba(0,113,188,0.5);
        padding: 7px;
        width: calc(100%/5);
        padding: 5px;
    } 

    .row_title_item.active{
        display: flex;
        justify-content: flex-start;
    }

    .row_title_item.id{
    display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .row_title_item.id svg{
        margin-right: 10px;
        cursor: pointer;
    }

    .row_title_item.action{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .row:not(:last-of-type) {
        margin-bottom: 15px;
    }

    .row_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 400;
        color: #707683;
        padding: 5px;
        width: calc(100%/5);
    }

    .row_item.active{
        display: flex;
        justify-content: flex-start;
    }

    .row_item.active span{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 400;
        color: #707683;
    }

    .row_item.active svg{
        margin-right: 7px;
    }

    .row_item.id{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 600;
        color: #323C47;
        padding: 7px;
    }

    .row_item.action{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    .row_item.id{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .checkbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        border: solid 2px #D5D5D5;
        border-radius: 0.2rem;
        margin-right: 10px;
        cursor: pointer;
    }

    .checkbox svg{
        margin: 0;
    }

    .checkbox.selected{
        border: solid 2px #90C1E1;
        background: #0071BC;
    }

	.pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
    }

    .count{
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #707683;
        padding: 5px 15px;
        cursor: pointer;
    }

    .current{
        border: solid 1px #707683;
        color: #0071BC;
    }

    .btn{
        background-color: transparent;
        border: none;
        padding: 5px 15px;
        cursor: pointer;
    }

    .prev_btn{
        transform: rotate(180deg);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }

    .edit_page{
        width: 80px;
        padding: 5px 15px;
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #0071BC;
        text-align: center;
        padding: 5px 15px;
        border: solid 1px #707683;
        overflow: visible;
    }

    .modal_back{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal_window{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        background-color: #fff;
        min-width: 25%;
        border-radius: .5rem;
    }

    .modal_title{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 18px;
        color: #000;
        text-align: center;
        margin-bottom: 30px;
    }

    .modal_form_row.first{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 15px;
    }

    .modal_form_row.first input{
        padding: 10px 25px;
        border: none;
        background-color: #DADCE0;
        border-radius: .5rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
    }

    .label{
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
        margin-bottom: 10px;
        user-select: none;
    }

    .modal_form_wrp{
        display: flex;
        align-items: flex-start;
        width: 100%;
    }

    .modal_form_wrp span{
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 12px;
        color: #707683;
        user-select: none;
    }

    .icon{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px 15px;
    }

    .modal_form_row.second{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 30px;
    }

    .modal_form_row_inner.second{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal_form_row.second input{
        width: 100%;
        padding: 10px 25px;
        border: none;
        background-color: #DADCE0;
        border-radius: .5rem;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        color: #000;
    }

    #cost{
        width: 100%;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 600;
        color: #000;
        padding: 10px 40px 10px 25px;
        border-radius: .5rem;
        border: none;
        background: #DADCE0;
    }

    .modal_form_row.fourth{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal_form_row.fourth button{
        width: 48%;
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 12px;
        color: #80868B;
        background-color: #DADCE0;
        padding: 15px 0;
        border: none;
        border-radius: .5rem;
        transition: .2s linear;
    }

    .modal_form_row.fourth button:hover{
        color: #fff;
        background-color: #0071BC;
    }
</style>