<template>
	<teleport to="body">
		<div class="modal_background" @mousedown.self="closeModalWindow">
			<div class="modal_window">
				<loading v-if="isLoading"></loading>
				<h3 class="modal_title" v-if="title.length > 0">{{ title }}</h3>
				<div class="modal_item" v-if="loginField">
					<label for="login">
						Логин
						<p  v-if="login.empty">*</p>
					</label>
					<input 
						type="text" 
						id="login" 
						placeholder="Логин" 
						autocomplete="off"
						v-model.trim="login.content" 
						@input="validateForms([login])">
				</div>
				<div class="modal_item" v-if="nameField">
					<label for="name">
						{{ nameFieldType }}
						<p v-if="name.empty">*</p>
					</label>
					<input 
						type="text" 
						id="name" 
						:placeholder="nameFieldType" 
						autocomplete="off"
						v-model.trim="name.content"
						@input="validateForms([name])">
				</div>
				<div class="modal_item" v-if="passwordField">
					<label for="password">
						Пароль
						<p v-if="password.empty && !password.invalid && !data.first.edit">*</p>
						<p v-else-if="password.invalid && !password.empty">&#10006;</p>
						<p class="tick" v-else-if="!password.invalid && !password.empty">&#10003;</p>
					</label>
					<div class="password_wrp">
						<input
							:type="password.type ? 'password' : 'text'"
							id="password"
							placeholder="Пароль"
							autocomplete="off"
							v-model.trim="password.content"
							@input="validateForms([password], 'password')">
						<img 
							v-if="!password.visible" 
							@click.prevent="showPassword"
							src="@/assets/add-options/close-eye.svg" 
							alt="Show">
						<img 
							v-if="password.visible" 
							@click.prevent="showPassword"
							src="@/assets/add-options/open-eye.svg" 
							alt="Hide">
					</div>
				</div>
				<div class="modal_item" v-if="numberField">
					<label for="number">
						{{ numberFieldType }}
						<p v-if="number.empty && !number.invalid">*</p>
						<p v-else-if="!number.empty && number.invalid">&#10006;</p>
					</label>
					<input 
						type="number" 
						id="number" 
						:placeholder="numberFieldType" 
						autocomplete="off"
						:readonly="numberFieldOptions.readonly"
						v-model.trim.number="number.content"
						@input="validateForms([number], numberFieldOptions.validate)">
				</div>
				<div class="modal_item" v-if="selectField">
					<label>Кассир</label>
					<select 
						v-model="select.first" 
						v-if="data.second[0].fullName === 'Список пуст' || 'Список кассиров недоступен'">
						<option
							v-for="user in data.second" 
							:key="user.id"
							:value="user.id">{{ user.fullName }}</option>
					</select>
					<select 
						v-model="select.first" 
						v-else>
						<option
							v-for="user in data.second" 
							:key="user.id"
							:value="user.id">{{ user.fullName }}</option>
					</select>
				</div>
				<div class="modal_item checkbox" v-if="checkboxField">
					<div class="checkbox_field_wrp">
						<input 
							type="checkbox" 
							v-model="checkbox.first" 
							@change="validateForms([checkbox], 'checkbox')"
							:value="true">
						<span>{{ checkboxFieldLabels[0] }}</span>
					</div>
				</div>
				<div class="modal_item" v-if="dateField">
					<label>Дата</label>
					<div class="date_wrp wrp">
						<date-picker 
							color="blue" 
							v-model="date.from" 
							:masks="{ inputDateTime24hr: ['L HH:mm', 'YYYY-MM-DD HH:mm', 'YYYY/MM/DD HH:mm'] }"
							mode="dateTime" 
							:is24hr="true"
							class="date item">
							<template v-slot="{ inputValue, inputEvents }">
								<label>С</label>
								<input
								:value="inputValue"
								v-on="inputEvents"
								readonly
								/>
							</template>
						</date-picker>
						<date-picker 
							color="blue" 
							v-model="date.to" 
							:masks="{ inputDateTime24hr: ['L HH:mm', 'YYYY-MM-DD HH:mm', 'YYYY/MM/DD HH:mm'] }"
							mode="dateTime" 
							:is24hr="true"
							class="date item" 
							:min-date="date.from">
							<template v-slot="{ inputValue, inputEvents }">
								<label>До</label>
								<input
									:value="inputValue"
									v-on="inputEvents"
									readonly
								/>
							</template>
						</date-picker>
					</div>
				</div>
				<div class="modal_item" v-if="mixField === 'select-checkbox'">
					<label>Профиль</label>
					<div class="mix_wrp wrp">
						<select 
							class="item" 
							v-model="select.first">
							<option :value="1">Админ</option>
							<option :value="2">Кассир</option>
						</select>
						<div class="checkbox_wrp wrp">
							<input 
								type="checkbox" 
								:value="true" 
								class="item" 
								v-model="checkbox.first">
							<span>{{ checkboxFieldLabels[0] }}</span>
						</div>
					</div>
				</div>
				<div class="modal_item" v-if="mixField === 'checkbox-checkbox'">
					<label>
						{{ checkboxFieldOptions.label }}
						<p v-if="!(checkbox.first || checkbox.second) && checkboxFieldOptions.validate === 'checkbox-checkbox'">*</p>
					</label>
					<div class="checkbox_mix_wrp wrp">
						<div class="checkbox_wrp wrp">
							<input 
								type="checkbox" 
								class="item" 
								v-model="checkbox.first"
								@change="validateForms([checkbox], 'checkbox-checkbox', 'first')">
							<span>{{ checkboxFieldLabels[0] }}</span>
						</div>
						<div class="checkbox_wrp wrp">
							<input 
								type="checkbox" 
								class="item" 
								v-model="checkbox.second"
								@change="validateForms([checkbox], 'checkbox-checkbox', 'second')">
							<span>{{ checkboxFieldLabels[1] }}</span>
						</div>
					</div>
				</div>
				<div class="modal_item" v-if="mixField === 'checkbox-checkbox-checkbox'">
					<label>
						Способ оплаты
						<p v-if="!(checkbox.first || checkbox.second || checkbox.third) && data.component === 'cards'">*</p>
					</label>
					<div class="checkbox_mix_wrp wrp triple">
						<div class="checkbox_triple_wrp">
							<input 
								type="checkbox" 
								class="item" 
								v-model="checkbox.first"
								@change="validateForms([checkbox], checkboxFieldOptions.validate, 'first')">
							<span>{{ checkboxFieldLabels[0] }}</span>
						</div>
						<div class="checkbox_triple_wrp">
							<input 
								type="checkbox" 
								class="item" 
								v-model="checkbox.second"
								@change="validateForms([checkbox], checkboxFieldOptions.validate, 'second')">
							<span>{{ checkboxFieldLabels[1] }}</span>
						</div>
						<div class="checkbox_triple_wrp">
							<input 
								type="checkbox" 
								class="item" 
								v-model="checkbox.third"
								@change="validateForms([checkbox], checkboxFieldOptions.validate, 'third')">
							<span>{{ checkboxFieldLabels[2] }}</span>
						</div>
					</div>
				</div>
				<div class="modal_item import" v-if="importField">
					<label class="custom_label_upload">
						Выберите файл...
						<input 
							type="file" 
							class="custom_file_upload" 
							id="file" 
							ref="file" 
							accept=".xlsx"
							v-on:change="handleFileUpload()"/>
						<button 
							:class="{custom_btn_upload: true, active: !file.invalid}" 
							@click.prevent="!file.invalid ? sendFile() : false">Отправить</button>
					</label>
					<label>Выбран файл:&nbsp;<b>{{ file.name }}</b></label>
					<label>Размер:	&nbsp;<b>{{ file.size }}kb</b></label>
				</div>
				<div class="modal_item select_mix" v-if="mixField === 'select-select'">
					<div class="select_mix_wrp wrp">
						<div class="select_mix_inner_wrp">
							<label>Роль</label>
							<select 
								class="item" 
								v-model="select.first">
								<option :value="1">Аттракцион</option>
								<option :value="2">Админ</option>
							</select>
						</div>
						<div class="select_mix_inner_wrp" v-if="select.first === 1">
							<label>Аттракцион</label>
							<select 
								class="item" 
								v-model="select.second" 
								v-if="data.second[0].name === 'Список пуст' || 'Список Аттракционов недоступен'">
								<option 
									v-for="attraction in data.second"
									:key="attraction.id"
									:value="attraction.id">{{ attraction.name }}</option>
							</select>
							<select 
								class="item" 
								v-model="select.second" 
								v-else>
								<option 
									v-for="attraction in data.second"
									:key="attraction.id"
									:value="attraction.id"
									disabled>{{ attraction.name }}</option>
							</select>
						</div>

					</div>
				</div>
				<div class="modal_item result" v-if="resultField">
					<div class="result_wrp" v-if="resultFieldOptions.result" >
						<img src="@/assets/replenishment/tick.svg" alt="Img">
						<h1>{{ resultFieldOptions.message }}</h1>
					</div>
					<div class="result_wrp" v-else-if="!resultFieldOptions.result">
						<img src="@/assets/replenishment/cross.svg" alt="Img">
						<h1>{{ resultFieldOptions.message }}</h1>
					</div>
				</div>
				<div class="modal_item btns" v-if="buttonsText[2]">
					<button 
						class="modal_btn item" 
						@click.prevent="buttonsText[0] === 'Удалить' ? firstModalBtn('delete') : firstModalBtn('close')">
						{{ buttonsText[0] }}</button>
					<button 
						:class="{
							modal_btn: true, 
							item: true, 
							active: sendToValidate(buttonsText[1] === 'Изменить' || buttonsText[1] === 'Заблокировать' || buttonsText[1] === 'Применить' ? 'edit' : 'add')}" 
						@click.prevent="buttonsText[1] === 'Изменить' || buttonsText[1] === 'Заблокировать' || buttonsText[1] === 'Применить' ? secondModalBtn('edit', data, true) : secondModalBtn('add', data)">
						{{ buttonsText[1] }}</button>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
/* eslint-disable no-unused-vars */
import Loading from './Loading.vue';
import { DatePicker } from 'v-calendar';

export default {
	name: 'ModalWindow',
	props: {
		data: {
			required: true,
			type: Object
		},
		title: {
			required: false,
			type: String,
			default: ''
		},
		loginField: {
			required: false,
			type: Boolean,
			default: false
		},
		nameField: {
			required: false,
			type: Boolean,
			default: false
		},
		nameFieldType: {
			required: false,
			type: String,
			default: 'ФИО'
		},
		numberField: {
			required: false,
			type: Boolean,
			default: false,
		},
		numberFieldType: {
			required: false,
			type: String,
			default: 'Стоимость'
		},
		numberFieldOptions: {
			required: false,
			type: Object,
			default() {
				return {
					readonly: false,
					validate: 'number',
					require: true,
				}
			}
		},
		passwordField: {
			required: false,
			type: Boolean,
			default: false,
		},
		requirePassword: {
			required: false,
			type: Boolean,
			default: false,
		},
		selectField: {
			required: false,
			type: Boolean,
			default: false,
		},
		importField: {
			required: false,
			type: Boolean,
			default: false
		},
		checkboxField: {
			required: false,
			type: Boolean,
			default: false,
		},
		checkboxFieldLabels: {
			required: false,
			type: Array,
			default() {
				return ['', '']
			}
		},
		checkboxFieldOptions: {
			required: false,
			type: Object,
			default() {
				return {
					label: '',
					validate: 'checkbox-checkbox-checkbox'
				}
			}
		},
		dateField: {
			required: false,
			type: Boolean,
			default: false,
		},
		mixField: {
			require: false,
			type: String,
			default: ''
		},
		resultField: {
			require: false,
			type: Boolean,
			default: false
		},
		resultFieldOptions: {
			required: false,
			type: Object,
			default() {
				return {
					message: 'Сообщение',
					result: true
				}
			}
		},
		buttonsText: {
			require: false,
			type: Array,
			default() {
				return ['Отмена', 'Добавить']
			}
		},
		errorMessage: {
			require: false,
			type: String,
			default: 'Ошибка'
		}
	},
	components: {
		Loading,
		DatePicker,
		// vSelect,
	},
	emits: ['send', 'close', 'delete'],
	data() {
		return{
			isLoading: false,
			login: {
				content: '',
				empty: true,
				invalid: false
			},
			name: {
				content: '',
				empty: true
			},
			password: {
				content: '',
				empty: true,
				invalid: false,
				type: true,
				visible: false
			},
			number: {
				content: '',
				empty: true,
				invalid: false
			},
			file: {
				data: '',
				name: '',
				size: '',
				invalid: true,
			},
			select: {
				first: null,
				second: null,
			},
			checkbox: {
				first: false,
				second: false,
				third: false,
				invalid: false,
			},
			date: {
				from: new Date(),
				to: new Date(),
			},
			modelConfig: {
				timeAdjust: '24:00:00',
				is24hr: true
			},
			editItemId: null,
		}
	},
	methods: {
		// ========================== Devices ========================== //
		devicesAdd() {
			this.sendData({
				login: this.login.content,
				name: this.name.content,
				password: this.password.content,
				role: this.select.first,
				attId: this.select.first === 2 ? null : this.select.second,
			}, 'add')
		},
		devicesEdit(collectedData, send) {
			this.editItemId = collectedData.first.editItemId;
			if(!send) {
				this.login.content = collectedData.first.login;
				this.name.content = collectedData.first.name;
				this.password.content = collectedData.first.password;
				this.select.first = Number(collectedData.first.role);
				this.select.second = collectedData.first.attraction
			}else if(send){
				this.sendData({
					login: this.login.content,
					name: this.name.content,
					password: this.password.content,
					role: this.select.first,
					attId: this.select.first === 2 ? null : this.select.second,
					id: this.editItemId
				}, 'edit')
			}
		},

		// ========================== Users ========================== //
		usersAdd() {
			this.sendData({
				username: this.login.content,
				fullname: this.name.content,
				password: this.password.content,
				role: this.select.first,
				status: this.checkbox.first ? 1 : 0,
			}, 'add')
		},
		usersEdit(collectedData, send) {
			this.editItemId = collectedData.first.editItemId;
			if(!send) {
				this.login.content = collectedData.first.login;
				this.name.content = collectedData.first.name;
				this.password.content = collectedData.first.password;
				this.select.first = collectedData.first.profile;
				this.checkbox.first = collectedData.first.is_active
			}else if(send){
				this.sendData({
					username: this.login.content,
					fullname: this.name.content,
					password: this.password.content,
					role: this.select.first,
					status: this.checkbox.first ? 1 : 0,
					id: this.editItemId
				}, 'edit')
			}
		},

		// ========================== Discounts ========================== //
		discountsAdd() {
			this.sendData({
				name: this.name.content,
				quantity: this.number.content,
				type: this.checkbox.first ? 1 : 2,
				id: this.editItemId
			}, 'add')
		},
		discountsEdit(collectedData, send) {
			this.editItemId = collectedData.first.editItemId;
			if(!send) {
				this.name.content = collectedData.first.name;
				this.number.content = collectedData.first.quantity;
				if(collectedData.first.type === "1") {
					this.checkbox.first = true;
				}else if(collectedData.first.type === "2"){
					this.checkbox.second = true;
				}else{
					this.checkbox.first = false;
					this.checkbox.second = false;
				}
			}else if(send){
				this.sendData({
					name: this.name.content,
					quantity: this.number.content,
					type: this.checkbox.first ? 1 : 2,
					id: this.editItemId
				}, 'edit')
			}
		},

		// ========================== Cards ========================== //
		cardsAdd() {
			let paymentMethod = '1';
			if(this.checkbox.first) {
				paymentMethod = '1';
			}else if(this.checkbox.second) {
				paymentMethod = '2';
			}else if(this.checkbox.third) {
				paymentMethod = '3';
			}
			this.sendData({
				card_code: this.number.content,
				payment_method: paymentMethod
			}, 'add')
		},
		cardsEdit(collectedData, send) {
			this.editItemId = collectedData.first.editItemId;
			if(!send) {
				this.number.content = collectedData.first.code;
			}else if(send){
				this.sendData({
					id: this.editItemId,
					status: 0
				}, 'edit')
			}
		},

		// ========================== Price List ========================== //
		priceListAdd() {
			this.sendData({
				name: this.name.content,
				price: this.number.content,
			}, 'add')
		},
		priceListEdit(collectedData, send) {	
			this.editItemId = collectedData.first.editItemId;
			if(!send) {
				this.name.content = collectedData.first.attName;
				this.number.content = collectedData.first.cost;
			}else if(send){
				this.sendData({
					name: this.name.content,
					price: this.number.content,
					id: this.editItemId
				}, 'edit')
			}
		},
		handleFileUpload() {
			this.file.data = this.$refs.file.files[0];
			this.file.name = this.file.data.name;
			this.file.size = this.file.data.size;
			this.file.name.split('.').pop() === 'xlsx' ? this.file.invalid = false : this.file.invalid = true
		},
		sendFile() {
			this.$emit('send', this.file.data);
			this.isLoading = true;
		},

		// ========================== Replenishment ========================== //
		paymentsFilter(collectedData, send=false) {
			if(!send) {
				this.select.first = collectedData.second[0].id;
				this.checkbox.third = false;
				return 
			}
			let paymentMethod = '1';
			if(this.checkbox.first) {
				paymentMethod = '1';
			}else if(this.checkbox.second) {
				paymentMethod = '2';
			}else if(this.checkbox.third) {
				paymentMethod = '3';
			}else{
				paymentMethod = '';
			}
			if(send) {
				this.sendData({
					userId: this.select.first === 'all' ? '' : this.select.first,
					paymentMethod: paymentMethod,
					userFrom: this.formatDate(this.date.from),
					userTo: this.formatDate(this.date.to),
				}, 'edit')
			}
		},
		formatDate(date) {
			let d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear(),
				hours = d.getHours(),
				minutes = d.getMinutes();

			if (month.length < 2) 
				month = '0' + month;
			if (day.length < 2) 
				day = '0' + day;
			if (year === 1970) {
				return ''
			}
			if (minutes < 10) {
				minutes = '0' + minutes
			}
			const DMY = [day, month, year].join('.')
			const time = [hours, minutes].join(':')
			return DMY + ' ' + time
		},

		// ========================== Cashiers ========================== //
		cashiersFilter(collectData, send=false) {
			let paymentMethod = '1';
			if(this.checkbox.first) {
				paymentMethod = '1';
			}else if(this.checkbox.second) {
				paymentMethod = '2';
			}else if(this.checkbox.third) {
				paymentMethod = '3';
			}else{
				paymentMethod = '';
			}
			if(send) {
				this.sendData({
					card_code: this.number.content,
					dateFrom: this.formatDate(this.date.from),
					dateTo: this.formatDate(this.date.to),
					paymentMethod: paymentMethod,
				}, 'edit')
			}
		},


		// ========================== Replenishment ========================== //
		replenishmentFilter(send=false) {
			let paymentMethod = '1';
			if(this.checkbox.first) {
				paymentMethod = '1';
			}else if(this.checkbox.second) {
				paymentMethod = '2';
			}else if(this.checkbox.third) {
				paymentMethod = '3';
			}else{
				paymentMethod = '';
			}
			if(send) {
				this.sendData({
					dateFrom: this.formatDate(this.date.from),
					dateTo: this.formatDate(this.date.to),
					paymentMethod: paymentMethod,
				}, 'edit')
			}
		},

		// ========================== WriteOff ========================== //
		writeOffFilter(send=false) {
			if(send) {
				this.sendData({
					dateFrom: this.formatDate(this.date.from),
					dateTo: this.formatDate(this.date.to),
				}, 'edit')
			}
		},

		// ========================== Common ========================== //
		validateForms(forms, validate='validate', target="first") {
			for(let i of forms) {
				if(Object.prototype.hasOwnProperty.call(i, 'content')) {
					if(String(i.content).length > 0) {
						i.empty = false;
						if(validate === 'password') {
							i.content.length >= 1 && i.content.length < 6 ? i.invalid = true : i.invalid = false;
						}
						if(validate === 'number') {
							i.content.startsWith(0 || '0') ? i.invalid = true : i.invalid = false;
						}
						if(validate === 'cardCode') {
							if(i.content.length > 8) {
								i.content = i.content.slice(0, 8)
							}
						}
					}else if(i.content.length <= 0){
						i.empty = true;
						i.invalid = false;
					}
				}else if(validate === 'checkbox-checkbox') {
					if(target === "first") {
						i.second = false;
					}else if(target === 'second') {
						i.first = false;
					}
				}else if(validate === 'checkbox-checkbox-checkbox') {
					if(target === 'first') {
						i.second = false;
						i.third = false;
					}else if(target === 'second') {
						i.first = false;
						i.third = false;
					}else if(target === 'third') {
						i.first = false;
						i.second = false;
					}
				}
			}
			if(!this.numberFieldOptions.require) {
				this.number.empty = false
			}
		},
		sendToValidate(actionType="actionType") {
			switch(actionType) {
				case 'add':
					if(this.data.component === 'devices')  {
						return this.checkValidity([this.login, this.name, this.password]);
					}else if(this.data.component === 'users') {
						return this.checkValidity([this.login, this.name, this.password]);
					}else if(this.data.component === 'discounts') {
						return this.checkValidity([this.name, this.number, this.checkbox]);
					}else if(this.data.component === 'cards') {
						return this.checkValidity([this.number, this.checkbox]);
					}else if(this.data.component === 'priceList') {
						return this.checkValidity([this.number, this.name]);
					}
					break;
				case 'edit':
					if(this.data.component === 'devices') {
						return this.checkValidity([this.login, this.name]);
					}else if(this.data.component === 'users') {
						return this.checkValidity([this.login, this.name, this.password], 'edit');
					}else if(this.data.component === 'discounts') {
						return this.checkValidity([this.name, this.number, this.checkbox]);
					}else if(this.data.component === 'cards') {
						return this.checkValidity([this.number]);
					}else if(this.data.component === 'priceList') {
						return this.checkValidity([this.number, this.name]);
					}else if(this.data.component === 'payments') {
						return this.checkValidity([]);
					}else if(this.data.component === 'cashiers') {
						return this.checkValidity([]);
					}else if(this.data.component === 'replenishment') {
						return this.checkValidity([]);
					}else if(this.data.component === 'write-off') {
						return this.checkValidity([]);
					}
					break;
			}
		},
		checkValidity(forms=[], actionType="actionType") {
			let formsAmount = 0;
			for(let i of forms) {
				if(Object.prototype.hasOwnProperty.call(i, 'visible') && actionType === 'edit') {
					if(!i.invalid) {
						formsAmount++;
					}
				}
				if(Object.prototype.hasOwnProperty.call(i, 'empty')) {
					if(!i.empty && !i.invalid ) {
						formsAmount++;
					}
				}
				if(i.first || i.second || i.third) {
					formsAmount++
				}
			}
			return formsAmount >= forms.length ? true : false;
		}, 
		firstModalBtn(actionType="actionType") {
			switch(actionType) {
				case 'delete':
					this.deleteItem();
					break;
				case 'close':
					this.closeModalWindow();
					break;
			}
		},
		secondModalBtn(actionType="actionType", data={}, send=false) {
			switch(actionType) {
				case 'add':
					if(this.data.component === 'devices') {
						return this.devicesAdd(data)
					}else if(this.data.component === 'users') {
						return this.usersAdd(data);
					}else if(this.data.component === 'discounts') {
						return this.discountsAdd(data)
					}else if(this.data.component === 'cards') {
						return this.cardsAdd(data)
					}else if(this.data.component === 'priceList') {
						return this.priceListAdd(data);
					}
					break;
				case 'edit':
					if(this.data.component === 'devices') {
						return this.devicesEdit(data, send)
					}else if(this.data.component === 'users') {
						return this.usersEdit(data, send)
					}else if(this.data.component === 'discounts') {
						return this.discountsEdit(data, send)
					}else if(this.data.component === 'cards') {
						return this.cardsEdit(data, send)
					}else if(this.data.component === 'priceList') {
						return this.priceListEdit(data, send);
					}else if(this.data.component === 'payments') {
						return this.paymentsFilter(data, send);
					}else if(this.data.component === 'cashiers') {
						return this.cashiersFilter(data, send);
					}else if(this.data.component === 'replenishment') {
						return this.replenishmentFilter(data, send);
					}else if(this.data.component === 'write-off') {
						return this.writeOffFilter(data, send);
					}
					break;
			}
		},
		async sendData(data, actionType="actionType") {
			if(this.sendToValidate(actionType)) {
				this.$emit('send', data);
				this.isLoading = true;
			}
		},
		deleteItem() {
			this.$emit('delete');
			this.isLoading = true;
		},
		closeModalWindow() {
			this.$emit('close');
		},
		showPassword() {
			this.password.type = !this.password.type;
			this.password.visible = !this.password.visible;
		}
	},
	mounted() {
		switch(this.data.component) {
			case 'devices':
				this.devicesEdit(this.data, false);
				break;
			case 'users':
				this.usersEdit(this.data, false);
				break;
			case 'discounts':
				this.discountsAdd();
				this.discountsEdit(this.data, false);
				break;
			case 'cards':
				this.cardsEdit(this.data, false);
				this.cardsAdd();
				break;
			case 'priceList':
				this.priceListEdit(this.data, false);
				break;
			case 'payments':
				this.paymentsFilter(this.data, false);
		}
		this.validateForms([this.login, this.password, this.name, this.number])
	},

}
</script>

<style scoped>
	.modal_background{
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2000;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.modal_window{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 40%;
		max-height: 90%;
		padding: 20px;
		border-radius: 1rem;
		background-color: #fff;
		overflow: auto;
	}

	label, p, h3{
		user-select: none;
	}

	.modal_title{
		font-family: "Open Sans";
		font-weight: 700;
		font-size: 18px;
		color: #000;
		width: 100%;
		text-align: center;
		margin-bottom: 30px;
	}

	.modal_item{
		display: flex;
		flex-direction: column;
		justify-content: flex-start	;
		align-items: center;
		width: 100%;
		margin-bottom: 15px;
	}

	.modal_item.import label{
		width: 80%;
	}

	.modal_item.import b{
		font-family: "Open Sans";
		font-weight: 700;
		font-size: 14px;
		color: #0071BC;
	}

	p{
		font-family: "Open Sans";
		font-weight: 600;
		font-size: 12px;
		color: #FF0000;
		text-align: start;
		margin-left: 5px;
	}

	span{
		font-family: "Open Sans";
		font-weight: 400;
		font-size: 12px;
		color: #000;
	}

	.modal_item:last-of-type{
		margin-top: 15px;
	}

	.modal_item.checkbox{
		flex-direction: column;
		justify-content: flex-start;
	}

	.checkbox_field_wrp{
		width: 100%;
	}
	
	.modal_item.checkbox input[type=checkbox]{
		width: unset;
		margin-right: 10px;
	}

	.modal_item.checkbox label{
		margin-bottom: 0;
	}

	.modal_item.result{
		margin: 0;
		user-select: none;
	}

	.checkbox_mix_wrp.triple{
		display: flex;
		flex-wrap: wrap;
	}

	.checkbox_triple_wrp{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex: 0 0 calc(100%/3);
		max-width: calc(100%/3);
	}

	.checkbox_triple_wrp input{
		width: unset;
		margin-right: 10px;
	}

	.wrp{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.result_wrp{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.result_wrp img{
		margin-bottom: 10px;
		width: 100px;
	}

	.result_wrp h1{
		font-family: "Open Sans";
		font-size: 25px;
		font-weight: 700;
		color: #000;
	}
	
	.item{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 48%;
	}

	input[type=text], [type=password], [type=number], select{
		width: 100%;
		padding: 8px 10px;
		border: none;
		border-radius: .5rem;
		background-color: #DADCE0;
		font-family: "Open Sans";
		font-weight: 600;
		font-size: 14px;
		color: #000;
	}

	input[type=password] {
		padding: 8px 40px 8px 10px;
	}

	.tick{
		color: #0071BC;
	}

	.password_wrp{
		width: 100%;
		position: relative;
	}

	.password_wrp img{
		padding: 8px;
		position: absolute;
		top: 50%;
		right: 7px;
		transform: translateY(-50%);
		cursor: pointer;
		user-select: none;
	}

	.custom_file_upload{
		opacity: 0;
		position: absolute
	}

	.custom_label_upload{
		position: relative;
		width: 80%;
		padding: 15px 110px 15px 20px;
		background-color: #DADCE0;
		border-radius: .5rem;
	}

	.custom_btn_upload{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		padding: 0 15px;
		background-color: #828282;
		border: none;
		border-radius: .5rem;
		font-family: "Open Sans";
		font-size: 12px;
		font-weight: 600;
		color: #fff;
	}

	.custom_btn_upload.active{
		background-color: #0071BC;
	}

	label{
		display: flex;
		align-items: center;
		font-family: "Open Sans";
		font-weight: 600;
		font-size: 14px;
		color: #000;
		width: 100%;
		text-align: start;
		margin-bottom: 5px;
	}

	select{
		padding: 7px 20px 7px 10px;
	}

	.date input{
		width: 100%;
		padding: 7px 15px;
		border: none;
		border-radius: .5rem;
		background-color: #DADCE0;
		font-family: "Open Sans";
		font-weight: 600;
		font-size: 14px;
		color: #000;
	}

	.date label{
		width: unset;
		margin: 0 10px 0 0;
	}

	.checkbox_wrp{
		justify-content: start;
		width: 48%;
	}

	.checkbox_wrp label{
		width: unset;
		margin: 0;
	}

	.checkbox_wrp .item{
		width: unset;
		margin-right: 10px;
	}

	.select_mix{
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
	}

	.select_mix_inner_wrp{
		flex-direction: column;
		justify-content: flex-start;
		width: 48%;
	}

	.select_mix_inner_wrp select{
		width: 100%;
	}

	.btns{
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 0;
	}

	.modal_btn{
		display: flex;
		justify-content: center;
		width: 48%;
		padding: 10px 15px;
		border: none;
		border-radius: .5rem;
		background-color: #DADCE0;
		font-family: "Open Sans";
		font-weight: 700;
		font-size: 12px;
		color: #80868B;
		transition: .2s ease-out;
	}

	.modal_btn.active{
		background-color: #0071BC;
		color: #fff;
		cursor: pointer;
	}

	@media(max-width: 1100px) {
		.checkbox_mix_wrp.triple{
			flex-direction: column;
			align-items: flex-start;
		}

		.checkbox_triple_wrp{
			margin-bottom: 10px;
		}

	}
</style>