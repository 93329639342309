import {createRouter, createWebHistory} from 'vue-router';
import store from './store.js';
import Login from './components/Login.vue';
import NotFoundPage from './components/admin/NotFoundPage.vue';
import UsedAccountPage from './components/admin/UsedAccountPage.vue';
// Admin
import Admin from'./components/admin/Admin.vue';
import Devices from './components/admin/navigation/Devices.vue';
import Users from './components/admin/navigation/Users/Users.vue';
import Table from './components/admin/navigation/Users/Table.vue';
import Payments from './components/admin/navigation/Payments.vue';
import PriceList from './components/admin/navigation/PriceList.vue';
import AdminReports from './components/admin/navigation/Reports/Reports.vue';
import AdminReplenishment from './components/admin/navigation/Reports/Replenishment.vue';
import WriteOff from './components/admin/navigation/Reports/WriteOff.vue';
import Attractions from './components/admin/navigation/Reports/Attractions.vue';
import Cashiers from './components/admin/navigation/Reports/Cashiers.vue';
import CardHistory from './components/admin/navigation/Reports/CardHistory.vue';
import AddOptions from './components/admin/navigation/AddOptions/AddOptions.vue';
import Discounts from './components/admin/navigation/AddOptions/Discounts.vue';
import SendMessage from './components/admin/navigation/AddOptions/SendMessage.vue';
import BalanceMigrate from './components/admin/navigation/AddOptions/BalanceMigrate.vue';
import Cards from './components/admin/navigation/AddOptions/Cards.vue';
// Cashier
import Cashbox from './components/cashier/Cashbox.vue'
import CardId from './components/cashier/CardId.vue';
import CashierReports from './components/cashier/Reports.vue';
import CashierReplenishment from './components/cashier/Replenishment/Replenishment.vue';
import Sum from './components/cashier/Replenishment/Sum.vue';
import PayMethod from './components/cashier/Replenishment/PayMethod.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
		// Common
		{path: '/used-account', component: UsedAccountPage, meta: {role: window.localStorage.role}},
		{path: '/', redirect: '/login'},
        {path: '/login', name: 'login', component: Login, meta: {role: window.localStorage.role}},
		// Admin
        {path: '/admin', redirect: '/admin/devices', component:  Admin, meta: {role: '1'},
        children: [
            {path: 'devices', component: Devices},
            {path: 'users', component: Users, redirect: '/admin/users/table',
            children: [
                {path: 'table', component: Table },
            ]},
            {path: 'payments', component: Payments},
            {path: 'price-list', component: PriceList},
            {path: 'reports', component: AdminReports, redirect: '/admin/reports/replenishment',
            children: [
                {path: 'replenishment', component: AdminReplenishment}, 
				{path: 'write-off', component: WriteOff}, 
				{path: 'cashiers', component: Cashiers}, 
				{path: 'card-history', component: CardHistory}, 
				{path: 'attractions', component: Attractions}, 
            ]},
            {path: 'add-options', component: AddOptions, redirect: '/admin/add-options/discounts',
            children: [
				{path: 'discounts',  component: Discounts},
				{path: 'send-message',  component: SendMessage},
                {path: 'balance-migrate',  component: BalanceMigrate},
                {path: 'cards',  component: Cards},
            ]},
            ]},
		// Cashier
		{path: '/cashbox', component: Cashbox, redirect: '/cashbox/card-id', meta: {role: '2'},
		children: [
			{path: 'card-id', component: CardId},
			{path: 'reports', component: CashierReports},
			{path: 'replenishment', component: CashierReplenishment, redirect: '/cashbox/replenishment/sum',
				children: [
				{path: 'sum', component: Sum},
				{path: 'pay-method', component: PayMethod}
			]},
		]},
		// Not found page
		{path: '/:notFoundPage(.*)', component: NotFoundPage, meta: {role: window.localStorage.role}},
		
    ],
    linkActiveClass: 'active',
});

/* eslint-disable no-unused-vars */

router.beforeEach((to, from, next) => {
	if(!window.localStorage.access_token) {
		if(to.path !== '/login') {
			next('/')
		}else{
			next();
		}	
	}else{
		if(to.matched[0].meta.role === window.localStorage.role) {
			next()
		}else if(to.matched[0].meta.role !== window.localStorage.role){
			switch(window.localStorage.role) {
				case '2':
					next('/cashbox');
					break;
				case '1':
					next('/admin')
			}
		}else{
			next()
		}
	}
})

router.afterEach((to) => {
	if(to.fullPath !== '/login') {
		setTimeout(() => {
			store.commit('onFocus')
		}, 10)
	}
})

export default router;