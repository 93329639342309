<template>
	<error-message v-if="error"></error-message>
	<loading :expand="true" v-if="isLoading"></loading>
	<div class="filter_wrp" v-if="!error">
		<button class="filter" @keypress.prevent @click="filterModalWindow()">
			<span>Фильтр</span>
			<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M17.9963 1.5H1.49969V3.8775L7.05604 9.4425L7.49845 9.8775V16.5H10.4978V15H11.9975V16.5C11.9975 16.8978 11.8395 17.2794 11.5583 17.5607C11.277 17.842 10.8956 18 10.4978 18H7.49845C7.10071 18 6.71925 17.842 6.43801 17.5607C6.15676 17.2794 5.99876 16.8978 5.99876 16.5V10.5L0.442409 4.9425C0.302062 4.80296 0.190714 4.63701 0.114784 4.45423C0.0388545 4.27144 -0.000156336 4.07543 4.7085e-07 3.8775V1.5C4.7085e-07 1.10218 0.158003 0.720644 0.439249 0.43934C0.720496 0.158035 1.10195 0 1.49969 0H17.9963V1.5Z" fill="#0071BC"/>
				<path d="M20.7782 5.4675L18.5287 3.2175C18.459 3.1472 18.376 3.09141 18.2846 3.05333C18.1933 3.01526 18.0953 2.99565 17.9963 2.99565C17.8973 2.99565 17.7993 3.01526 17.7079 3.05333C17.6165 3.09141 17.5336 3.1472 17.4639 3.2175L10.4978 10.1925V13.5H13.8046L20.7782 6.5325C20.8485 6.46278 20.9043 6.37983 20.9423 6.28843C20.9804 6.19704 21 6.09901 21 6C21 5.90099 20.9804 5.80296 20.9423 5.71157C20.9043 5.62017 20.8485 5.53722 20.7782 5.4675ZM13.1898 12H11.9975V10.8075L15.7467 7.0575L16.939 8.25L13.1898 12ZM17.9963 7.1925L16.804 6L17.9963 4.8075L19.1885 6L17.9963 7.1925Z" fill="#0071BC"/>
			</svg>
		</button>
	</div>
  <div class="table" v-if="!error">
        <div class="table_inner">
            <div class="row_titles">
                <h3 class="row_title_item">
					Аттракцион
				</h3>
                <h3 class="row_title_item sort">
					Сыграно игр
				</h3>
                <h3 class="row_title_item sort">
					Потрачено очков
				</h3>
            </div>
            <div
                class="row"
                v-for="attraction in attractionsList"
                :key="attraction">
                <h3 class="row_item">{{ attraction.name }}</h3>
                <h3 class="row_item">{{ attraction.count }}</h3>
                <h3 class="row_item">{{ attraction.sum }}</h3>
            </div>
        </div>
        <div class="pagination">
            <span class="prev count" @click="toStart">1</span>
            <button class="prev_btn btn" @click="prev">
                <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.177677 7.79061C0.291166 7.92467 0.445244 8 0.605923 8C0.766602 8 0.920679 7.92467 1.03417 7.79061L3.82232 4.5047C4.05923 4.22551 4.05923 3.7745 3.82232 3.49531L1.03417 0.209397C0.797267 -0.0697987 0.414579 -0.0697988 0.177677 0.209397C-0.0592249 0.488591 -0.0592249 0.939599 0.177677 1.21879L2.53455 4.00358L0.177677 6.78122C-0.0592255 7.06041 -0.0531511 7.51858 0.177677 7.79061Z" fill="#707683"/>
                </svg>
            </button>
            <input type="number" v-model="page" class="edit_page">
            <button class="next_btn btn" @click="next">
                <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.177677 7.79061C0.291166 7.92467 0.445244 8 0.605923 8C0.766602 8 0.920679 7.92467 1.03417 7.79061L3.82232 4.5047C4.05923 4.22551 4.05923 3.7745 3.82232 3.49531L1.03417 0.209397C0.797267 -0.0697987 0.414579 -0.0697988 0.177677 0.209397C-0.0592249 0.488591 -0.0592249 0.939599 0.177677 1.21879L2.53455 4.00358L0.177677 6.78122C-0.0592255 7.06041 -0.0531511 7.51858 0.177677 7.79061Z" fill="#707683"/>
                </svg>
            </button>
            <span class="next count" @click="toEnd">{{ pagesAmountCount }}</span>
        </div>
    </div>

	<div class="result" v-if="!error">
		<span>Кол-во посещений: {{ visitAmount }}</span>
		<span>Итого: {{ sumResult }}</span>
	</div>

	<modal-window 
		v-if="modalFilter"
		:data="collectedData"
		:title="'Фильтр'"
		:dateField="true"
		:buttonsText="['Отменить', 'Применить', true]"
		@send="(data) => getData(data)"
		@close="filterModalWindow()"></modal-window>
</template>

<script>
import ErrorMessage from '../../ErrorMessage.vue';
import Loading from '../../Loading.vue';
import ModalWindow from '../../ModalWindow.vue';

export default {
	name: 'Attractions',
	components: {
		ModalWindow,
		ErrorMessage,
		Loading
	},
	data() {
		return{
			attractionsList: [],
			page: 1,
            pagesAmountCount: 0,
			modalFilter: false,
			collectedData: {
				first: {},
				second: {},
				component: 'write-off'
			},
			error: false,
			isLoading: false,
			dateReverse: false,
			sumReverse: false,
			sumResult: 0,
			visitAmount: 0
		}
	},
	methods: {
		async getData(data={}) {
			try{
				this.isLoading = true
				this.error = false;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/attraction-pagination-list',
					data: {
						page: this.page - 1,
						limit: 10,
						dateFrom: data.dateFrom,
						dateTo: data.dateTo
					}
				}
				const response = await this.$store.dispatch('sendData', payload)
				this.attractionsList = response.data.data
				this.pagesAmountCount = response.data.pagesCount
				await this.getResults(data.dateFrom, data.dateTo)
				this.isLoading = false
                this.modalFilter = false 
			}catch(err) {
				this.error = true;
				this.isLoading = false
                this.modalFilter = false 
			}
		},
		async getResults(dateFrom, dateTo) {
			try{
				this.error = false;
				const payload = {
					url: this.$store.state.apiUrl + 'v1/front/attraction-total-list',
					data: {
						dateFrom: dateFrom,
						dateTo: dateTo
					}
				}
				const response = await this.$store.dispatch('sendData', payload)
				this.sumResult = response.data.data[0].sum
				this.visitAmount = response.data.data[0].count
			}catch(err) {
				this.error = true;
			}
		},
		filterModalWindow() {
			this.modalFilter = !this.modalFilter
		},
		prev() {
            if(this.page > 1) {
                this.page--
            } 
        },
        next() {
            if(this.page < this.pagesAmountCount) {
                this.page++
            } 
        },
        toStart() {
            this.page = 1;
        },
        toEnd() {
            this.page = this.pagesAmountCount;
        },
	},
	watch: {
		page() {
			if(this.page !== '' && Number(this.page) > 0 && !(Number(this.page) > Number(this.pagesAmountCount))) {
				this.getData();
			}
		}
	},
	mounted() {
		this.getData()
	}
}
</script>

<style scoped>
	.filter_wrp{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 25px;
    }

    .filter{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 10px;
        cursor: pointer;
        user-select: none;	
    }

    .filter span{
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 14px;
        color: #0071BC;
        margin-right: 10px;
        user-select: none;
    }	

	.table{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        padding: 10px 15px;
        overflow-y: auto;
        margin-bottom: 30px;
    }

    .table_inner{
        width: 100%;
        margin-bottom: 30px;
    }

    .row_titles{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
    }

    .row_title_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 700;
        color: rgba(0,113,188,0.5);
        padding: 7px;
        width: calc(100%/3);
        padding: 5px;
		user-select: none;
    }

	.row_title_item img.reverse{
		transform: rotate(180deg);
	}

	.row_title_item.sort{
		cursor: pointer;
	}

    .row_title_item.id{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .checkbox{
        width: 15px;
        height: 15px;
        border: solid 2px #D5D5D5;
        border-radius: 0.2rem;
        margin-right: 10px;
        cursor: pointer;
    }

    .row_title_item.action{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .row:not(:last-of-type) {
        margin-bottom: 15px;
    }

    .row_item{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 400;
        color: #707683;
        padding: 5px;
        width: calc(100%/3);
    }

    .row_item.id{
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 600;
        color: #323C47;
        padding: 7px;
    }

    .row_item.action{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    .row_item.id{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

	.result{
		align-self: flex-start;
		margin-bottom: 20px;
	}

    .pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
    }

    .count{
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #707683;
        padding: 5px 15px;
        cursor: pointer;
    }

    .current{
        border: solid 1px #707683;
        color: #0071BC;
    }

    .btn{
        background-color: transparent;
        border: none;
        padding: 5px 15px;
        cursor: pointer;
    }

    .prev_btn{
        transform: rotate(180deg);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }

    .edit_page{
        width: 80px;
        padding: 5px 15px;
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        color: #0071BC;
        text-align: center;
        padding: 5px 15px;
        border: solid 1px #707683;
        overflow: visible;
    }

	.result{
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 15px;
	}

	.result span{
		flex: 0 0 calc(100%/2 - 10px);
		max-width: 0 0 calc(100%/2- 10px);
		padding: 15px;
		background-color: #fff;
	}

	.result span:first-of-type{
		flex: 0 0 calc(100%/2 - 10px);
		max-width: 0 0 calc(100%/2 - 10px);
		margin-right: 20px;
	}
</style>