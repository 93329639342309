<template>
	<section class="section">
		<div class="container">
			<div class="section_inner">
				<header class="header">
					<div class="header_title">
						<h1 class="name">Номер ID: {{ id }}</h1>
					</div>
				</header>
				<router-view></router-view>
				<div class="empty"></div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Replenishment',
	props: ['cardId'],
	data() {
		return{
			id: window.localStorage.cardId,
		}
	},
}
</script>

<style scoped>
	.section{
        position: relative;
		z-index: 99;
        width: 100%;
        height: 100vh;
    }

    .section_inner{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100vh;
		padding: 30px 0;
    }

	.header{
		display: flex;
		justify-content: center;
		width: 100%;
		align-items: center;
	}

	.device{
		font-family: "Open Sans";
        font-size: 36px;
        font-weight: 700;
        color: #0071BC;
	}

	.name{
		font-family: "Open Sans";
        font-size: 36px;
        font-weight: 700;
        color: #000;
	}

	.subtitle{
		font-family: "Open Sans";
        font-size: 36px;
        font-weight: 700;
		color: #666;
	}

	.header_btn{
		font-family: "Open Sans";
        font-size: 24px;
        font-weight: 700;
        color: #fff;
		text-decoration: underline;
		cursor: pointer;
		padding: 15px 50px;
		border: none;
		background: #DADCE0;
		border-radius: .5rem;
		transition: .1s linear;
	}

	.header_btn:hover{
		background: #0071BC;
		color: #fff;
	}

    .section_form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
    }

    .section_form input{
        width: 100%;
        padding: 20px;
        border-radius: .5rem;
        border: solid 3px #bfbfbf;
        font-family: "Open Sans";
        font-size: 36px;
        font-weight: 600;
        color: #828282;
        margin-bottom: 40px;
    }

	.section_form input:focus{
		border: solid 3px #0071BC;
		color: #000;
	}

    .form_btn{
        width: 70%;
        font-family: "Open Sans";
        font-size: 36px;
        font-weight: 700;
        color: #fff;
        border: none;
        background: #DADCE0;
        padding: 15px;
        border-radius: .5rem;
        transition: .1s linear;
		text-decoration: none;
		text-align: center;
    }

    .form_btn:hover{
        background: #0071BC;
        color: #fff;
    }

	.section_btn_wrp{
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.section_btn{
		font-family: "Open Sans";
        font-size: 24px;
        font-weight: 700;
        color: #fff;
		padding: 15px 50px;
		border: none;
		background: #DADCE0;
		border-radius: .5rem;
		transition: .1s linear;
		text-decoration: none;
	}

	.section_btn:hover{
		background: #0071BC;
	}
	.empty{
		width: 100%;
	}
</style>